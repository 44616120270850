import AriaModal from 'react-aria-modal'
import React, { memo } from 'react'
import styled from 'styled-components'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

import { Colors } from 'shared/styles/Colors'
import { Icon } from 'shared/components/Icon/Icon'
import { MediaQueries } from 'web/styles/responsive'

export const InfoModal = memo(({ onClose }) => {
  return (
    <AriaModal
      titleText="Cancel Order"
      underlayStyle={{ paddingTop: '40px', paddingBottom: '40px' }}
      underlayClickExits={false}
      escapeExits={false}
      verticallyCenter
      focusDialog={true}
    >
      <Modal>
        <Header>
          <Button onClick={onClose}>
            <Icon icon={faXmark} color={Colors.black} size="lg" />
          </Button>
        </Header>

        <TextContainer>
          <Text>This issue happens to some users when using Instagram's web browser.</Text>
          <TitleText>How to work around this:</TitleText>
          <Text>- Tap the icon with the three little dots on the top right side of the screen</Text>
          <Text>- Select 'Open in Browser'</Text>
          <TitleText>
            Doing this will save all of your previous order details and will open on your phone's
            web browser.
          </TitleText>
          <Text>
            If you’re still encountering issues, please move forward with submitting your order
            request and let us know about this issue in the comments section on the next page.
            Thanks!
          </Text>
        </TextContainer>
      </Modal>
    </AriaModal>
  )
})

export const Modal = styled.div`
  background-color: ${Colors.white};
  padding: 10px 0px;
  border-radius: 4px;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  width: calc(100% - 40px);
  position: relative;
  margin: 0 20px;
  height: 420px;
  overflow: hidden;
  @media ${MediaQueries.mdUp} {
    width: 450px;
    margin: 0 auto;
  }
`

const TextContainer = styled.div`
  height: 370px;
  overflow: scroll;
  padding: 0 20px;
`
const Text = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 0px;
`
const TitleText = styled.p`
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: bold;
`
export const Header = styled.div`
  display: flex;
  font-size: 22px;
  font-weight: bold;
  margin: 0;
  margin-top: 0;
  justify-content: flex-end;
  padding: 0 10px;
`
export const Button = styled(Link)`
  display: flex;
  width: 30px;
  justify-content: center;
  margin-bottom: 5px;
`
