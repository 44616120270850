import * as Yup from 'yup'
import { MONEY_PATTERN_VALIDATION_MESSAGE, moneyInputRegex } from 'shared/utils/currency'

export const priceAdjustment = {
  Name: Yup.string().required('Please give this adjustment a name'),
  AmountCents: Yup.string().when('isAdjustmentTypePercent', {
    is: false,
    then: (schema) =>
      schema.required('Please input an amount').matches(moneyInputRegex, {
        message: MONEY_PATTERN_VALIDATION_MESSAGE,
      }),
  }),
  Type: Yup.boolean().required(),
  TaxPercent: Yup.number()
    .typeError('Percent must be a number')
    .positive('Please input a positive number')
    .max(100, 'Please enter a number below 100')
    .min(0, 'Please enter a number above 0')
    .when('isAdjustmentTypePercent', {
      is: true,
      then: (schema) => schema.required('Please input a percentage'),
    }),
  DiscountPercent: Yup.number()
    .typeError('Percent must be a number')
    .positive('Please input a positive percent')
    .max(100, 'Please enter a percent below 100')
    .min(0, 'Please enter a percent above 0')
    .when('isAdjustmentTypePercent', {
      is: true,
      then: (schema) => schema.required('Please input a percentage'),
    }),
  FeePercent: Yup.number()
    .typeError('Percent must be a number')
    .positive('Please input a positive percent')
    .min(0, 'Please enter a percent above 0')
    .when('isAdjustmentTypePercent', {
      is: true,
      then: (schema) => schema.required('Please input a percentage'),
    }),
  Code: Yup.string().when('useCoupon', {
    is: true,
    then: (schema) => schema.required('Please input coupon code'),
  }),
}
