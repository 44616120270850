import React from 'react'
import styled from 'styled-components'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'

import { Colors } from 'shared/styles/Colors'
import { useConversationPreview } from 'shared/hooks/useConversationPreview'
import { UserAvatar } from 'shared/components/UserAvatar/UserAvatar'
import { useResponsiveLayout } from 'web/hooks/useResponsiveLayout'

export const ConversationItem = React.memo(
  ({ conversation, activeConversation, setActiveConversation }) => {
    const { isMdUp } = useResponsiveLayout()
    const { otherUser, read } = conversation
    const isActive = conversation.otherUser.id === activeConversation.otherUser.id
    const preview = useConversationPreview({ conversation })

    return (
      <Container
        active={isMdUp && isActive}
        onClick={isMdUp && isActive ? null : () => setActiveConversation(conversation)}
      >
        <UserAvatar user={otherUser} bakery={otherUser.bakery} style={{ marginRight: '15px' }} />
        <Content>
          <NameContainer>
            <Name read={read}>
              {otherUser.bakery && otherUser.bakery.name ? otherUser.bakery.name : otherUser.name}
            </Name>
            {otherUser.verified && (
              <FontAwesomeIcon
                icon={faCheckCircle}
                color={Colors.blue}
                size={20}
                style={{ marginLeft: 5 }}
              />
            )}
          </NameContainer>
          <Preview>
            <Message>{preview}</Message>
            {!conversation.newConversation && (
              <DateText>
                &nbsp;&middot;&nbsp;
                {format(new Date(conversation.sentAt), 'LL/dd/yy')}
              </DateText>
            )}
          </Preview>
        </Content>
        {!read && <ReadAlert />}
      </Container>
    )
  },
)

const Container = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.active ? Colors.grey10 : 'transparent')};
  border-radius: 4px;
  padding: 10px 10px;
  cursor: pointer;
`

const Content = styled.div`
  flex: 1;
  overflow: hidden;
`

const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${(props) => (props.read ? '' : 'bold')};
`

const Preview = styled.div`
  display: flex;
  color: ${Colors.grey50};
  font-size: 1.6rem;
`

const Message = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ReadAlert = styled.div`
  background-color: ${Colors.blue};
  width: 15px;
  height: 15px;
  margin-left: 10px;
  border-radius: 50%;
`

const DateText = styled.div``

const NameContainer = styled.div`
  display: flex;
  align-items: center;
`
