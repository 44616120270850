import React from 'react'
import styled from 'styled-components'
import { headerHeight } from 'web/styles/Layout'
import { useWindowDimensions } from 'web/components/WindowDimensionsProvider'

export const CenteredContent = ({ children, page, fullScreen, height: _height }) => {
  const { height: pageHeight } = useWindowDimensions()
  let height = null

  if (_height) {
    height = _height
  } else if (fullScreen) {
    height = pageHeight
  } else if (page) {
    height = pageHeight - headerHeight
  } else {
    height = 'initial'
  }

  return <Container height={height}>{children}</Container>
}

const CenteredContentDiv = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const OverlayedContent = styled(CenteredContentDiv)({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 100,
  backgroundColor: '#F5FCFF88',
})

const Container = styled(CenteredContentDiv)(({ height }) => ({
  height,
}))
