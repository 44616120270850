const titleizeProvider = (provider) => {
  return provider.charAt(0).toUpperCase() + provider.slice(1)
}

export const createAccountScreenTitle = (serviceData = {}) => {
  let titleText = 'Create an account'
  if (serviceData?.provider)
    titleText = `${titleText} via ${titleizeProvider(serviceData?.provider)}`
  return titleText
}
