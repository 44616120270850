import humps from 'humps'
import Resizer from 'react-image-file-resizer'
import { Configuration } from 'shared/services/Configuration'
import { DirectUpload } from '@rails/activestorage'

export const directUpload = async (image) => {
  const resized = await resizeImage(image)
  resized.name = image.name
  const data = await uploadFile(resized)
  return humps.camelizeKeys(data)
}

const uploadFile = (file) => {
  return new Promise((resolve, reject) => {
    const upload = new DirectUpload(file, Configuration.directUploadUrl)

    upload.create((error, blob) => {
      if (error) {
        reject(error)
      } else {
        resolve(blob)
      }
    })
  })
}

const resizeImage = (file) => {
  return new Promise((resolve, reject) => {
    const responseUriFunc = (response) => {
      if (response && response.stack && response.message) {
        reject(response)
      } else {
        resolve(response)
      }
    }
    Resizer.imageFileResizer(
      file, //is the file of the new image that can now be uploaded...
      1000, // is the maxWidth of the  new image
      1000, // is the maxHeight of the  new image
      'JPEG', // is the compressFormat of the  new image
      80, // is the quality of the  new image
      0, // is the rotation of the  new image
      responseUriFunc, // is the callBack function of the new image URI
      'blob', // is the output type of the new image
    )
  })
}

export const imageInterface = async (image) => image
export const documentInterface = async (file) => file
