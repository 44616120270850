import React, { memo, useState } from 'react'
import styled from 'styled-components'
import { faCartShoppingFast } from '@fortawesome/pro-duotone-svg-icons'
import { faCheck, faPlus } from '@fortawesome/pro-light-svg-icons'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFragment } from '@apollo/client'

import { Colors, stockTextColor } from 'shared/styles/Colors'
import { covertStockToDozens } from 'shared/utils/number'
import { CurrencyFragment, OfferingDetailsFragment, UserFragment } from 'shared/graphql/fragments'
import { DescriptionModal } from './DescriptionModal'
import { formatCents } from 'shared/utils/currency'
import { StyledIcon } from './Offering'
import { useThemeContext } from 'web/contexts/ThemeContext'

export const PresaleItem = memo(
  ({
    currencyId,
    isInstantCheckout,
    marginTop,
    offeringSlug,
    onClick,
    selected,
    storefrontId,
    userId,
  }) => {
    const { backgroundColor } = useThemeContext()

    const [showModal, setShowModal] = useState(false)

    const { data: offering } = useFragment({
      fragment: OfferingDetailsFragment,
      fragmentName: 'OfferingDetails',
      from: {
        __typename: 'Offering',
        id: offeringSlug,
      },
    })

    const { data: currency = {} } = useFragment({
      fragment: CurrencyFragment,
      fragmentName: 'Currency',
      from: {
        __typename: 'Currencies',
        id: currencyId,
      },
    })

    const { data: user } = useFragment({
      fragment: UserFragment,
      fragmentName: 'User',
      from: {
        __typename: 'User',
        id: userId,
      },
    })

    const imageUrl = offering.image ?? user.avatar?.largeUrl
    const dozenOnly = offering.dozenOnly
    const stock = covertStockToDozens(offering.stock, dozenOnly)
    const disabled = stock !== null ? stock <= 0 : false

    const handleItemClick = () => {
      if (!disabled) onClick(offering.id)
    }

    const handleOnDescClick = (e) => {
      if (offering.description) {
        e.stopPropagation()
        setShowModal(true)
      }
    }

    return (
      <>
        <PresaleItemContainer
          key={offering.slug}
          selected={selected}
          selectedColor={backgroundColor ?? Colors.offeringSelectedBgColor}
          onClick={handleItemClick}
          marginTop={marginTop}
          disabled={disabled}
        >
          <StyledIcon icon={selected ? faCheck : faPlus} selected={selected} />
          {imageUrl && <PresaleItemImage src={imageUrl} alt={offering.name} />}
          <PriceAndName>
            <Name>{offering.name}</Name>
            {offering.priceType !== 'noPrice' && (
              <Price>{`${offering.priceType === 'variable' ? 'Starting at' : ''} ${formatCents(
                offering.priceCents,
                currency,
              )}`}</Price>
            )}
          </PriceAndName>

          <InfoContainer onClick={handleOnDescClick}>
            {stock !== null && stock <= 10 && (
              <ItemStock stock={stock}>
                {stock > 0 ? `${stock} ${dozenOnly ? 'dozen' : ''} left` : 'Out of stock'}
              </ItemStock>
            )}

            {offering.description && <InfoIcon icon={faInfoCircle} />}

            {isInstantCheckout && offering.enableInstantCheckout && (
              <InstantCheckoutWrapper>
                <FontAwesomeIcon icon={faCartShoppingFast} />
                <InstanCheckoutText>Instant Checkout</InstanCheckoutText>
              </InstantCheckoutWrapper>
            )}
          </InfoContainer>
        </PresaleItemContainer>

        {showModal && <DescriptionModal offering={offering} onClose={() => setShowModal(false)} />}
      </>
    )
  },
)

const PresaleItemContainer = styled.div(({ selected, selectedColor, disabled, marginTop = 0 }) => ({
  position: 'relative',
  display: 'flex',
  borderRadius: 10,
  border: `1px solid ${selected ? Colors.offeringSelectedBgColor : Colors.grey25}`,
  /**
   * NOTE: This is hard to read but the gist is:
   *       If the item is disabled - background color is grey10
   *       If the item is selected - background color is selectedColor
   *       Otherwise - background color is white
   **/
  backgroundColor: disabled ? Colors.grey10 : selected ? selectedColor : Colors.white,
  color: selected ? Colors.offeringSelectedTextColor : Colors.grey100,
  padding: '10px 20px',
  marginBottom: 20,
  alignItems: 'center',
  cursor: 'pointer',
  height: 110,
  justifyContent: 'center',
  minWidth: '300px',
  width: '100%',
  marginTop,
}))

const PresaleItemImage = styled.img({
  width: 80,
  height: 80,
  marginRight: 10,
  marginTop: 10,
  marginBottom: 10,
})

const PriceAndName = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  width: 'calc(100% - 115px)',
})

const Name = styled.div({
  fontSize: 14,
  marginBottom: 10,
  fontWeight: 'bolder',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const Price = styled.div({
  fontSize: 14,
  fontWeight: 'bold',
})

const InfoContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'center',
  padding: '10px 10px 5px',
  marginRight: -18,
  height: '100%',
})

const InfoIcon = styled(FontAwesomeIcon)({ fontSize: 24, lineHeight: 0 })

const InstantCheckoutWrapper = styled.div({
  position: 'absolute',
  display: 'flex',
  gap: 5,
  bottom: 10,
  right: 10,
})

const InstanCheckoutText = styled.span({
  fontSize: 14,
})

const ItemStock = styled.div(({ stock }) => ({
  fontWeight: 'bold',
  position: 'absolute',
  top: 5,
  right: 8,
  color: stockTextColor(stock),
  fontSize: 12,
}))
