import { Configuration } from './Configuration'
import { logout, setJwt } from 'shared/reducers/account'
import { print } from 'graphql'
import { Queries } from 'shared/constants/Queries'

let refreshingPromise = null

const _refresh = async (resolve, reject, store) => {
  const fetchParams = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: print(Queries.RefreshToken),
      variables: { input: { refreshToken: store.getState().account.refreshToken } },
    }),
  }

  try {
    // console.log('attempting token refresh with params', fetchParams)
    const refreshResponse = await fetch(Configuration.graphqlUrl, fetchParams)
    const refreshJson = await refreshResponse.json()
    // console.log('token refresh', refreshJson)

    const jwt = refreshJson?.data?.createSession?.jwt
    if (!jwt) store.dispatch(logout())

    resolve(jwt)
  } catch (e) {
    console.log('error during token refresh', e)
    store.dispatch(logout())
    resolve(null)
  }
}

const refresh = async (store) => {
  return new Promise((resolve, reject) => {
    _refresh(resolve, reject, store)
  })
}

export const customFetch = async (uri, options, store) => {
  const originalResult = await fetch(uri, options)
  const clonedOriginal = originalResult.clone()
  const originalJson = await originalResult.json()

  if (originalJson?.errors?.[0]?.message === 'unauthorized') {
    if (!refreshingPromise) {
      refreshingPromise = refresh(store)
    }

    return refreshingPromise.then((newJwt) => {
      refreshingPromise = null
      store.dispatch(setJwt(newJwt))
      options.headers.authorization = newJwt ? `Bearer ${newJwt}` : ''
      return fetch(uri, options)
    })
  }

  return clonedOriginal
}
