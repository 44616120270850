import * as Cross from 'shared/components/Cross'
import React, { memo } from 'react'
import styled from 'styled-components'
import { useFragment } from '@apollo/client'

import { covertStockToDozens } from 'shared/utils/number'
import { Info } from './'
import { OfferingDetailsFragment } from 'shared/graphql/fragments'
import { stockTextColor } from 'shared/styles/Colors'
import { TextField } from 'web/components/form/TextField'

export const ItemQuantity = memo(({ offeringId, onChange, value }) => {
  const { data: offering } = useFragment({
    fragment: OfferingDetailsFragment,
    fragmentName: 'OfferingDetails',
    from: {
      __typename: 'Offering',
      id: offeringId,
    },
  })

  const renderInfo = () => {
    if (offering.dozenOnly && offering.allowHalfDozen) {
      return <Info>* increments of '0.5' (1/2 dozen)</Info>
    }

    return null
  }

  const renderQuantities = () => {
    switch (true) {
      case offering.allowMaxQuantity && offering.minQuantity > 1:
        return (
          <Info $marginTop={2}>
            ({offering.minQuantity} min - {offering.maxQuantity} max)
          </Info>
        )

      case offering.allowMaxQuantity:
        return <Info $marginTop={2}>({offering.maxQuantity} max)</Info>

      case offering.minQuantity > 1:
        return <Info $marginTop={2}>({offering.minQuantity} min)</Info>

      default:
        return null
    }
  }

  const stock = covertStockToDozens(offering.stock, offering.dozenOnly)

  return (
    <QuantityForm>
      <LabelContainer>
        <Label>{offering.name}</Label>
      </LabelContainer>

      {renderInfo(offering)}

      {stock !== null && stock <= 10 && (
        <StockLabel stock={stock}>
          ({`${stock} ${offering.dozenOnly ? 'dozen' : ''} left`})
        </StockLabel>
      )}

      <InputContainer>
        <TextField
          style={{ width: '30%' }}
          type="number"
          value={value}
          onChange={onChange(offering.id)}
          min={0}
        />

        <Helper>
          <Strong>{offering.dozenOnly ? 'Dozen' : 'Quantity'}</Strong>
          {renderQuantities(offering)}
        </Helper>
      </InputContainer>
    </QuantityForm>
  )
})

const QuantityForm = styled.div({
  flexDirection: 'column',
  alignItems: 'flex-start',
  display: 'flex',
  flexWrap: 'wrap',
  width: 280,
  marginBottom: 20,
})

const Label = styled.div({
  width: 'auto',
  maxWidth: '100%',
  fontWeight: 600,
})

const InputContainer = styled.div({
  flexDirection: 'row',
  display: 'flex',
  marginTop: 10,
})

const Helper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginLeft: 10,
  paddingTop: 10,
})

const Strong = styled(Cross.Strong)({
  marginRight: 10,
})

const StockLabel = styled(Label)(({ stock }) => ({
  marginTop: 10,
  fontSize: '1.5rem',
  fontStyle: 'italic',
  color: stockTextColor(stock),
}))

const LabelContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 5,
  width: '100%',
})
