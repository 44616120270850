import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Colors } from 'shared/styles/Colors'
import { conversationsSelector, loadConversations } from 'shared/reducers/messenger'
import { useDispatch, useSelector } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import { useLocation } from 'react-router-dom'

import { Configuration } from 'shared/services/Configuration'
import { Conversations } from './Conversations'
import { Editor } from './Editor'
import { FetchError } from 'shared/components/FetchError'
import { Header } from './Header'
import { MediaQueries } from 'web/styles/responsive'
import { Message } from 'shared/models/Message'
import { Messages } from './Messages'
import { NoMessages } from './NoMessages'
import { Spinner } from 'shared/components/Spinner'
import { useCurrentUser } from 'shared/hooks/useCurrentUser'
import { useMessageSender } from 'shared/hooks/useMessageSender'

const { bakesyTipsId, bakesyAnnouncementsId } = Configuration
const serviceAccountsIds = [bakesyTipsId, bakesyAnnouncementsId]

export const ChatPage = React.memo(() => {
  const dispatch = useDispatch()
  const [mobileConversationsOpen, setMobileConversationsOpen] = useState(false)
  const [activeConversation, setActiveConversation] = useState(null)
  const conversationsState = useSelector((state) => state.messenger.conversationsState)
  const conversations = useSelector(conversationsSelector)
  const currentUser = useCurrentUser()
  const location = useLocation()
  const { newConversationUser, initialConversationUserId } = location.state || {}
  const readOnly = serviceAccountsIds.includes(activeConversation?.otherUser?.id)

  const notifyTooManyImages = (maxImages) => {
    alert(`You may only add ${maxImages} images at most to each message.`)
  }
  const onSendFailure = () => {
    alert('Error sending message.  Please try again.')
  }

  const messageSenderBag = useMessageSender({
    receiverId: activeConversation?.otherUser?.id,
    notifyTooManyImages,
    onSendFailure,
  })
  const { addImages } = messageSenderBag

  useEffect(() => {
    const setup = () => {
      const newConversation = newConversationUser
        ? new Message({ sender: currentUser, receiver: newConversationUser }, currentUser).data
        : null
      dispatch(loadConversations({ newConversation }))
    }

    setup()
  }, [dispatch, newConversationUser, currentUser, initialConversationUserId])

  useEffect(() => {
    if (activeConversation || !conversations.length) return

    if (initialConversationUserId) {
      const index =
        conversations.findIndex((c) => c.otherUser?.id === initialConversationUserId) || 0
      setActiveConversation(conversations[index])
    } else {
      setActiveConversation(conversations[0])
    }
  }, [conversations, activeConversation, setActiveConversation, initialConversationUserId])

  const onDrop = useCallback(
    (files) => {
      const acceptedFiles = files.filter((f) => f.type.match(/^image\//))
      if (acceptedFiles.length) addImages(acceptedFiles)
    },
    [addImages],
  )
  const { getRootProps, isDragActive } = useDropzone({ onDrop })
  const dropZoneProps = readOnly ? () => {} : getRootProps

  if (conversationsState === 'error') return <FetchError page />
  if (conversationsState === 'loaded' && !conversations.length) return <NoMessages />
  if (conversationsState !== 'loaded' || !activeConversation) return <Spinner page />

  return (
    <ChatContainer>
      <Conversations
        activeConversation={activeConversation}
        setActiveConversation={(conversation) => {
          setActiveConversation(conversation)
          setMobileConversationsOpen(false)
        }}
        isOpen={mobileConversationsOpen}
      />

      <Main {...dropZoneProps()} isOpen={!mobileConversationsOpen}>
        {isDragActive && !readOnly && <Highlight />}
        <Header
          activeConversation={activeConversation}
          openConversations={() => {
            setMobileConversationsOpen(true)
          }}
        />
        <Messages activeConversation={activeConversation} />
        <Editor readOnly={readOnly} messageSenderBag={messageSenderBag} />
      </Main>
    </ChatContainer>
  )
})

const ChatContainer = styled.div({
  height: '100vh',
  display: 'flex',
  backgroundColor: Colors.white,
})

const Main = styled.div(({ isOpen }) => ({
  height: '100vh',
  flex: 1,
  display: isOpen ? 'flex' : 'none',
  flexDirection: 'column',
  position: 'relative',
  outline: 'none',
  width: '100%',

  [`@media ${MediaQueries.mdUp}`]: {
    width: 'initial',
    display: 'flex',
  },
}))

const Highlight = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  border: `4px solid ${Colors.blue}`,
})
