import React from 'react'
import styled from 'styled-components'

import logoWithText from 'shared/images/logo-website.png'

const isLink = !/review\//.test(window.location.pathname)
const commonStyle = ({ noMargin }) => ({
  display: 'block',
  margin: noMargin ? '0px' : '15px 0px 13px 24px',
})

export const Title = ({ noMargin = false }) => (
  <Container>
    {isLink ? (
      <StyledLink noMargin={noMargin} href="https://www.bakesy.app">
        <Logo src={logoWithText} />
      </StyledLink>
    ) : (
      <StyledDiv noMargin={noMargin}>
        <Logo src={logoWithText} />
      </StyledDiv>
    )}
  </Container>
)

const Container = styled.div`
  display: flex;
  margin-right: 'auto';
  align-items: center;
`

const StyledLink = styled.a(commonStyle)
const StyledDiv = styled.div(commonStyle)

const Logo = styled.img`
  height: 52px;
  width: 165px;
  margin-right: 10px;
`
