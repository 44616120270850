import React from 'react'
import styled from 'styled-components'

import { Colors } from 'shared/styles/Colors'

export const ActionModule = ({ children, header }) => (
  <>
    {header ? <Header>{header}</Header> : null}
    <Body>{children}</Body>
  </>
)

const Header = styled.div({
  backgroundColor: Colors.infoAlertBgColor,
  color: Colors.infoAlertTextColor,
  padding: '10px 20px',
})

const Body = styled.div({
  padding: 20,
})
