import React, { useState } from 'react'
import styled from 'styled-components'

import { Header } from 'web/pages/OrderRequest/styles'
import { MediaQueries } from 'web/styles/responsive'
import { OrderRequestLayout } from 'web/pages/OrderRequest/OrderRequestLayout'
import { Spec } from './Spec'
import { useOrderContext } from 'web/contexts/OrderContext'
import { useUpdateOrder } from 'shared/hooks/useUpdateOrder'

export const Theme = () => {
  const { order } = useOrderContext()
  const { loading, updateOrder } = useUpdateOrder({ order })

  const [orderItems, setOrderItems] = useState(
    order.orderItems.map((orderItem) => {
      const offering = orderItem.offering

      return {
        offeringId: offering.id,
        offeringSlug: offering.slug,
        quantity: orderItem.quantity,
        unit: orderItem.unit,
        spec: orderItem.spec ?? '',
      }
    }),
  )
  const valid = orderItems.every((orderItem) => !!orderItem.spec)
  const onNext = ({ navigateToNextScreen }) => {
    /* Omit offering slug from the input object as backend does not accept it */
    const updatedOrderItems = orderItems.map(({ offeringSlug, ...input }) => input)

    updateOrder({ input: { orderItems: updatedOrderItems } }, navigateToNextScreen)
  }

  const handleChange = (offeringId) => (e) => {
    setOrderItems((previousItems) =>
      previousItems.map((orderItem) => {
        if (orderItem.offeringId !== offeringId) return orderItem
        return { ...orderItem, spec: e.target.value }
      }),
    )
  }

  return (
    <OrderRequestLayout nextDisabled={!valid || loading} onNext={onNext}>
      <StyledHeader>Order specifications</StyledHeader>

      {orderItems.map((orderItem) => (
        <Spec
          key={orderItem.offeringId}
          offeringId={orderItem.offeringSlug ?? orderItem.offeringId}
          onChange={handleChange}
          value={orderItem.spec}
        />
      ))}
    </OrderRequestLayout>
  )
}

const StyledHeader = styled(Header)({
  [`@media ${MediaQueries.mdUp}`]: {
    padding: '0 80px',
  },
})
