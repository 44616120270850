import React, { memo } from 'react'
import styled from 'styled-components'

import { Colors } from 'shared/styles/Colors'
import { Fonts } from 'shared/styles/Typography'
import { Title } from 'web/components/Header/Title'

export const PoweredBy = memo(({ customStyle }) => (
  <Container customStyle={customStyle}>
    <Text>Powered by</Text>
    <Title noMargin={true} />
  </Container>
))

const Container = styled.div(({ customStyle }) => ({
  transform: 'scale(0.8)',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 20,
  ...customStyle,
}))

const Text = styled.p({
  color: Colors.black,
  fontSize: '2rem',
  fontWeight: 400,
  fontFamily: Fonts.DefaultSecondary,
  margin: 0,
})
