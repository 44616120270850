import React from 'react'
import styled from 'styled-components'
import { Colors } from 'shared/styles/Colors'
import { createError } from 'shared/utils/formErrors'

export const ErrorMessage = React.memo(({ error, name, Container = null }) => {
  const ContainerComponent = Container || ErrorContainer

  if (!error) return null

  return (
    <ContainerComponent>
      <ErrorMessageText>{createError(error, name)}</ErrorMessageText>
    </ContainerComponent>
  )
})

const ErrorContainer = styled.div`
  margin-top: 10px;
`

const ErrorMessageText = styled.span`
  color: ${Colors.red};
`
