import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useStripe,
} from '@stripe/react-stripe-js'
import { faLock } from '@fortawesome/pro-solid-svg-icons'

import { Button } from 'web/components/Button'
import { Colors } from 'shared/styles/Colors'
import { formatCents } from 'shared/utils/currency'
import { Icon } from 'shared/components/Icon/Icon'
import { plus, times } from 'shared/utils/number'
import { TextField } from 'web/components/form/TextField'
import { Tips } from './Tips'
import { useInvoiceContext } from 'shared/contexts/InvoiceContext'
import { useResponsiveLayout } from 'web/hooks/useResponsiveLayout'

const tipPercent = {
  0: 0,
  1: 0.15,
  2: 0.2,
  3: 0.25,
}

export const Stripe = ({ invoice, invoiceId, collectTips }) => {
  const stripe = useStripe()
  const { isXs } = useResponsiveLayout()
  const { order, payStripe, stripeLoading } = useInvoiceContext()
  const { totalDueInCents } = invoice

  const [zip, setZip] = useState('')
  const [tipVariant, setTipVariant] = useState(null)
  const [customTip, setCustomTip] = useState('')
  const [stripeReady, setStripeReady] = useState({
    cvc: false,
    expiry: false,
    number: false,
  })

  useEffect(() => {
    if (tipVariant === 0) setCustomTip('')
  }, [tipVariant])

  const tipCentsRaw =
    tipVariant === 4 && customTip
      ? times(customTip, 100)
      : times(totalDueInCents, tipPercent[tipVariant] || 0)

  const tipCents = Math.round(Math.abs(tipCentsRaw))

  const handlePay = () => payStripe({ CardNumberElement, stripe, invoiceId, zip, tipCents })

  const amountToPay = plus(totalDueInCents, tipCents)

  const handleStripeReady = (field) => (event) =>
    setStripeReady((ps) => ({ ...ps, [field]: event.complete }))

  const disabled =
    stripeLoading ||
    !stripe ||
    (collectTips && tipVariant === null) ||
    !Object.values(stripeReady).every((e) => e) ||
    zip.length < 5

  return (
    <Container $marginBottom={16}>
      Credit or debit card
      <CardDetailsRow style={{ marginTop: 10, marginBottom: 10 }}>
        <CardInputWrapper style={{ width: '100%' }}>
          <CardNumberElement
            onChange={handleStripeReady('number')}
            options={{
              showIcon: true,
              style: cardVerificationElementStyles(),
            }}
          />
        </CardInputWrapper>
      </CardDetailsRow>
      <CardDetailsRow style={{ marginBottom: 20 }}>
        <CardInputWrapper style={{ marginRight: 10 }}>
          <CardExpiryElement
            onChange={handleStripeReady('expiry')}
            options={{
              style: cardVerificationElementStyles({ smaller: isXs }),
            }}
          />
        </CardInputWrapper>

        <CardInputWrapper style={{ marginRight: 10 }}>
          <CardCvcElement
            onChange={handleStripeReady('cvc')}
            options={{ style: cardVerificationElementStyles() }}
          />
        </CardInputWrapper>

        <ZipField
          placeholder={isXs ? 'ZIP' : 'ZIP Code'}
          value={zip}
          onChange={(e) => setZip(e.target.value)}
          Container={ZipContainer}
          inputMode="numeric"
          pattern="[0-9]*"
          autocomplete="postal-code"
        />
      </CardDetailsRow>
      {collectTips && (
        <Tips
          customTip={customTip}
          tipVariant={tipVariant}
          total={totalDueInCents}
          setCustomTip={setCustomTip}
          setTipVariant={setTipVariant}
        />
      )}
      <PayButtonContainer>
        <PayButton disabled={disabled} loading={stripeLoading} onClick={handlePay} button fullWidth>
          Pay {formatCents(amountToPay, order?.bakery?.currency)}
        </PayButton>
      </PayButtonContainer>
      <SecurePaymentsContainer>
        <LockIcon icon={faLock} size={14} color={Colors.green} />
        Secure payments by&nbsp;
        <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">
          Stripe
        </a>
        .
      </SecurePaymentsContainer>
    </Container>
  )
}

const LockIcon = styled(Icon)({
  marginRight: 10,
})

const PayButtonContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 10%',
})

const PayButton = styled(Button)({
  display: 'inline-block',
  marginRight: 10,
})

const Container = styled.div(({ $marginBottom = 0 }) => ({
  marginBottom: $marginBottom,
}))

const CardDetailsRow = styled.div({
  display: 'flex',
})

const CardInputWrapper = styled.div({
  padding: '10px 15px',
  border: `1px solid ${Colors.grey25}`,
  borderRadius: 4,
  width: '33%',
  height: 44,
})

const cardVerificationElementStyles = ({ smaller = false } = {}) => ({
  base: {
    fontSize: smaller ? '15px' : '18px',
    '::placeholder': {
      color: Colors.grey50,
    },
  },
})

const ZipField = styled(TextField)({
  height: 44,
  fontSize: 18,
})

const ZipContainer = styled.div({
  width: '33%',
})

const SecurePaymentsContainer = styled.div({
  color: Colors.green,
  marginTop: 30,
  fontSize: 14,

  a: {
    color: Colors.green,
    textDecoration: 'underline',
  },
})
