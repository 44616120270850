import React from 'react'
import styled from 'styled-components'
import { CenteredContent } from 'shared/components/CenteredContent'

export const NoMessages = () => {
  return (
    <CenteredContent page>
      <Container>
        <h2>No messages yet!</h2>
        <div>Visit a baker's page to start a conversation.</div>
      </Container>
    </CenteredContent>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
