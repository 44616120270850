import React from 'react'
import styled from 'styled-components'
import { Colors } from 'shared/styles/Colors'
import { ConversationItem } from './ConversationItem'
import { conversationsSelector } from 'shared/reducers/messenger'
import { MediaQueries } from 'web/styles/responsive'
import { useCurrentUser } from 'shared/hooks/useCurrentUser'
import { UserAvatar } from 'shared/components/UserAvatar/UserAvatar'
import { useSelector } from 'react-redux'

export const Conversations = React.memo(({ activeConversation, setActiveConversation, isOpen }) => {
  const currentUser = useCurrentUser()
  const conversations = useSelector(conversationsSelector)

  return (
    <Container isOpen={isOpen}>
      <Header>
        <UserAvatar user={currentUser} style={{ marginRight: '10px' }} size={40} />
        Messages
      </Header>

      {conversations.map((c) => (
        <ConversationItem
          conversation={c}
          key={c.id ? c.id : 'new'}
          activeConversation={activeConversation}
          setActiveConversation={setActiveConversation}
        />
      ))}
    </Container>
  )
})

const Container = styled.div(({ isOpen }) => ({
  width: '100%',
  borderRight: `2px solid ${Colors.grey10}`,
  padding: '20px 10px',
  overflowY: 'auto',
  display: isOpen ? 'initial' : 'none',

  [`@media ${MediaQueries.mdUp}`]: {
    width: 275,
    display: 'initial',
  },
  [`@media ${MediaQueries.lgUp}`]: {
    width: 350,
  },
}))

const Header = styled.div`
  font-size: 2.8rem;
  margin-bottom: 20px;
  margin-left: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
`
