import React from 'react'
import styled from 'styled-components'

import { addColon } from 'shared/utils/textTransforms'
import { Colors } from 'shared/styles/Colors'
import { isValidUrl } from 'shared/utils/url'

export const Default = ({ paymentMethod }) => {
  const username = paymentMethod.username

  const renderUsername = () => {
    if (!username) return null

    const link = username.toLowerCase().includes('http') ? username : `https://${username}`

    if (isValidUrl(link)) return addColon(<PaymentLink href={link}>{link}</PaymentLink>)
    else return addColon(username)
  }

  return (
    <span>
      {paymentMethod.name || paymentMethod.displayType}
      {renderUsername()}
    </span>
  )
}

const PaymentLink = styled.a({
  color: Colors.blue,
})
