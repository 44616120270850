import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Colors } from 'shared/styles/Colors'
import { Divider, Row, Strong } from 'shared/components/Invoice/styles'
import { useInvoiceContext } from 'shared/contexts/InvoiceContext'

export const BakerySection = ({ show, Container = React.Fragment }) => {
  const { order } = useInvoiceContext()
  const { slug, name, user } = order.bakery

  if (!show) return null

  return (
    <Container>
      <Row>
        <Bakery to={`/b/${slug}`}>{name}</Bakery>
      </Row>

      <Row>
        <Strong>Email</Strong>

        <Email href={`mailto:${user.email}`}>{user.email}</Email>
      </Row>

      <Divider />
    </Container>
  )
}

const Bakery = styled(Link)({
  textDecoration: 'underline',
  fontWeight: 'bold',
  marginRight: 4,
  fontSize: 16,
})

const Email = styled.a({
  marginLeft: 10,
  color: Colors.blue,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})
