import { Fragments } from './Fragments'
import { gql } from '@apollo/client'

const RefreshToken = gql`
  mutation RefreshToken($input: CreateSessionInput!) {
    createSession(input: $input) {
      jwt
      refreshToken
      errors
    }
  }
`

const InitializeUI = gql`
  ${Fragments.CurrentUser}

  mutation InitializeUI($input: CreateSessionInput!) {
    createSession(input: $input) {
      jwt
      refreshToken
      errors
      user {
        ...CurrentUserFragment
      }
    }
  }
`

export const Queries = {
  RefreshToken,
  InitializeUI,
}
