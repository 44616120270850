import React from 'react'
import { CenteredContent, OverlayedContent } from './CenteredContent'
import { Colors } from 'shared/styles/Colors'
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons'
import { Icon } from './Icon/Icon'

export const Spinner = ({ size = 40, overlay = false, ...props }) => {
  const Container = overlay ? OverlayedContent : CenteredContent

  return (
    <Container {...props}>
      <Icon icon={faSpinnerThird} color={Colors.brand} size={size} spin />
    </Container>
  )
}
