import React from 'react'
import styled from 'styled-components'

export const Toggle = ({ value, onChange, disabled }) => {
  const _onChange = (e) => onChange(e.target.checked)

  return (
    <CheckboxLabel>
      <CheckboxInput type="checkbox" checked={value} onChange={_onChange} disabled={disabled} />
    </CheckboxLabel>
  )
}

const CheckboxInput = styled.input({
  height: 20,
  width: 20,
  cursor: 'pointer',
})

const CheckboxLabel = styled.label({
  padding: 5,
  cursor: 'pointer',
})
