import React, { useMemo } from 'react'
import styled from 'styled-components'
import { faPaperclip } from '@fortawesome/pro-light-svg-icons'
import { useDispatch } from 'react-redux'

import { Colors } from 'shared/styles/Colors'
import { Divider, Image, ImageLink, Row, Strong } from 'shared/components/Invoice/styles'
import { Icon } from 'shared/components/Icon/Icon'
import { setLightboxImage } from 'shared/reducers/ui'
import { useInvoiceContext } from 'shared/contexts/InvoiceContext'

export const ImageSection = ({ show, Container = React.Fragment }) => {
  const dispatch = useDispatch()
  const { order } = useInvoiceContext()
  const { inspirationPhotos } = order

  const hasPhotos = useMemo(() => {
    return Boolean(inspirationPhotos?.length)
  }, [inspirationPhotos])

  if (!show) return null
  if (!hasPhotos) return null

  const handleClickImage = (url) => () => dispatch(setLightboxImage(url))

  return (
    <Container>
      <Row $marginBottom={10}>
        <Icon icon={faPaperclip} size={20} color={Colors.blue} />

        <Strong $marginLeft={10}>Inspiration Photos:</Strong>
      </Row>

      {hasPhotos && (
        <Content>
          {order.inspirationPhotos.map((photo) => (
            <ImageLink onClick={handleClickImage(photo.fullUrl)} key={photo.key}>
              <Image source={{ uri: photo.thumbnailUrl }} />
            </ImageLink>
          ))}
        </Content>
      )}

      <Divider />
    </Container>
  )
}

const Content = styled(Row)({
  flexWrap: 'wrap',
  display: 'flex',
})
