/* utility function */
export function insertIfElse(condition, trueElements, falseElements) {
  return condition ? trueElements : falseElements
}

/* for inserting arrays */
export function insertArrayIf(condition, ...elements) {
  return insertIfElse(condition, elements, [])
}

/* for inserting object keys */
export function insertKeyIf(condition, element) {
  return condition ? { ...element } : {}
}
