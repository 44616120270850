import { gql, useMutation } from '@apollo/client'
import { useElements } from '@stripe/react-stripe-js'
import { useState } from 'react'

export const usePayment = ({ order, refetch }) => {
  const elements = useElements()
  const [paymentError, setPaymentError] = useState(null)
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [stripeLoading, setStripeLoading] = useState(false)
  const [payInvoiceMutation] = useMutation(PAY_INVOICE_MUTATION)

  const payInvoice = async () => {
    setPaymentError(null)
    setPaymentLoading(true)

    try {
      const {
        data: {
          payInvoice: { errors },
        },
      } = await payInvoiceMutation({
        variables: {
          input: {
            invoiceId: order.isInstantCheckout ? order.finalInvoice.id : order.depositInvoice.id,
          },
        },
      })
      setPaymentLoading(false)
      if (errors.length) {
        setPaymentError(errors[0])
      } else {
        refetch()
      }
    } catch (e) {
      setPaymentError('Unknown error processing payment.')
      setPaymentLoading(false)
      console.error(e)
    }
  }

  const payStripe = async ({ CardNumberElement, stripe, invoiceId, zip, tipCents = 0 }) => {
    setPaymentError(null)
    setStripeLoading(true)
    const cardElement = elements.getElement(CardNumberElement)
    const result = await stripe.createToken(cardElement, { address_zip: zip })

    if (result.error) {
      setPaymentError(result?.error?.message || 'Unknown error processing payment.')
      setStripeLoading(false)
      return
    }

    try {
      const {
        data: {
          payInvoice: { errors },
        },
      } = await payInvoiceMutation({
        variables: {
          input: {
            cardToken: result.token.id,
            invoiceId,
            tipCents,
          },
        },
      })

      if (errors.length) {
        setPaymentError(errors[0])
        setStripeLoading(false)
      } else {
        refetch()
      }
    } catch (e) {
      setPaymentError('Unknown error processing payment.')
      setStripeLoading(false)
      console.error(e)
    }
  }

  return { payInvoice, payStripe, paymentError, paymentLoading, stripeLoading }
}

export const PAY_INVOICE_MUTATION = gql`
  mutation PayInvoice($input: PayInvoiceInput!) {
    payInvoice(input: $input) {
      errors
    }
  }
`
