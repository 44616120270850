import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  priceAdjustments: [],
  archivedPriceAdjustments: [],
}

const priceAdjustmentsSlice = createSlice({
  name: 'priceAdjustments',
  initialState,
  reducers: {
    reset: () => {
      return initialState
    },
    setPriceAdjustments: (state, action) => {
      state.priceAdjustments = action.payload
    },
    setArchivedPriceAdjustments: (state, action) => {
      state.archivedPriceAdjustments = action.payload
    },
    addPriceAdjustment: (state, action) => {
      state.priceAdjustments.push(action.payload)
    },
    removePriceAdjustment: (state, action) => {
      let index = state.priceAdjustments.findIndex((adjustment) => adjustment.id === action.payload)
      if (index > -1) {
        state.archivedPriceAdjustments.push(state.priceAdjustments[index])
        state.priceAdjustments.splice(index, 1)
      }

      index = state.archivedPriceAdjustments.findIndex(
        (adjustment) => adjustment.id === action.payload,
      )
      if (index > -1) {
        state.priceAdjustments.push(state.archivedPriceAdjustments[index])
        state.priceAdjustments.splice(index, 1)
      }
    },
    updatePriceAdjustment: (state, action) => {
      let index = state.priceAdjustments.findIndex(
        (adjustment) => adjustment.id === action.payload.id,
      )
      if (index > -1) state.priceAdjustments[index] = action.payload

      index = state.archivedPriceAdjustments.findIndex(
        (adjustment) => adjustment.id === action.payload.id,
      )
      if (index > -1) state.archivedPriceAdjustments[index] = action.payload
    },
  },
})

export const {
  addPriceAdjustment,
  setPriceAdjustments,
  setArchivedPriceAdjustments,
  removePriceAdjustment,
  updatePriceAdjustment,
  reset,
} = priceAdjustmentsSlice.actions

export const priceAdjustmentsReducer = priceAdjustmentsSlice.reducer

export const selectPriceAdjustments = (state) => state?.priceAdjustments?.priceAdjustments
export const selectArchivedPriceAdjustments = (state) =>
  state?.priceAdjustments?.archivedPriceAdjustments
