import React, { useState } from 'react'
import styled from 'styled-components'

import { Colors } from 'shared/styles/Colors'
import { Header } from 'web/pages/OrderRequest/styles'
import { ItemQuantity } from './ItemQuantity'
import { OrderRequestLayout } from 'web/pages/OrderRequest/OrderRequestLayout'
import { parseNumberOrNull } from 'shared/utils/number'
import { useOrderContext } from 'web/contexts/OrderContext'
import { useUpdateOrder } from 'shared/hooks/useUpdateOrder'
import { useValidation } from './useValidation'

export const Quantity = () => {
  const { order } = useOrderContext()
  const { loading, updateOrder } = useUpdateOrder({ order })
  const validateOrderItems = useValidation()

  const [orderItems, setOrderItems] = useState(
    order.orderItems.map((orderItem) => {
      const offering = orderItem.offering

      return {
        offeringId: offering.id,
        offeringSlug: offering.slug,
        /* Since orderItem.quantity is 0 by default, we need to use '||' to set the quantity to the minimum quantity */
        quantity: orderItem.quantity || offering.minQuantity,
        unit: orderItem.unit ?? offering.defaultUnit,
        spec: orderItem.spec,
      }
    }),
  )

  const valid = orderItems.every((orderItem) => orderItem.quantity > 0)

  const onNext = ({ navigateToNextScreen }) => {
    if (!validateOrderItems(orderItems)) return

    /* Omit offering slug from the input object as backend does not accept it */
    const updatedOrderItems = orderItems.map(({ offeringSlug, ...input }) => ({
      ...input,
      quantity: parseNumberOrNull(input.quantity),
    }))

    updateOrder({ input: { orderItems: updatedOrderItems } }, navigateToNextScreen)
  }

  const handleChange = (offeringId) => (e) => {
    setOrderItems((previousItems) =>
      previousItems.map((orderItem) => {
        if (orderItem.offeringId !== offeringId) return orderItem
        return { ...orderItem, quantity: e.target.value }
      }),
    )
  }

  return (
    <OrderRequestLayout nextDisabled={!valid || loading} onNext={onNext}>
      <Header>How many do you need?</Header>

      <Container>
        {orderItems.map((orderItem) => (
          <ItemQuantity
            key={orderItem.offeringId}
            offeringId={orderItem.offeringSlug ?? orderItem.offeringId}
            onChange={handleChange}
            value={orderItem.quantity}
          />
        ))}
      </Container>
    </OrderRequestLayout>
  )
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

export const Info = styled.div(({ $marginTop = 0 }) => ({
  fontSize: 16,
  color: Colors.grey50,
  fontStyle: 'italic',
  marginTop: $marginTop,
  whiteSpace: 'nowrap',
}))
