import React from 'react'
import styled from 'styled-components'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { Icon } from 'shared/components/Icon/Icon'
import { setAlert } from 'shared/reducers/ui'
import { useDispatch, useSelector } from 'react-redux'

export const Alerts = React.memo(() => {
  const alert = useSelector((state) => state.ui.alert)
  const dispatch = useDispatch()

  return (
    <Container>
      {alert && (
        <Alert>
          <AlertText>
            {alert.title && <strong>{alert.title}&nbsp;</strong>}
            {alert.body}
          </AlertText>
          <IconContainer onClick={() => dispatch(setAlert(null))}>
            <Icon icon={faTimes} />
          </IconContainer>
        </Alert>
      )}
    </Container>
  )
})

const Container = styled.div({})

const Alert = styled.div({
  color: '#004085',
  backgroundColor: '#cce5ff',
  border: '1px solid #b8daff',
  display: 'flex',
})

const AlertText = styled.div({
  marginRight: 'auto',
  padding: 10,
})

const IconContainer = styled.div({
  marginLeft: 20,
  padding: 10,
  cursor: 'pointer',
})
