import React from 'react'
import styled from 'styled-components'
import { Button } from 'web/components/Button'
import { CenteredContent } from 'shared/components/CenteredContent'
import { openSignIn } from 'shared/reducers/ui'
import { useDispatch } from 'react-redux'

export const SignInRequiredPage = () => {
  const dispatch = useDispatch()

  return (
    <CenteredContent page>
      <Container>
        <Message>Please sign in to continue.</Message>
        <Button
          onClick={(e) => {
            e?.preventDefault()
            dispatch(openSignIn())
          }}
        >
          Sign In
        </Button>
      </Container>
    </CenteredContent>
  )
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const Message = styled.div({
  marginBottom: 20,
})
