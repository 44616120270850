import React from 'react'
import styled from 'styled-components'
import { CardBackground } from 'web/styles/shared'
import { FixedWidthContainer } from 'web/styles/responsive'
import { NotificationSettings } from 'shared/components/NotificationSettings/NotificationSettings'

export const NotificationSettingsPage = () => {
  const onErrorAlert = (error) => {
    alert(error)
  }

  return (
    <Container>
      <Title>Notification Settings</Title>

      <Content>
        <NotificationSettings showPushSettings={false} onErrorAlert={onErrorAlert} />
      </Content>
    </Container>
  )
}

const Title = styled.div({
  fontSize: 22,
  fontWeight: 'bold',
  marginBottom: 20,
})

const Container = styled(FixedWidthContainer)({
  marginTop: 40,
  marginBottom: 40,
  maxWidth: 500,
})

const Content = styled.div({
  ...CardBackground,
  padding: 20,
})
