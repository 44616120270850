// WEB ENTRY FILE

import './web/styles/global.css'
import 'normalize.css'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'react-calendar/dist/Calendar.css'
import 'react-image-lightbox/style.css'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react'
import { createRoot } from 'react-dom/client'

import { App } from './web/App'
import { Configuration } from 'shared/services/Configuration'
import { store } from 'shared/reducers/store'

Bugsnag.start({
  apiKey: Configuration.bugsnagWebKey,
  plugins: [new BugsnagPluginReact()],
  releaseStage: Configuration.environment,
  enabledReleaseStages: ['production', 'staging'],
  onError: function (event) {
    event.addMetadata('redux', store.getState())
    event.addMetadata('storage', localStorage)
  },
})

// Fix for browser translation issue: https://github.com/facebook/react/issues/11538#issuecomment-417504600
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        console.error('Cannot remove a child from a different parent', child, this)
      }
      return child
    }
    return originalRemoveChild.apply(this, arguments)
  }

  const originalInsertBefore = Node.prototype.insertBefore
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error(
          'Cannot insert before a reference node from a different parent',
          referenceNode,
          this,
        )
      }
      return newNode
    }
    return originalInsertBefore.apply(this, arguments)
  }
}

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
const container = document.getElementById('app')
const root = createRoot(container)

root.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
)
