import React, { useMemo } from 'react'
import styled from 'styled-components'
import { faClipboardListCheck } from '@fortawesome/pro-light-svg-icons'

import * as Cross from 'shared/components/Cross'
import { Colors } from 'shared/styles/Colors'
import { Divider, ItemDot, Row, Strong } from 'shared/components/Invoice/styles'
import { Icon } from 'shared/components/Icon/Icon'
import { useInvoiceContext } from 'shared/contexts/InvoiceContext'

export const QuestionSection = ({ show, Container = React.Fragment }) => {
  const { order } = useInvoiceContext()
  const { customQuestions } = order

  const hasQuestions = useMemo(() => {
    return Boolean(customQuestions?.length)
  }, [customQuestions])

  if (!show) return null
  if (!hasQuestions) return null

  return (
    <Container>
      <Row $marginBottom={10}>
        <Icon icon={faClipboardListCheck} size={20} color={Colors.blue} />

        <Strong $marginLeft={10}>Questionnaire:</Strong>
      </Row>

      {customQuestions.map((qa, index) => (
        <Content key={index}>
          <Row>
            <Strong $marginRight={4}>{qa.question}</Strong>
          </Row>

          {qa.answer && (
            <Row>
              <Cross.Text>{qa.answer}</Cross.Text>
            </Row>
          )}

          {qa.answers?.map((answer, i) => (
            <Row key={i}>
              <ItemDot />
              <Cross.Text>{answer}</Cross.Text>
            </Row>
          ))}
        </Content>
      ))}

      <Divider />
    </Container>
  )
}

const Content = styled.div({
  marginTop: 5,
})
