import React from 'react'

import * as Cross from 'shared/components/Cross'
import { Colors } from 'shared/styles/Colors'
import { Configuration } from 'shared/services/Configuration'
import { Fonts } from 'shared/styles/Typography'
import { styler } from 'shared/utils/styler'

export const OptionRow = ({ label, value, onChange, helpText }) => {
  return (
    <>
      <Option>
        <OptionText>{label}</OptionText>
        <Cross.Toggle value={value} onChange={onChange} />
      </Option>
      {!!helpText && <HelpText>{helpText}</HelpText>}
      <Divider />
    </>
  )
}

export const SharedComponent = ({ hidePushOptions, settings, toggleSetting }) => {
  if (hidePushOptions)
    return (
      <>
        <SectionHeader style={{ marginTop: 20 }}>Order Requests</SectionHeader>
        <SectionDescription>
          Receive notifications for order requests and other order updates.
        </SectionDescription>
        <OptionRow
          label="Email"
          value={settings.emailReminders}
          onChange={() => toggleSetting('emailReminders')}
        />
      </>
    )

  return (
    <>
      <SectionHeader>Messages</SectionHeader>
      <SectionDescription>
        Receive notifications from Bakesy customer support and for other Bakesy related updates and
        announcements.
      </SectionDescription>
      <OptionRow
        label="Push notifications"
        value={settings.pushNotificationsMessages}
        onChange={() => toggleSetting('pushNotificationsMessages')}
      />

      <SectionHeader style={{ marginTop: 20 }}>Order Requests</SectionHeader>
      <SectionDescription>
        Receive notifications for order requests and other order updates.
      </SectionDescription>
      <OptionRow
        label="Email"
        value={settings.emailReminders}
        onChange={() => toggleSetting('emailReminders')}
      />
      <OptionRow
        label="Push notifications"
        value={settings.pushNotificationsReminders}
        onChange={() => toggleSetting('pushNotificationsReminders')}
      />
    </>
  )
}

const Option = styler(Cross.View)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const OptionText = styler(Cross.Text)({
  fontSize: 20,
})

export const SectionHeader = styler(Cross.Text)({
  fontSize: 20,
  fontWeight: Configuration.isNative ? undefined : 'bold',
  fontFamily: Configuration.isNative ? Fonts.SourceSansPro.Bold : undefined,
  marginBottom: 10,
  display: 'flex',
})

export const SectionDescription = styler(Cross.Text)({
  marginBottom: 20,
  display: 'flex',
  fontWeight: '500',
})

const Divider = styler(Cross.View)({
  height: 1,
  backgroundColor: Colors.grey10,
  marginTop: 20,
  marginBottom: 20,
})

const HelpText = styler(Cross.Text)({
  fontFamily: Fonts.SourceSansPro.Italic,
  marginTop: 10,
  fontSize: 16,
  color: Colors.grey50,
})
