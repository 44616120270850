import React from 'react'
import styled from 'styled-components'

import { addColon } from 'shared/utils/textTransforms'
import { Colors } from 'shared/styles/Colors'
import { validateEmail } from 'shared/utils/email'

const PROVIDER_URL = 'https://paypal.me/'
const PROVIDER_DOMAIN = 'paypal.me'
const PROTOCOL = 'https://'

export const PayPal = ({ paymentMethod }) => {
  const username = paymentMethod.username

  const createLink = (data) => {
    if (data.includes(PROVIDER_DOMAIN) && data.includes('http')) return data
    if (data.includes(PROVIDER_DOMAIN) && !data.includes('http')) return PROTOCOL.concat(data)
    else return PROVIDER_URL.concat(data)
  }

  const renderContent = () => {
    if (!username) return null

    const data = username.toLowerCase()

    switch (true) {
      case validateEmail(data):
        return addColon(<PaymentLink href={`mailto:${username}`}>{username}</PaymentLink>)

      default:
        const link = createLink(data)
        return addColon(<PaymentLink href={link}>{link}</PaymentLink>)
    }
  }

  return (
    <span>
      {paymentMethod.displayType}
      {username && renderContent()}
    </span>
  )
}

const PaymentLink = styled.a({
  color: Colors.blue,
})
