import Bugsnag from 'shared/services/Bugsnag'
import { addDraft, removeDraft, sendMessage } from 'shared/reducers/messenger'
import { directUpload, imageInterface } from 'shared/utils/directUpload'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

const MAX_IMAGES = 4

export const useMessageSender = ({
  receiverId,
  notifyTooManyImages,
  onSendFailure,
  preMessage,
}) => {
  const dispatch = useDispatch()

  const draft = useSelector((state) => state.messenger.drafts[receiverId]) || {}

  const [isSending, setIsSending] = useState(false)
  const [message, setMessage] = useState(preMessage || draft.message || '')
  const [images, setImages] = useState(draft.images || [])

  const draftRef = useRef({})

  useEffect(() => {
    draftRef.current = { message, images }
  }, [images, message])

  useEffect(() => {
    return () => {
      if (draftRef.current.images?.length || draftRef.current.message) {
        dispatch(addDraft({ receiverId, ...draftRef.current }))
      }
    }
  }, [dispatch, receiverId])

  const onSend = async () => {
    if (!message.length && !images?.length) return
    setIsSending(true)

    try {
      let signedImageIds = []

      for (let i = 0; i < images?.length; i++) {
        const image = images[i].data

        const fileInterface = await imageInterface(image)
        const directUploadJson = await directUpload(fileInterface)

        const { signedId } = directUploadJson
        signedImageIds.push(signedId)
      }

      await dispatch(sendMessage(message, { receiverId, images: signedImageIds }))

      setMessage('')
      setImages([])
    } catch (e) {
      setIsSending(false)
      console.error('error sending message', e)
      Bugsnag.notify(e)
      onSendFailure()
    } finally {
      dispatch(removeDraft(receiverId))
      setIsSending(false)
    }
  }

  const addImages = (imagesData) => {
    let notify = false

    setImages((prev) => {
      const newImages = [
        ...prev,
        ...imagesData.map((data) => {
          return { id: uuidv4(), data }
        }),
      ]
      if (newImages.length > MAX_IMAGES) {
        notify = true
      }
      return newImages.slice(0, MAX_IMAGES)
    })

    if (notify) notifyTooManyImages(MAX_IMAGES)
  }

  const removeImage = (id) => {
    setImages((prev) => prev.filter((img) => img.id !== id))
  }

  return { addImages, message, setMessage, images, isSending, onSend, removeImage }
}
