import React from 'react'
import { gql } from '@apollo/client'
import { Navigate, useParams } from 'react-router-dom'

import { useBakesyQuery } from 'shared/hooks/useBakesyQuery'

export const ItemRedirect = () => {
  const { id, slug } = useParams()

  const { data: bakery } = useBakesyQuery(GET_BAKERY_ITEMS, {
    variables: { slug },
    transofrm: (data) => data?.bakery,
    initialValue: null,
  })

  if (bakery)
    return (
      <Navigate
        to={`/b/${slug}/products/${bakery?.selectedBakedGoods?.find((i) => i.id === id)?.slug}`}
      />
    )
}

const GET_BAKERY_ITEMS = gql`
  query getBakery($slug: String) {
    bakery(slug: $slug) {
      selectedBakedGoods: offerings(
        offeringTypes: [bakedGood, menuItem, presaleItem]
        selected: true
      ) {
        id
        slug
      }
    }
  }
`
