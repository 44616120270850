import React from 'react'
import { capitalize } from 'lodash'

export const removeUnderscores = (str) => capitalize(str.replace('_', () => ' '))

export const addColon = (value) => {
  if (typeof value === 'string') return `: ${value}`
  else {
    return (
      <>
        {': '}
        {value}
      </>
    )
  }
}
