import styled from 'styled-components'
import { Colors } from 'shared/styles/Colors'
import { Link } from 'react-router-dom'

export const CardBackground = {
  backgroundColor: Colors.white,
  borderRadius: 4,
  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
}

export const TextLink = styled(Link)`
  color: ${Colors.blue};
  text-decoration: underline;
`
