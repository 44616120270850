import React, { useState } from 'react'
import styled from 'styled-components'

import { AgreementContainer } from './OtherDetails'
import { BooleanInput } from 'web/components/form'
import { Colors } from 'shared/styles/Colors'
import { Header } from 'web/pages/OrderRequest/styles'
import { OrderRequestLayout } from 'web/pages/OrderRequest/OrderRequestLayout'
import { useOrderContext } from 'web/contexts/OrderContext'

export const TermsAndConditions = () => {
  const { bakery } = useOrderContext()
  const { termsAndConditions } = bakery

  const [agreed, setAgreed] = useState(false)

  const onNext = ({ navigateToNextScreen }) => navigateToNextScreen()

  return (
    <OrderRequestLayout nextDisabled={!agreed} onNext={onNext} nextButtonText="Next">
      <Container>
        <Header>Terms & Conditions</Header>

        <TermsContainer>
          <TermsContentContainer>
            <TermsContent>{termsAndConditions}</TermsContent>
          </TermsContentContainer>
        </TermsContainer>

        <AgreementContainer>
          <BooleanInput
            name="terms_agree"
            label="I agree"
            checked={agreed}
            onChange={(e) => setAgreed(e.target.checked)}
            labelStyle={{ fontStyle: 'italic' }}
          />
        </AgreementContainer>
      </Container>
    </OrderRequestLayout>
  )
}

const Container = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  outline: 0,
})

const TermsContainer = styled.div({
  background: `linear-gradient(0deg, ${Colors.grey50} 0%, ${Colors.grey25} 100%)`,
  height: '50vh',
  padding: 5,
  width: '100%',
})

const TermsContentContainer = styled.div({
  background: Colors.defaultBackground,
  borderRadius: 5,
  paddingTop: 10,
  paddingBottom: 10,
  paddingRight: 5,
  width: '100%',
  height: '100%',
})

const TermsContent = styled.div`
  padding-left: 10px;
  padding-right: 5px;
  height: 100%;
  overflow-y: scroll;
  position: relative;
  white-space: pre-wrap;

  scrollbar-width: thin;
  scrollbar-color: ${Colors.brand} ${Colors.brandFaded};

  &::-webkit-scrollbar {
    position: absolute;
    right: 20px;
    width: 6px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: ${Colors.brandFaded};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.brand};
    border-radius: 20px;
  }
`
