import React from 'react'
import styled from 'styled-components'
import { startsWith } from 'lodash'

import { addColon } from 'shared/utils/textTransforms'
import { Colors } from 'shared/styles/Colors'

const PROVIDER_URL = 'https://venmo.com/'
const PROVIDER_DOMAIN = 'venmo.com'
const PROTOCOL = 'https://'

export const Venmo = ({ paymentMethod }) => {
  const username = paymentMethod.username

  const createLink = () => {
    if (!username) return null

    const data = paymentMethod.username.toLowerCase()

    if (data.includes(PROVIDER_DOMAIN) && data.includes('http')) return data
    if (data.includes(PROVIDER_DOMAIN) && !data.includes('http')) return PROTOCOL.concat(data)
    if (startsWith(data, '@')) return PROVIDER_URL.concat(data.substring(1))
    else return PROVIDER_URL.concat(data)
  }

  const link = createLink()

  return (
    <span>
      {paymentMethod.displayType}
      {link && addColon(<PaymentLink href={link}>{link}</PaymentLink>)}
    </span>
  )
}

const PaymentLink = styled.a({
  color: Colors.blue,
})
