import React from 'react'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

import * as Cross from 'shared/components/Cross'
import { Colors } from 'shared/styles/Colors'
import { Icon } from './Icon/Icon'
import { styler } from 'shared/utils/styler'

export const RemoveBox = ({ onClick, containerStyles = null }) => (
  <Close style={containerStyles}>
    <Cross.LinkedView
      style={{
        width: 30,
        height: 30,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
      onClick={onClick}
    >
      <Icon icon={faTimes} color={Colors.white} />
    </Cross.LinkedView>
  </Close>
)

const Close = styler(Cross.View)({
  position: 'absolute',
  top: 0,
  right: 0,
  width: 30,
  height: 30,
  backgroundColor: Colors.grey100,
  borderBottomLeftRadius: 4,
})
