import React, { createContext, useContext, useEffect, useState } from 'react'
import { headerHeight } from 'web/styles/Layout'

import { getWindowDims } from 'web/utils/getWindowDims'

const WindowDimensionsContext = createContext(null)

export const WindowDimensionsProvider = ({ children }) => {
  const [dimensions, setDimensions] = useState(getWindowDims)
  const handleResize = () => {
    setDimensions(getWindowDims)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const value = {
    ...dimensions,
    layoutHeight: dimensions.height - headerHeight - (dimensions.bannerHeight || 0),
    setDimensions,
  }

  return (
    <WindowDimensionsContext.Provider value={value}>{children}</WindowDimensionsContext.Provider>
  )
}

export const useWindowDimensions = () => {
  return useContext(WindowDimensionsContext)
}
