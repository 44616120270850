import { Fragments } from 'shared/constants/Fragments'
import { gql } from '@apollo/client'

export const VERIFY_SERVICE_ACCOUNT = gql`
  ${Fragments.CurrentUser}

  mutation VerifyServiceAccount($input: VerifyServiceAccountInput!) {
    verifyServiceAccount(input: $input) {
      user {
        ...CurrentUserFragment
      }
      jwt
      errors
      refreshToken
      serviceData {
        name
        email
        token
        tokenType
        provider
      }
    }
  }
`
