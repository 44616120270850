import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  categories: [],
}

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    addCategory: (state, action) => {
      state.categories.push(action.payload)
    },
    removeCategory: (state, action) => {
      const defaultIndex = state.categories.findIndex((category) => category.default)
      const index = state.categories.findIndex((category) => category.id === action.payload)

      const defaultCategory = state.categories[defaultIndex]
      const category = state.categories[index]

      defaultCategory.offerings = [...category.offerings, ...defaultCategory.offerings]
      defaultCategory.offeringsCount = defaultCategory.offerings.length
      if (index > -1) state.categories.splice(index, 1)
    },
    replaceCategory: (state, action) => {
      const index = state.categories.findIndex((category) => category.id === action.payload.id)
      if (index > -1) state.categories[index] = action.payload
    },
    setCategories: (state, action) => {
      state.categories = action.payload
    },
    updateCategoryOffering: (state, action) => {
      const { offering } = action.payload

      const categories = state.categories
      const offeringCategoryIdx = categories.findIndex((cat) => cat.id === offering.categoryId)

      if (offeringCategoryIdx < 0) return null

      const categoryOfferings = [...categories[offeringCategoryIdx].offerings]
      const offeringIdx = categoryOfferings.findIndex((off) => off.id === offering.id)

      if (offeringIdx >= 0) categoryOfferings[offeringIdx] = offering
      else categoryOfferings.push(offering)

      state.categories[offeringCategoryIdx].offerings = categoryOfferings
    },
  },
})

export const {
  addCategory,
  removeCategory,
  replaceCategory,
  setCategories,
  updateCategoryOffering,
} = categoriesSlice.actions

export const categoriesReducer = categoriesSlice.reducer
