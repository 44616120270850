import styled from 'styled-components'

import { Colors } from 'shared/styles/Colors'
import { MediaQueries } from 'web/styles/responsive'

export const Header = styled.h1({
  fontWeight: 'bold',
  fontSize: '1.8rem',
  marginBottom: '1em',
  textAlign: 'center',

  [`@media ${MediaQueries.mdUp}`]: {
    fontSize: '3.0rem',
  },
})

export const HelpText = styled.div({
  color: Colors.grey50,
  fontSize: '3vw',
  fontWeight: 500,

  [`@media ${MediaQueries.smUp}`]: {
    fontSize: '12px',
  },
})
