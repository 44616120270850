import { useCallback, useMemo, useRef, useState } from 'react'
import { useQuery } from '@apollo/client'

const TRANSFORM_FALLBACK = (data) => data

export const useBakesyQuery = (query, options) => {
  const { transform, initialValue, ...rest } = options

  const [dataInternal, setDataInternal] = useState(null)

  /*
   * The values below change on every render and we cannot consistently check if those are changed or not,
   * so instead of passing them as dependencies to the useCallback, we use refs to keep the initial values.
   *
   * It shouldn't even matter if the values change, because the query should be the same.
   */
  const transformRef = useRef(transform ?? TRANSFORM_FALLBACK)
  const initialValueRef = useRef(initialValue)

  const { data: fetched, ...restQuery } = useQuery(query, {
    ...rest,
  })

  const fallbackValue = useMemo(() => {
    return transformRef.current(fetched) ?? initialValueRef.current
  }, [fetched])

  const data = useMemo(() => dataInternal ?? fallbackValue, [dataInternal, fallbackValue])

  const setData = useCallback(
    (nextValue) => {
      setDataInternal((prevData) => {
        if (typeof nextValue === 'function') {
          return nextValue(prevData ?? data)
        } else {
          return nextValue
        }
      })
    },
    [data],
  )

  return { data, setData, ...restQuery }
}
