import { useEffect, useRef } from 'react'

export function useEffectOnce(effect, condition = true) {
  const hasRun = useRef(false)

  useEffect(() => {
    if (!hasRun.current && condition) {
      effect()
      hasRun.current = true
    }
  }, [condition, effect])
}
