import React, { useState } from 'react'
import styled from 'styled-components'
import { gql, useMutation } from '@apollo/client'

import { Button } from 'web/components/Button'
import { FixedWidthContainer, MediaQueries } from 'web/styles/responsive'
import { TextField } from 'web/components/form/TextField'

export const PasswordReset = () => {
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const queryString = new URLSearchParams(window.location.search)
  const [resetPasswordMutation] = useMutation(RESET_PASSWORD_MUTATION)

  const onSubmit = async (event) => {
    event.preventDefault()
    setError(null)
    setLoading(true)

    try {
      const {
        data: {
          resetPassword: { error: submitError },
        },
      } = await resetPasswordMutation({
        variables: {
          input: {
            email: queryString.get('email'),
            token: queryString.get('token'),
            password: password,
          },
        },
      })

      if (submitError) {
        setError(submitError)
        setLoading(false)
      } else {
        setLoading(false)
        alert('Your password has been changed. Return to the Bakesy app and try logging in.')
      }
    } catch (e) {
      setError('Unknown error resetting password.')
      setLoading(false)
      console.log(e)
    }
  }

  return (
    <StyledFixedWidthContainer>
      <h2>Choose a new password</h2>
      <form onSubmit={onSubmit}>
        <TextField
          autoFocus
          type="password"
          autoComplete="new-password"
          label={false}
          placeholder="New password"
          minLength={8}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={error}
          required
        />
        <Button fullWidth button disabled={loading} size="large" style={{ marginTop: 20 }}>
          Save
        </Button>
      </form>
    </StyledFixedWidthContainer>
  )
}

const StyledFixedWidthContainer = styled(FixedWidthContainer)`
  @media ${MediaQueries.mdUp} {
    width: 550px !important;
    margin: 30vh auto !important;
  }
`

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      error
    }
  }
`
