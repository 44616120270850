import {
  createFragmentRegistry,
  defaultDataIdFromObject,
  InMemoryCache,
} from '@apollo/client/cache'

import * as fragments from 'shared/graphql/fragments'

const fragmentRegistry = createFragmentRegistry(...Object.values(fragments))

export const cache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case 'Offering':
        /**
         * For offerings we do not always have a slug, so if we do not
         * have one we should use the default data id from object.
         **/
        if (responseObject.slug) return `Offering:${responseObject.slug}`
        else return defaultDataIdFromObject(responseObject)

      default:
        return defaultDataIdFromObject(responseObject)
    }
  },
  fragments: fragmentRegistry,
  typePolicies: {
    Category: {
      keyFields: ['slug'],
    },
  },
})
