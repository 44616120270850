import { Configuration } from 'shared/services/Configuration'
import { insertIfElse } from 'shared/utils/insertIfFunctions'

const hardShadow = '7px 7px 0px 0px rgba(0,0,0,0.75)'
const softShadow = '0px 5px 10px 0px rgba(0,0,0,0.2)'

export const outlineButtons = {
  outline0: { borderRadius: 0, boxShadow: 'none' },
  outline10: { borderRadius: '10px', boxShadow: 'none' },
  outline100: { borderRadius: '100px', boxShadow: 'none' },
}

export const hardShadowButtons = {
  hardShadow0: { borderRadius: 0, boxShadow: hardShadow },
  hardShadow10: { borderRadius: '10px', boxShadow: hardShadow },
  hardShadow100: { borderRadius: '100px', boxShadow: hardShadow },
}

export const softShadowButtons = {
  softShadow0: { borderRadius: 0, boxShadow: softShadow, borderWidth: 0 },
  softShadow10: { borderRadius: '10px', boxShadow: softShadow, borderWidth: 0 },
  softShadow100: { borderRadius: '100px', boxShadow: softShadow, borderWidth: 0 },
}

export const buttonVariants = {
  ...outlineButtons,
  ...hardShadowButtons,
  ...softShadowButtons,
}

export const dividerVariants = {
  1: require('shared/images/PremiumThemes/divider1.png'),
  2: require('shared/images/PremiumThemes/divider2.png'),
  3: require('shared/images/PremiumThemes/divider3.png'),
  4: require('shared/images/PremiumThemes/divider4.png'),
  5: require('shared/images/PremiumThemes/divider5.png'),
}

// These are in rems for web and for native we simply multiply these by 10
export const fontSizeAdjustments = {
  'AmaticSC-Bold': 1.5,
  'BerkshireSwash-Regular': 0.3,
  'FrederickatheGreat-Regular': 0.3,
  'LobsterTwo-Bold': 0.5,
  'Lobster-Regular': 0.5,
  'Pacifico-Regular': 0.5,
  'Philosopher-Regular': 0.8,
  'Qwigley-Regular': 1.5,
  'Sacramento-Regular': 1.2,
  'Sail-Regular': 0.8,
  Zeyada: 1.1,
}

export const fontPositionAdjustments = {
  'Cambay-Regular': { position: 'relative', top: insertIfElse(Configuration.isWeb, '0.3rem', 4) },
  'NunitoSans7pt-Regular': {
    position: 'relative',
    top: insertIfElse(Configuration.isWeb, '0.2rem', 2),
  },
  'Poppins-Regular': {
    position: 'relative',
    top: insertIfElse(Configuration.isWeb, '0.1rem', 1),
  },
  'Roboto-Regular': {
    position: 'relative',
    top: insertIfElse(Configuration.isWeb, '0.2rem', 2),
  },
}
