import React from 'react'
import styled from 'styled-components'
import { Button } from 'web/components/Button'
import { Colors } from 'shared/styles/Colors'
import { TextField } from 'web/components/form/TextField'
import { TextLink } from 'web/styles/shared'
import { useMobileNumberVerification } from 'shared/hooks/useMobileNumberVerification'

export const VerifyNumber = React.memo(({ mobileNumber, setVerifying }) => {
  const { isLoading, error, code, setCode, onSubmit } = useMobileNumberVerification()

  return (
    <Container>
      <Header>Enter 6-digit verification</Header>

      <MobileNumberDescription style={{ marginTop: 0, marginBottom: 20 }}>
        Sent to you at {mobileNumber}.
      </MobileNumberDescription>

      <TextField
        error={error}
        onChange={(e) => setCode(e.target.value)}
        Container={InputContainer}
        placeholder="000000"
        style={{ fontSize: '3.4rem' }}
        value={code}
        maxLength="6"
        inputMode="numeric"
        pattern="[0-9]*"
        autocomplete="one-time-code"
      />
      <Button fullWidth onClick={onSubmit} disabled={code.length !== 6 || isLoading} button>
        Verify
      </Button>
      <MobileNumberDescription style={{ fontSize: '1.6rem' }}>
        Haven't received the code?&nbsp;
        <TextLink onClick={() => setVerifying(null)} as="a">
          Resend it.
        </TextLink>
      </MobileNumberDescription>
    </Container>
  )
})

const Container = styled.div``
const InputContainer = styled.div({
  marginBottom: 20,
})

const Header = styled.div({
  fontWeight: 'bold',
  fontSize: '2.8rem',
  marginBottom: 20,
})

const MobileNumberDescription = styled.div({
  color: Colors.grey75,
  fontSize: 18,
  marginTop: 30,
})
