import AriaModal from 'react-aria-modal'
import pluralize from 'pluralize'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { ContentWithBreaks } from 'web/components/ContentWithBreaks'
import {
  faCalendarAlt,
  faClock,
  faFileInvoiceDollar,
  faMapMarkerAlt,
  faUsdCircle,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { Helmet } from 'react-helmet'
import { useFragment } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from 'web/components/Button'
import { CalendarComponent } from 'web/components/Calendar'
import { CardBackground } from 'web/styles/shared'
import { Colors } from 'shared/styles/Colors'
import {
  dividerVariants,
  fontPositionAdjustments,
  fontSizeAdjustments,
} from 'shared/styles/PremiumThemes'
import { FeaturedOfferings } from './FeaturedOfferings'
import { FixedWidthContainer } from 'web/styles/responsive'
import { formatCents } from 'shared/utils/currency'
import { insertKeyIf } from 'shared/utils/insertIfFunctions'
import { ReviewStars } from 'web/components/reviews/ReviewStars'
import { useBakeryContext } from 'web/contexts/BakeryContext'
import { useCurrentUser } from 'shared/hooks/useCurrentUser'
import { UserAvatar } from 'shared/components/UserAvatar/UserAvatar'
import { useResponsiveLayout } from 'web/hooks/useResponsiveLayout'
import { UserFragment } from 'shared/graphql/fragments'
import { useThemeContext } from 'web/contexts/ThemeContext'

const buttonStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 42,
  padding: '5px 30px',
}

const OfferingsComponent = ({ array, title, primaryColor, backgroundColor, font }) =>
  !!array.length && (
    <>
      <SubHeading $style={{ marginTop: 0 }} font={font}>
        {title}
      </SubHeading>

      <div style={{ marginBottom: 30 }}>
        {array.map((o) => (
          <span key={o.id || Math.random()}>
            <Offering $primaryColor={primaryColor} $backgroundColor={backgroundColor}>
              {o.name || o}
            </Offering>{' '}
          </span>
        ))}
      </div>
    </>
  )

const DividerComponent = () => {
  const { dividerVariant, backgroundColor, primaryColor } = useThemeContext()
  const isDefaultDesign = dividerVariant === 0

  return (
    <Divider
      backgroundImage={dividerVariants[dividerVariant]}
      backgroundColor={isDefaultDesign ? backgroundColor : primaryColor}
    />
  )
}

export const BakeryDetailsPage = () => {
  const currentUser = useCurrentUser()
  const { isMdUp } = useResponsiveLayout()
  const { slug } = useParams()
  const navigate = useNavigate()
  const {
    actionButtonName,
    availabilityHidden,
    backgroundColor,
    coverImage,
    dividerVariant,
    primaryColor,
    primaryFont,
    secondaryFontRaw,
    storefrontActionHidden,
  } = useThemeContext()

  const { bakery } = useBakeryContext()
  const reviewSummary = bakery?.reviewSummary

  const [isCalendarOpen, setIsCalendarOpen] = useState(false)

  const { data: user } = useFragment({
    fragment: UserFragment,
    fragmentName: 'User',
    from: {
      __typename: 'User',
      id: bakery.user.id,
    },
  })

  const hasAvailabilityButton = useMemo(
    () => currentUser?.id !== user.id && !bakery.ordersPaused && !availabilityHidden,
    [availabilityHidden, bakery.ordersPaused, currentUser, user],
  )

  const showPolicies = useMemo(
    () => !!bakery.requiredDepositPercentage || bakery.leadTimeValue || bakery.minimumOrderInCents,
    [bakery],
  )

  const navigateItemDetails = useCallback(
    (productSlug) => () => {
      navigate(`/b/${slug}/products/${productSlug}`, { state: { fromMain: true } })
    },
    [slug, navigate],
  )

  const customSectionsToShow = useMemo(() => {
    if (bakery?.customSections?.length) return bakery.customSections.filter((cs) => !cs.category)
    return []
  }, [bakery?.customSections])

  const pageTitle = `${bakery.name} on Bakesy`
  const ogTitle = `Visit ${bakery.name} on Bakesy to order!`
  const description = bakery.bio
  const isDefaultDesign = dividerVariant === 0
  const image = coverImage ? `url(${coverImage})` : null
  const hasCustomSections = !!customSectionsToShow.length
  const ogImage = bakery.galleryImage

  return (
    <Container isDefaultDesign={isDefaultDesign}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={ogTitle} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />

        {ogImage && [
          <meta
            key="og:image"
            property="og:image"
            content={`${ogImage.thumbnailUrl}?v=${ogImage.updatedAt}`}
          />,
          <meta key="og:image:width" property="og:image:width" content="400" />,
          <meta key="og:image:height" property="og:image:height" content="400" />,
        ]}
      </Helmet>

      <MainColumn isMdUp={isMdUp}>
        <HeaderCard isDefaultDesign={isDefaultDesign}>
          <CoverPhoto image={image} bgColor={backgroundColor} />

          <Header marginTop={isDefaultDesign ? -73 : 0}>
            {isDefaultDesign && <UserAvatar user={bakery.user} bakery={bakery} size={144} />}
            <Name defaultStyle={isDefaultDesign} font={primaryFont}>
              {bakery.name}
            </Name>
            <ProfileBlock defaultStyle={isDefaultDesign}>
              <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: '10px' }} />
              {!!bakery.location && `${bakery.location.name}, ${bakery.location.region.name}`}
            </ProfileBlock>
          </Header>
          {!!reviewSummary?.count && (
            <StarsContainer>
              <ReviewStars rating={reviewSummary.average} count={reviewSummary.count} />
            </StarsContainer>
          )}
          <ButtonsContainer isMdUp={isMdUp}>
            {!storefrontActionHidden && (
              <Button
                to={`/order-request/new/${bakery.id}`}
                disabled={bakery.ordersPaused}
                style={{
                  marginTop: '20px',
                  marginRight: isMdUp && hasAvailabilityButton ? '20px' : '0px',
                  fontSize: !isMdUp && bakery.ordersPaused && '1.6rem',
                  ...buttonStyles,
                }}
                color={primaryColor}
                fullWidth={!isMdUp}
              >
                <ButtonTextContainer font={secondaryFontRaw}>
                  {bakery.ordersPaused ? 'Not accepting orders at this time' : actionButtonName}
                </ButtonTextContainer>
              </Button>
            )}
            {hasAvailabilityButton && (
              <Button
                onClick={(e) => {
                  e?.preventDefault()
                  setIsCalendarOpen(true)
                }}
                fullWidth={!isMdUp}
                style={{
                  marginTop: '20px',
                  ...buttonStyles,
                }}
                type="alt"
                color={primaryColor}
              >
                <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: '10px' }} />
                <ButtonTextContainer font={secondaryFontRaw}>Availability</ButtonTextContainer>
              </Button>
            )}
          </ButtonsContainer>
          {isCalendarOpen ? (
            <AriaModal
              titleText="Availability"
              underlayStyle={{ paddingTop: '40px', paddingBottom: '40px' }}
              verticallyCenter
              onExit={() => setIsCalendarOpen(false)}
            >
              <CalendarComponent
                bakery={bakery}
                modalStyle={ReactCalenderModalStyle}
                onChange={(date) =>
                  navigate(`/order-request/new/${bakery.id}`, {
                    state: { completionDate: format(date, 'yyyy-MM-dd') },
                  })
                }
              />
            </AriaModal>
          ) : null}
        </HeaderCard>

        <DividerComponent />

        {!!bakery.featuredOfferings?.length && (
          <>
            <Card isDefaultDesign={isDefaultDesign}>
              <SubHeading font={primaryFont}>
                {bakery.featuredProductsHeader ?? 'Featured Products'}
              </SubHeading>
              <FeaturedOfferings
                offerings={bakery.featuredOfferings}
                logo={bakery?.user?.avatar?.largeUrl || bakery?.user?.avatar?.smallUrl}
                currency={bakery.currency}
                onItemClick={navigateItemDetails}
              />
            </Card>

            <DividerComponent />
          </>
        )}

        {!!bakery.bio?.length && (
          <>
            <Card isDefaultDesign={isDefaultDesign}>
              <SubHeading font={primaryFont}>About</SubHeading>
              <Bio>
                <ContentWithBreaks content={bakery.bio} />
              </Bio>
            </Card>

            <DividerComponent />
          </>
        )}

        {(!!bakery.bakedGoods?.length ||
          !!bakery.cakeFlavors?.length ||
          !!bakery.cookieFlavors?.length) && (
          <>
            <Card isDefaultDesign={isDefaultDesign}>
              <OfferingsComponent
                array={bakery.bakedGoods}
                title={'Offerings'}
                primaryColor={primaryColor}
                backgroundColor={backgroundColor}
                font={primaryFont}
              />
            </Card>

            <DividerComponent />
          </>
        )}
        {!!bakery.capabilitiesAndPricing?.length && (
          <>
            <Card isDefaultDesign={isDefaultDesign}>
              <SubHeading font={primaryFont}>Capabilities & Pricing</SubHeading>
              <Bio>
                <ContentWithBreaks content={bakery.capabilitiesAndPricing} />
              </Bio>
            </Card>

            <DividerComponent />
          </>
        )}

        {showPolicies && (
          <>
            <Card isDefaultDesign={isDefaultDesign}>
              <SubHeading font={primaryFont}>Policies</SubHeading>

              {bakery.minimumOrderInCents && (
                <PolicyLine>
                  <PolicyIcon icon={faFileInvoiceDollar} />
                  <PolicyDescription>
                    Order minimum:{' '}
                    <PolicyValue>
                      {formatCents(bakery.minimumOrderInCents, bakery.currency, false)}
                    </PolicyValue>
                  </PolicyDescription>
                </PolicyLine>
              )}

              {!!bakery.requiredDepositPercentage && (
                <PolicyLine>
                  <PolicyIcon icon={faUsdCircle} />
                  <PolicyDescription>
                    Typical Deposit: <PolicyValue>{bakery.requiredDepositPercentage}%</PolicyValue>
                  </PolicyDescription>
                </PolicyLine>
              )}

              {bakery.leadTimeValue && (
                <PolicyLine>
                  <PolicyIcon icon={faClock} />
                  <PolicyDescription>
                    Typical lead-time needed:{' '}
                    <PolicyValue>
                      {pluralize(bakery.leadTimeUnit, bakery.leadTimeValue, true)}
                    </PolicyValue>
                  </PolicyDescription>
                </PolicyLine>
              )}
            </Card>

            {hasCustomSections && <DividerComponent />}
          </>
        )}

        {hasCustomSections &&
          customSectionsToShow.map((item, idx) => {
            const isLast = customSectionsToShow.length === idx + 1

            return item.kind === 'text_box' ? (
              <div key={item.id}>
                <Card isDefaultDesign={isDefaultDesign}>
                  <SubHeading font={primaryFont}>{item.title}</SubHeading>
                  <div>
                    <Text>{item.text}</Text>
                  </div>
                </Card>
                {!isLast && <DividerComponent />}
              </div>
            ) : (
              <div key={item.id}>
                <Card isDefaultDesign={isDefaultDesign}>
                  <OfferingsComponent
                    array={item.choices}
                    title={item.title}
                    primaryColor={primaryColor}
                    backgroundColor={backgroundColor}
                    font={primaryFont}
                  />
                </Card>
                {!isLast && <DividerComponent />}
              </div>
            )
          })}
      </MainColumn>
    </Container>
  )
}

const Container = styled(FixedWidthContainer)(({ isDefaultDesign }) => ({
  paddingBottom: isDefaultDesign ? 65 : 0,
  ...insertKeyIf(!isDefaultDesign, {
    backgroundColor: Colors.white,
    marginTop: 0,
    marginBottom: 0,
  }),
}))

const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: ${({ marginTop }) => marginTop}px;
`

export const Card = styled.div(({ isDefaultDesign }) => ({
  ...CardBackground,
  boxShadow: isDefaultDesign ? CardBackground.boxShadow : 'none',
  padding: '20px 30px',
}))

const HeaderCard = styled.div(({ isDefaultDesign }) => ({
  ...CardBackground,
  boxShadow: isDefaultDesign ? CardBackground.boxShadow : 'none',
  paddingBottom: '20px',
  position: 'relative',
}))

export const MainSubtitle = styled.h2(({ $style, font }) => ({
  marginTop: 0,
  marginBottom: 10,
  fontSize: `${2.4 + (fontSizeAdjustments[font] || 0)}rem`,
  ...$style,
}))

const SubHeading = styled(MainSubtitle)(({ font }) => ({
  fontSize: `${2 + (fontSizeAdjustments[font] || 0)}rem`,
}))

const ProfileBlock = styled.div`
  color: ${Colors.grey75};
  font-size: ${(props) => (props.defaultStyle ? '1.6rem' : '2rem')};
  font-weight: 600;
  text-align: center;
`
const Bio = styled.div``

const Name = styled.h1(
  ({ defaultStyle, font }) => `
    font-weight: 600;
    font-size: ${2.5 + (fontSizeAdjustments[font] || 0)}rem;
    margin: ${defaultStyle ? '5px' : '20px'} 0 10px;
    text-align: center;
    width: 100%;
    line-height: 150%;
  `,
)

const MainColumn = styled.div`
  flex: 1;
  padding-top: 20px;
  display: flex,
  justify-content: center;
`

const PolicyLine = styled.div`
  display: flex;
  margin-bottom: 15px;
`
const PolicyDescription = styled.div`
  flex: 1;
  margin-left: 10px;
`
const PolicyValue = styled.span`
  white-space: nowrap;
  font-weight: bold;
`
const PolicyIcon = styled(FontAwesomeIcon)`
  margin-top: 3px;
  min-width: 20px;
`

const ButtonsContainer = styled.div(
  ({ isMdUp }) => `
    display: flex;
    flex-direction: ${isMdUp ? 'row' : 'column'};
    align-items: center;
    justify-content: center;
    padding: ${isMdUp ? '0px' : '0px 10%'};
    margin-bottom: 20px;
  `,
)

const CoverPhoto = styled.div(
  ({ image, bgColor }) => `
    width: 100%;
    background: ${image || bgColor};
    background-size: cover;
    ${image ? 'aspect-ratio: 2.699;' : 'height: 92px;'}
  `,
)

const Offering = styled.span(
  ({ $primaryColor, $backgroundColor }) => `
    background-color: ${$backgroundColor};
    color: ${$primaryColor};
    padding: 5px 10px;
    font-size: 1.6rem;
    margin-right: 5px;
    margin-bottom: 8px;
    border-radius: 4px;
    display: inline-block;
  `,
)

const ReactCalenderModalStyle = {
  backgroundColor: `${Colors.white}`,
  padding: 20,
  borderRadius: 4,
  boxShadow: ' 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  width: '100%' - 20,
  position: 'relative',
  marginHorizontal: 20,
}

const Text = styled.p({
  whiteSpace: 'pre-wrap',
})

const StarsContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 10,
})

const Divider = styled.div(({ backgroundColor, backgroundImage }) => ({
  height: backgroundImage ? 50 : 20,
  backgroundColor,
  margin: '0 -20px',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'round',
}))

const ButtonTextContainer = styled.span(({ font }) => fontPositionAdjustments[font] || {})
