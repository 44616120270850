import qs from 'qs'
import React, { useEffect } from 'react'
import { openSignIn } from 'shared/reducers/ui'
import { Spinner } from 'shared/components/Spinner'
import { useDispatch } from 'react-redux'

export const OAuthRedirect = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const message = {}

    const params = window.location.hash.replace(/^#/, '')
    const parsedParams = qs.parse(params)

    // message.all = parsedParams
    message.provider = parsedParams.state
    message.token = parsedParams.access_token

    if (window.opener) {
      window.opener.postMessage(message, '*')
      window.close()
    } else {
      dispatch(openSignIn())
      window.postMessage(message, '*')
    }
  }, [dispatch])

  return <Spinner fullScreen />
}
