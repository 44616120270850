export class Message {
  constructor(payload, currentUser = null) {
    this.id = payload.id
    this.content = payload.content
    this.sentAt = payload.sentAt
    this.read = payload.read
    this.sender = payload.sender
    this.receiver = payload.receiver
    this.currentUser = payload.currentUser || currentUser
    this.images = payload.images
  }

  get data() {
    return {
      id: this.id,
      content: this.content,
      sentAt: this.sentAt,
      read: this.sender.id === this.currentUser.id || this.read,
      sender: this.sender,
      receiver: this.receiver,
      currentUser: this.currentUser,
      otherUser: this.otherUser,
      isSender: this.isSender,
      newConversation: !this.id,
      images: this.images,
    }
  }

  get otherUser() {
    return this.isSender ? this.receiver : this.sender
  }

  get isSender() {
    return this.sender.id === this.currentUser.id
  }

  get newConversation() {
    return !this.id
  }
}
