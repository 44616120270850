import React from 'react'
import styled from 'styled-components'
import { CardBackground } from 'web/styles/shared'
import { Colors } from 'shared/styles/Colors'
import { FetchError } from 'shared/components/FetchError'
import { formatISODate } from 'shared/utils/dates'
import { gql, useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'

import { MediaQueries } from 'web/styles/responsive'
import { Spinner } from 'shared/components/Spinner'
import { StateLabel } from 'shared/components/StateLabel'
import { useResponsiveLayout } from 'web/hooks/useResponsiveLayout'

export const OrderList = () => {
  const { isXs, isMdUp } = useResponsiveLayout()
  const { data, loading, error, refetch } = useQuery(ORDER_LIST_QUERY)

  if (loading) return <Spinner height={200} />
  if (error) return <FetchError height={200} onRetry={refetch} />

  const orders = data.ordersPlaced.results

  return (
    <Container>
      <Title>Orders</Title>
      {!orders.length && (
        <EmptyMessage>
          You haven't placed any orders yet. Visit a bakery page to request an order!
        </EmptyMessage>
      )}
      {!!orders.length && (
        <OrderTable>
          {!isXs && (
            <Header>
              <Col>Bakery</Col>
              {isMdUp && <Col>Items</Col>}
              <Col>Order Due</Col>
              {isMdUp && <Col>Sent on</Col>}
              <Col>Status</Col>
            </Header>
          )}
          <OrderTableBody>
            {orders.map((order) => (
              <BodyRow key={order.id}>
                {isXs ? (
                  <SmallListItem>
                    <SmallDetails>
                      {order.bakery.name}
                      <div style={{ fontSize: '1.2rem', marginTop: 3 }}>
                        Order Due:&nbsp;
                        {formatISODate(order.completionDate)}
                      </div>
                    </SmallDetails>
                    <SmallLabel>
                      <StateLabel state={order.state} />
                    </SmallLabel>
                  </SmallListItem>
                ) : (
                  <>
                    <Col>{order.bakery.name}</Col>
                    {isMdUp && (
                      <Col>{order.orderItems.map((oi) => oi.offering.name).join(', ')}</Col>
                    )}
                    <Col>{formatISODate(order.completionDate)}</Col>
                    {isMdUp && <Col>{formatISODate(order.sentAt)}</Col>}
                    <Col>
                      <StateLabel state={order.state} />
                    </Col>
                  </>
                )}
                <BodyRowLink to={`/orders/${order.orderNumber}`} />
              </BodyRow>
            ))}
          </OrderTableBody>
        </OrderTable>
      )}
    </Container>
  )
}

const Title = styled.div({
  fontSize: 22,
  fontWeight: 'bold',
  marginBottom: 20,
})

const Container = styled.div({})
const EmptyMessage = styled.div({})
const OrderTable = styled.div({})
const OrderTableBody = styled.div({
  ...CardBackground,
})

const Row = styled.div({
  display: 'flex',
})

const Col = styled.div({
  width: '33%',
  paddingRight: 20,

  '&:first-child': {
    paddingLeft: 20,
  },

  [`@media ${MediaQueries.mdUp}`]: {
    width: '20%',
  },
})

const Header = styled(Row)({
  color: Colors.grey75,
  marginBottom: 10,
})

const BodyRow = styled(Row)({
  borderBottom: `1px solid ${Colors.grey10}`,
  paddingTop: 20,
  paddingBottom: 20,
  position: 'relative',

  '&:last-child': {
    borderBottom: 0,
  },
})

const BodyRowLink = styled(Link)({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
})

const SmallListItem = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  paddingLeft: 20,
  paddingRight: 20,
})

const SmallDetails = styled.div({
  flex: 1,
})

const SmallLabel = styled.div({
  maxWidth: 100,
  div: {
    textAlign: 'center',
  },
})

export const ORDER_LIST_QUERY = gql`
  query OrderList {
    ordersPlaced {
      results {
        id
        completionDate
        state
        sentAt
        orderNumber
        bakery {
          name
        }
        orderItems {
          offering {
            name
          }
        }
      }
    }
  }
`
