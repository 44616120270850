import React from 'react'

import { Invoice } from 'shared/components/Invoice'
import { InvoiceProvider } from 'shared/contexts/InvoiceContext'

export const OrderDetailsPage = ({ printOrder = false }) => (
  <InvoiceProvider>
    <Invoice printOrder={printOrder} />
  </InvoiceProvider>
)
