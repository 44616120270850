import { createSlice } from '@reduxjs/toolkit'
import { gql } from '@apollo/client'

import { apolloClient } from 'shared/services/graphqlClient'
import { Configuration } from 'shared/services/Configuration'
import { Platform } from 'shared/services/Platform'
import { PlatformStorage } from 'shared/services/PlatformStorage'
import { StorageKeys } from 'shared/constants/constants'

const initialState = {
  authModalState: 'closed',
  lightboxImageUrl: null,
  appIntroComplete: false,
  promptNotifications: false,
  alert: null,
  hasOrderRequests: false,
  beforeYouStartComplete: false,
  appState: 'active',
  inSubActivity: false,
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    closeAuthModal: (state) => {
      state.authModalState = 'closed'
    },
    openSignIn: (state) => {
      state.authModalState = 'sign-in'
    },
    openCreateAccount: (state) => {
      state.authModalState = 'create-account'
    },
    setLightboxImage: (state, action) => {
      state.lightboxImageUrl = action.payload
    },
    setAppIntroComplete: (state, action) => {
      state.appIntroComplete = action.payload
    },
    setBeforeYouStartComplete: (state, action) => {
      state.beforeYouStartComplete = action.payload
    },
    setPromptNotifications: (state, action) => {
      state.promptNotifications = action.payload
    },
    setAlert: (state, action) => {
      state.alert = action.payload
    },
    setHasOrderRequests: (state, action) => {
      state.hasOrderRequests = action.payload
    },
    setAppState: (state, action) => {
      state.appState = action.payload
    },
    setInSubActivity: (state, action) => {
      state.inSubActivity = action.payload
    },
  },
})

export const {
  closeAuthModal,
  openSignIn,
  openCreateAccount,
  setLightboxImage,
  setAppIntroComplete,
  setBeforeYouStartComplete,
  setAlert,
  setPromptNotifications,
  setHasOrderRequests,
  setAppState,
  setInSubActivity,
} = uiSlice.actions

export const uiReducer = uiSlice.reducer

export const appStateSelector = (state) => state.ui.appState
export const inSubActivitySelector = (state) => state.ui.inSubActivity

export const initializeUi = () => {
  return async (dispatch, getState) => {
    // PlatformStorage.removeItem(StorageKeys.appIntroCompleteKey) // For testing
    const appIntroComplete =
      true || (await PlatformStorage.getItem(StorageKeys.appIntroCompleteKey))
    dispatch(setAppIntroComplete(appIntroComplete))

    // await PlatformStorage.removeItem(StorageKeys.beforeYouStartKey) // For testing
    const beforeYouStartComplete = await PlatformStorage.getItem(StorageKeys.beforeYouStartKey)
    dispatch(setBeforeYouStartComplete(beforeYouStartComplete))

    // PlatformStorage.removeItem(StorageKeys.notificationsSkipped, 't') // For testing
    // PlatformStorage.removeItem(StorageKeys.notificationsSystemPrompted, 't') // For testing
    const notificationsSkipped = await PlatformStorage.getItem(StorageKeys.notificationsSkipped)
    const notificationsSystemPrompted = await PlatformStorage.getItem(
      StorageKeys.notificationsSystemPrompted,
    )
    const promptNotifications =
      Platform.isIos && !notificationsSkipped && !notificationsSystemPrompted
    dispatch(setPromptNotifications(promptNotifications))
  }
}

export const notificationsSkipped = () => {
  return async (dispatch, getState) => {
    dispatch(setPromptNotifications(false))
    await PlatformStorage.setItem(StorageKeys.notificationsSkipped, 't')
  }
}

export const notificationsSystemPrompted = () => {
  return async (dispatch, getState) => {
    dispatch(setPromptNotifications(false))
    await PlatformStorage.setItem(StorageKeys.notificationsSystemPrompted, 't')
  }
}

export const refreshOrderRequestCount = () => {
  return async (dispatch, getState) => {
    if (!Configuration.isNative) return

    try {
      const {
        data: { ordersReceived },
      } = await apolloClient.query({
        query: orderRequestsQuery,
        variables: { state: 'requested' },
      })

      dispatch(setHasOrderRequests(ordersReceived.totalResults > 0))
    } catch (e) {
      console.log('error fetching order request count', e)
    }
  }
}

const orderRequestsQuery = gql`
  query GetOrderRequestsCount($state: [OrderState!]) {
    ordersReceived(state: $state) {
      totalResults
    }
  }
`
