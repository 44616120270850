import { createSlice } from '@reduxjs/toolkit'

import Bugsnag from 'shared/services/Bugsnag'
import { PlatformStorage } from 'shared/services/PlatformStorage'
import { refreshOrderRequestCount } from './ui'
import { setUser as setMessengerUser } from './messenger'
import { StorageKeys } from 'shared/constants/constants'

const initialState = {
  user: null,
  bakery: null,
  bootstrapped: false,
  authModalState: 'closed',
  jwt: null,
  refreshToken: null,
  bakeryIsLoading: true,
}

const addOffering = (selectedBakedGoods, offering) => {
  const index = selectedBakedGoods.findIndex((o) => o.name > offering.name)
  if (index >= 0) selectedBakedGoods.splice(index, 0, offering)
  else selectedBakedGoods.push(offering)
}

const removeOffering = (selectedBakedGoods, offering) => {
  const index = selectedBakedGoods.findIndex((o) => o.id === offering.id)
  if (index >= 0) selectedBakedGoods.splice(index, 1)
}

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setBootstrapped: (state) => {
      state.bootstrapped = true
    },
    setUser: (state, action) => {
      const user = action?.payload

      delete user?.bakery
      try {
        if (user) Bugsnag.setUser(user.id, user.email, user.name)
        else Bugsnag.setUser()
      } catch (e) {
        console.error(e)
        Bugsnag.notify(e)
      }
      state.user = user
    },
    setBakery: (state, action) => {
      state.bakery = state.bakery || action.payload ? { ...state.bakery, ...action.payload } : null
      state.bakeryIsLoading = false
    },

    setSettings: (state, action) => {
      state.user.settings = action.payload
    },

    addBakeryOffering: (state, action) => {
      addOffering(state.bakery.selectedBakedGoods, action.payload)
    },

    toggleBakeryOffering: (state, action) => {
      const offering = action.payload

      if (offering.selected) removeOffering(state.bakery.selectedBakedGoods, offering)
      else addOffering(state.bakery.selectedBakedGoods, offering)
    },

    updateOfferingsSelected: (state, action) => {
      action.payload.forEach((offering) => {
        if (offering.selected) addOffering(state.bakery.selectedBakedGoods, offering)
        else removeOffering(state.bakery.selectedBakedGoods, offering)
      })
    },

    updateOfferingsData: (state, action) => {
      action.payload.forEach((offering) => {
        const idx = state.bakery.selectedBakedGoods.findIndex((o) => o.id === offering.id)
        if (idx > -1)
          state.bakery.selectedBakedGoods[idx] = {
            ...state.bakery.selectedBakedGoods[idx],
            ...offering,
          }
      })
    },

    updateBakerySelectedGoods: (state, action) => {
      const { offering, remove } = action.payload

      const selectedBakedGoods = [...state.bakery.selectedBakedGoods]
      const offIdx = selectedBakedGoods.findIndex((off) => off.id === offering.id)

      if (remove) selectedBakedGoods.splice(offIdx, 1)
      else if (offIdx >= 0) selectedBakedGoods[offIdx] = offering
      else selectedBakedGoods.push(offering)

      state.bakery.selectedBakedGoods = selectedBakedGoods
    },

    addBakeryCategory: (state, action) => {
      state.bakery.categories.push(action.payload)
    },

    removeBakeryCategory: (state, action) => {
      const defaultIndex = state.bakery.categories.findIndex((category) => category.default)
      const index = state.bakery.categories.findIndex((category) => category.id === action.payload)

      const defaultCategory = state.bakery.categories[defaultIndex]
      const category = state.bakery.categories[index]

      defaultCategory.offerings = [...category.offerings, ...defaultCategory.offerings]
      defaultCategory.offeringsCount = defaultCategory.offerings.length
      if (index > -1) state.bakery.categories.splice(index, 1)
    },

    replaceBakeryCategory: (state, action) => {
      const index = state.bakery.categories.findIndex(
        (category) => category.id === action.payload.id,
      )
      if (index > -1) state.bakery.categories[index] = action.payload
    },

    setSubTier: (state, action) => {
      if (!state.user) return
      state.user.subscriptionTier = action.payload
    },

    _setJwt: (state, action) => {
      state.jwt = action.payload
    },

    _setRefreshToken: (state, action) => {
      state.refreshToken = action.payload
    },
  },
})

export const setRefreshToken = (token) => {
  return async (dispatch) => {
    dispatch(_setRefreshToken(token))
    if (token) {
      await PlatformStorage.setItem(StorageKeys.refreshTokenStorageKey, token)
    } else {
      await PlatformStorage.removeItem(StorageKeys.refreshTokenStorageKey)
    }
  }
}

export const setJwt = (token) => {
  return async (dispatch) => {
    dispatch(_setJwt(token))
    // todo: save JWT
    // await PlatformStorage.setItem(StorageKeys.jwtStorageKey, token)
  }
}

export const login = ({ jwt = null, user, refreshToken = null }) => {
  return async (dispatch, getState) => {
    console.log('logging in user', user)
    if (jwt) await dispatch(setJwt(jwt))
    if (refreshToken) await dispatch(setRefreshToken(refreshToken))
    dispatch(setBakery(user.bakery))
    dispatch(setUser(user))
    dispatch(setMessengerUser(user))
    dispatch(refreshOrderRequestCount())
  }
}

export const logout = () => {
  return async (dispatch, getState) => {
    console.log('logging out user')
    dispatch(setRefreshToken(null))
    dispatch({ type: 'RESET' }) // Reset entire store to initial state
  }
}

const { _setJwt, _setRefreshToken } = accountSlice.actions

export const {
  addBakeryOffering,
  addBakeryCategory,
  removeBakeryCategory,
  replaceBakeryCategory,
  setBootstrapped,
  setBakery,
  setSettings,
  setUser,
  toggleBakeryOffering,
  updateOfferingsSelected,
  updateOfferingsData,
  updateBakerySelectedGoods,
  setSubTier,
} = accountSlice.actions

export const accountReducer = accountSlice.reducer

export const selectBakery = (state) => state?.account?.bakery
export const selectSettings = (state) => state?.account?.user?.settings

export const updateUser = (data) => (dispatch, getState) => {
  const userState = getState().account.user

  dispatch(setUser({ ...userState, ...data }))
}
