import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  items: [],
}

const customSectionsSlice = createSlice({
  name: 'customSections',
  initialState,
  reducers: {
    reset: () => {
      return initialState
    },
    addSection: (state, action) => {
      state.items.push(action.payload)
    },
    removeSection: (state, action) => {
      const index = state.items.findIndex((section) => section.id === action.payload.id)
      if (index > -1) state.items.splice(index, 1)
    },
    updateSection: (state, action) => {
      const index = state.items.findIndex((section) => section.id === action.payload.id)
      if (index > -1) state.items[index] = action.payload
    },
    updateSections: (state, action) => {
      state.items = action.payload || []
    },
  },
})

export const { addSection, removeSection, updateSection, updateSections, reset } =
  customSectionsSlice.actions

export const customSectionsReducer = customSectionsSlice.reducer
