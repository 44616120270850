import { format, parseISO } from 'date-fns'

export const formatISODate = (date, { twoDigitYear = false } = {}) => {
  if (!date) return null

  let yearType = 'numeric'
  if (twoDigitYear) yearType = '2-digit'

  const parsedDate = parseISO(date)
  return parsedDate.toLocaleDateString(undefined, {
    month: 'numeric',
    day: 'numeric',
    year: yearType,
  })
}

export const formatDate = (date) => format(parseISO(date), 'MMMM d, yyyy')
