import React, { useState } from 'react'

import { ImagesProvider } from 'shared/contexts/ImagesContext'
import { InspirationPhotos } from './InspirationPhotos'
import { OrderRequestLayout } from 'web/pages/OrderRequest/OrderRequestLayout'
import { useOrderContext } from 'web/contexts/OrderContext'
import { useUpdateOrder } from 'shared/hooks/useUpdateOrder'

const transformImagesForUpload = (images) =>
  images.map((image) => ({
    key: image.key,
    signedId: image.signedId,
  }))

export const Pictures = () => {
  const { order } = useOrderContext()

  const { loading, updateOrder } = useUpdateOrder({ order })
  const [images, setImages] = useState(order.inspirationPhotos)

  const onNext = ({ navigateToNextScreen }) => {
    const inspirationPhotos = transformImagesForUpload(images)

    updateOrder({ input: { inspirationPhotos } }, navigateToNextScreen)
  }

  return (
    <OrderRequestLayout
      nextDisabled={loading || images.some((i) => i.loading)}
      onNext={onNext}
      nextButtonText={images?.length > 0 ? 'Next' : 'Skip'}
    >
      <ImagesProvider initialValues={order.inspirationPhotos}>
        <InspirationPhotos onChange={setImages} />
      </ImagesProvider>
    </OrderRequestLayout>
  )
}
