import Bugsnag from 'shared/services/Bugsnag'
import { gql, useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { useState } from 'react'

import { directUpload, imageInterface } from 'shared/utils/directUpload'
import { updateUser } from 'shared/reducers/account'

export const useImageUpload = () => {
  const [addUserAvatar] = useMutation(ADD_USER_AVATAR)
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const uploadImage = async (image) => {
    setError(false)
    setLoading(true)

    try {
      const fileInterface = await imageInterface(image)
      const directUploadJson = await directUpload(fileInterface)

      const { signedId } = directUploadJson
      const {
        data: {
          addUserAvatar: {
            errors,
            image: { smallUrl },
          },
        },
      } = await addUserAvatar({ variables: { input: { signedId } } })
      if (errors.length) throw errors[0]

      dispatch(updateUser({ avatar: { smallUrl } }))
    } catch (e) {
      console.error('error uploading image', e)
      Bugsnag.notify(e)
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  return { uploadImage, loading, error }
}

export const ADD_USER_AVATAR = gql`
  mutation AddUserAvatar($input: AddUserAvatarInput!) {
    addUserAvatar(input: $input) {
      image {
        smallUrl
      }
      errors
    }
  }
`
