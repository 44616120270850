import { Fragments } from 'shared/constants/Fragments'
import { gql, useMutation } from '@apollo/client'
import { setUser } from 'shared/reducers/account'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

export const useMobileNumberVerification = (onSuccess) => {
  const [code, setCode] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [verifyMobileNumber] = useMutation(VERIFY_MOBILE_NUMBER)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()

  const isDisabled = code.length !== 6 || isLoading

  const onSubmit = useCallback(async () => {
    setIsLoading(true)
    setError(null)

    try {
      const {
        data: {
          verifyMobileNumber: { user, errors },
        },
      } = await verifyMobileNumber({
        variables: { input: { code } },
      })

      if (errors.length) {
        setError(errors[0])
      } else {
        dispatch(setUser(user))
        onSuccess?.()
      }

      setIsLoading(false)
    } catch (e) {
      console.log('error verifying mobile number', error)
      setError('Unknown error verifying mobile number.')
      setIsLoading(false)
    }
  }, [error, code, dispatch, verifyMobileNumber, onSuccess])

  return { code, setCode, isDisabled, isLoading, error, onSubmit }
}

const VERIFY_MOBILE_NUMBER = gql`
  ${Fragments.CurrentUser}
  ${Fragments.CurrentBakery}

  mutation VerifyMobileNumber($input: VerifyMobileNumberInput!) {
    verifyMobileNumber(input: $input) {
      user {
        ...CurrentUserFragment
        bakery {
          ...CurrentBakeryFragment
        }
      }
      errors
    }
  }
`
