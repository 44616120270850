export const toRawMobileNumber = (phone) => (phone ? `${phone.replace(/\D/g, '')}` : null)

export const setCountryPlaceholder = (countryCode) => {
  switch (countryCode) {
    case 'US':
      return '(613) 555-9999'
    case 'NZ':
      return '9 555 0119'
    case 'CA':
      return '(613) 555-0119'
    case 'GB':
      return '7234 567892'
    case 'IE':
      return '99 555 0119'
    case 'AU':
      return '499 990 119'
    default:
      return '(613) 555-9999'
  }
}

export const setCountryMask = (countryCode) => {
  switch (countryCode) {
    case 'US':
      return { mask: '(999) 999-9999' }
    case 'NZ':
      return { mask: '9 999 999999' }
    case 'CA':
      return { mask: '(999) 999-9999' }
    case 'GB':
      return { mask: '9999 999999' }
    case 'IE':
      return { mask: '99 9999 9999' }
    case 'AU':
      return { mask: '999 999 99999' }
    default:
      return { mask: '(999) 999-9999' }
  }
}
