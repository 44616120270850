import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useFragment } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'

import OfferingsList from './OfferingsList'
import { CardBackground } from 'web/styles/shared'
import { CategoryFragment, CurrencyFragment, CustomSectionFragment } from 'shared/graphql/fragments'
import { Colors } from 'shared/styles/Colors'
import { FixedWidthContainer } from 'web/styles/responsive'
import { MainSubtitle } from './Details'
import { PresaleItem } from './PresaleItem'
import {
  showCakeFlavorsScreen,
  showCookieFlavorsScreen,
  showIcingScreen,
} from 'shared/helpers/showBakedGoodsHelpers'
import { useBakeryContext } from 'web/contexts/BakeryContext'
import { useResponsiveLayout } from 'web/hooks/useResponsiveLayout'
import { useThemeContext } from 'web/contexts/ThemeContext'

const CustomSection = ({ sectionId, categorySlug, backgroundColor, primaryFont, primaryColor }) => {
  const { data } = useFragment({
    fragment: CustomSectionFragment,
    fragmentName: 'CustomSection',
    from: {
      __typename: 'CustomSection',
      id: sectionId,
    },
  })

  if (
    (!data.category && data.kind === 'itemized' && categorySlug === 'offerings') ||
    (data.category?.slug === categorySlug && data.kind === 'itemized')
  ) {
    return (
      <OfferingsList
        array={data.choices}
        title={data.title}
        primaryColor={primaryColor}
        backgroundColor={backgroundColor}
        primaryFont={primaryFont}
      />
    )
  } else if (data.category?.slug === categorySlug && data.kind === 'text_box') {
    return (
      <OfferingsList
        text={data.text}
        title={data.title}
        primaryColor={primaryColor}
        backgroundColor={backgroundColor}
        primaryFont={primaryFont}
      />
    )
  }

  return null
}

export const Offerings = () => {
  const { isMdUp } = useResponsiveLayout()
  const { slug, categorySlug } = useParams()
  const { bakery } = useBakeryContext()
  const navigate = useNavigate()
  const { primaryColor, backgroundColor, primaryFont } = useThemeContext()

  const { data: category = {} } = useFragment({
    fragment: CategoryFragment,
    fragmentName: 'Category',
    from: {
      __typename: 'Category',
      slug: categorySlug,
    },
  })

  const { data: currency = {} } = useFragment({
    fragment: CurrencyFragment,
    fragmentName: 'Currency',
    from: {
      __typename: 'Currencies',
      id: bakery.currency?.id,
    },
  })

  const { bakedGoods, icings, cakeFlavors, cookieFlavors } = bakery

  const navigateItemDetails = useCallback(
    (productSlug) => {
      navigate(`/b/${slug}/products/${productSlug}`)
    },
    [navigate, slug],
  )

  if (!category) return null

  return (
    <Container>
      <MainColumn isMdUp={isMdUp}>
        <MainSubtitle $style={{ marginBottom: 20 }} font={primaryFont}>
          {category.name}
        </MainSubtitle>
        <Card>
          {category.default && (
            <>
              <OfferingsList
                array={bakedGoods}
                title="Offerings"
                primaryColor={primaryColor}
                backgroundColor={backgroundColor}
                primaryFont={primaryFont}
                defaultExpanded
              />

              {showIcingScreen(bakery) && (
                <OfferingsList
                  array={icings}
                  title="Icings"
                  primaryColor={primaryColor}
                  backgroundColor={backgroundColor}
                  primaryFont={primaryFont}
                />
              )}

              {showCakeFlavorsScreen(bakery) && (
                <OfferingsList
                  array={cakeFlavors}
                  title="Cake Flavors"
                  primaryColor={primaryColor}
                  backgroundColor={backgroundColor}
                  primaryFont={primaryFont}
                />
              )}

              {showCookieFlavorsScreen(bakery) && (
                <OfferingsList
                  array={cookieFlavors}
                  title="Cookie Flavors"
                  primaryColor={primaryColor}
                  backgroundColor={backgroundColor}
                  primaryFont={primaryFont}
                />
              )}
            </>
          )}

          <PresaleItemsContainer isMdUp={isMdUp}>
            {category.offerings.map((presaleItem) => (
              <PresaleItem
                item={presaleItem}
                key={presaleItem.id}
                onClick={() => navigateItemDetails(presaleItem?.slug)}
                currency={currency}
              />
            ))}
          </PresaleItemsContainer>

          {bakery.customSections.map((section) => (
            <CustomSection
              backgroundColor={backgroundColor}
              categorySlug={categorySlug}
              key={section.id}
              primaryColor={primaryColor}
              primaryFont={primaryFont}
              sectionId={section.id}
            />
          ))}
        </Card>
      </MainColumn>
    </Container>
  )
}
const Container = styled(FixedWidthContainer)`
  margin-bottom: 40px;
  padding-top: 5px;
  border-radius: 4px;
  background-color: ${Colors.white};
`

const MainColumn = styled.div`
  margin-top: 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
`

const PresaleItemsContainer = styled.div(
  ({ isMdUp }) => `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${isMdUp ? 'normal' : 'space-between'};
`,
)

const Section = styled.div`
  @media (max-width: 768px) {
    padding: 20px;
  }
  @media (min-width: 768px) {
    padding: 20px 70px 20px 55px;
  }
`

const Card = styled(Section)({
  ...CardBackground,
  boxShadow: 'none',
  marginBottom: 20,
})
