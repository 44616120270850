const brand = '#29b9ff'

export const Colors = {
  brand,
  brandFaded: 'rgba(88, 182, 249, 0.3)',

  defaultPrimary: brand,
  defaultBackground: '#F7F8F9',

  navy: '#001f3f',
  blue: '#29b9ff',
  darkerBlue: 'rgb(35,103,162)',
  darkBlue: 'rgb(69, 157, 212)',
  aqua: '#7fdbff',
  teal: '#39cccc',
  olive: '#3d9970',
  green: '#2ecc40',
  lime: '#01ff70',
  yellow: '#ffdc00',
  darkerYellow: '#f1d000',
  goldenYellow: '#fec100',
  orange: '#ff851b',
  red: '#ff4136',
  maroon: '#85144b',
  fuchsia: '#f012be',
  purple: '#6345af',
  black: '#111111',
  gray: '#aaaaaa',
  silver: '#dddddd',
  white: '#FFFFFF',
  pink: '#ff7dc0',

  grey100: '#333',
  grey80: '#5b6771',
  grey75: '#63707B',
  grey50: '#969FA7',
  grey37: '#b7bbbf',
  grey25: '#CBCFD3',
  grey15: '#E9E9EB',
  grey10: '#EAEBED',
  grey3: '#F8F9FB',

  formFieldBackground: '#f2f2f2',
  offeringLabelBackground: '#e2ecfa',
  offeringSelectedBgColor: 'rgba(69, 157, 212, 0.2)',
  offeringSelectedTextColor: 'rgb(42, 82, 162)',

  visibilitySelectActiveColor: 'rgb(28, 94, 33)',
  visibilitySelectHiddenColor: 'rgb(180, 1, 7)',

  infoAlertBgColor: '#b8daff',
  infoAlertTextColor: '#004085',
  infoAlternativeBgColor: '#ffddb9',

  featureColor: '#f8d185',
  featureSeconaryColor: '#2a2a2a',

  transparent: 'rgba(0,0,0,0)',

  avatarOutline: '#bbc6ce',
}

export const stockTextColor = (stock) => {
  switch (true) {
    case stock <= 5:
      return Colors.red
    case stock <= 9:
      return Colors.orange
    default:
      return Colors.green
  }
}
