import React from 'react'
import { faPlus, faStar as faStarOutline } from '@fortawesome/pro-light-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons'

import * as Cross from 'shared/components/Cross'
import { Colors } from 'shared/styles/Colors'
import { Configuration } from 'shared/services/Configuration'
import { Icon } from 'shared/components/Icon/Icon'
import { RemoveBox } from 'shared/components/RemoveBox'
import { Spinner } from 'shared/components/Spinner'
import { styler } from 'shared/utils/styler'

export const GalleryItem = React.memo(
  ({ item, index, galleryWidth, pickImage, removeImage, toggleFeatured, hasMaxFeatured }) => {
    const imageWidth = (galleryWidth - 20) / 3
    const marginRight = (index + 1) % 3 === 0 ? 0 : 10
    const size = { width: imageWidth, height: imageWidth }

    if (item.id === 'add') {
      return (
        <Cross.LinkedView onClick={pickImage}>
          <GalleryPlaceholder
            style={{ ...size, marginRight, backgroundColor: Colors.white, display: 'flex' }}
          >
            <GalleryAddImageContainer>
              <GalleryAddImageCircle>
                <Icon icon={faPlus} color={Colors.blue} />
              </GalleryAddImageCircle>
            </GalleryAddImageContainer>
          </GalleryPlaceholder>
        </Cross.LinkedView>
      )
    } else if (item.loading && item.url) {
      return (
        <GalleryImageContainer style={{ ...size, marginRight }}>
          <GalleryImage source={{ uri: item.url }} style={size} fadeDuration={0} />
          <Spinner overlay />
        </GalleryImageContainer>
      )
    } else if (item.url) {
      return (
        <GalleryImageContainer style={{ ...size, marginRight }}>
          <GalleryImage source={{ uri: item.url }} style={size} fadeDuration={0} />
          <RemoveBox
            onClick={() => removeImage(item.id)}
            containerStyles={{ borderTopRightRadius: 4 }}
          />
          {toggleFeatured && (item.featured || !hasMaxFeatured) && (
            <FeaturedContainer featured={item.featured} onClick={() => toggleFeatured(item)}>
              <Icon icon={item.featured ? faStar : faStarOutline} color={Colors.blue} size={20} />
            </FeaturedContainer>
          )}
        </GalleryImageContainer>
      )
    } else if (item.loading) {
      return (
        <GalleryPlaceholder
          style={{ ...size, marginRight, backgroundColor: Colors.white, display: 'flex' }}
        >
          <GalleryAddImageContainer>
            <Spinner />
          </GalleryAddImageContainer>
        </GalleryPlaceholder>
      )
    } else {
      return <GalleryPlaceholder style={{ ...size, marginRight }} />
    }
  },
)

const GalleryAddImageCircle = styler(Cross.View)({
  borderColor: Colors.blue,
  borderWidth: 2,
  borderRadius: 100,
  borderStyle: 'solid',
  marginTop: 5,
  marginBottom: 5,
  width: 30,
  height: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const GalleryAddImageContainer = styler(Cross.View)({
  borderColor: Colors.grey25,
  borderWidth: 1,
  borderRadius: 4,
  borderStyle: 'solid',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  flex: 1,
})

const GalleryImageContainer = styler(Cross.View)({
  position: 'relative',
  marginTop: 5,
  marginBottom: 5,
})

const GalleryPlaceholder = styler(Cross.View)({
  backgroundColor: Colors.grey25,
  borderRadius: 4,
  marginTop: 5,
  marginBottom: 5,
})

const GalleryImage = styler(Cross.Image)({
  backgroundColor: Colors.grey25,
  borderRadius: 4,
  objectFit: Configuration.isWeb ? 'cover' : undefined,
})

const FeaturedContainer = styler(Cross.LinkedView)({
  position: 'absolute',
  bottom: 5,
  right: 5,
  backgroundColor: Colors.white,
  borderRadius: 100,
  height: 30,
  width: 30,
  alignItems: 'center',
  justifyContent: 'center',
})
