import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
// import { Colors } from 'shared/styles/Colors'
// import { faTimes } from '@fortawesome/pro-light-svg-icons'
// import styled from 'styled-components'
import Lightbox from 'react-image-lightbox'
import { setLightboxImage } from 'shared/reducers/ui'

export const ImageLightbox = React.memo(() => {
  const imageUrl = useSelector((state) => state.ui.lightboxImageUrl)
  const dispatch = useDispatch()

  if (!imageUrl) return null

  return (
    <Lightbox
      mainSrc={imageUrl}
      // nextSrc={images[(photoIndex + 1) % images?.length]}
      // prevSrc={images[(photoIndex + images?.length - 1) % images?.length]}
      onCloseRequest={() => dispatch(setLightboxImage(null))}
    />
  )
})
