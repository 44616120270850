import { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { useQuestionCounter } from 'web/hooks/useQuestionCounter'

export const useOrderBuild = ({ order }) => {
  const location = useLocation()
  const currentPage = location.pathname.split('/').pop()

  const { enableOrderSpecifications } = order

  const skipDueDate = useMemo(
    () => order?.orderItems.every((oi) => oi.offering.dueDateDisabled),
    [order?.orderItems],
  )

  const orderOfferingIds = useMemo(
    () => new Set(order.orderItems.map((oi) => oi.offeringId)),
    [order],
  )

  const orderCategoryIds = useMemo(
    () => new Set(order.orderItems.map((oi) => oi.categoryId)),
    [order],
  )

  const forOfferings = useCallback(
    (offerings) => {
      if (!offerings.length) return

      const offeringIds = offerings.map((o) => o.id)
      return offeringIds.some((id) => orderOfferingIds.has(id))
    },
    [orderOfferingIds],
  )

  const forCategories = useCallback(
    (categories) => {
      if (!categories.length) return

      const categoryIds = categories.map((c) => c.id)
      return categoryIds.some((id) => orderCategoryIds.has(id))
    },
    [orderCategoryIds],
  )

  const customQuestions = useMemo(() => {
    const items = order.bakery?.customQuestions

    if (!items?.length || currentPage === 'details') return []

    return items.filter(
      (cq) =>
        (cq.offerings.length === 0 && cq.categories.length === 0) ||
        forOfferings(cq.offerings) ||
        forCategories(cq.categories),
    )
  }, [order, forOfferings, forCategories, currentPage])

  const customQuestionsLength = customQuestions?.length || 0
  const dueDateOffset = skipDueDate ? 0 : 1
  const specificationsOffset = enableOrderSpecifications ? 0 : 1

  const { questionCounter, setQuestionCounter } = useQuestionCounter({
    customQuestionsLength,
    dueDateOffset,
    specificationsOffset,
    currentPage,
  })

  return {
    currentPage,
    customQuestions,
    loading: order?.bakery?.status === 'expired',
    order,
    questionCounter,
    setQuestionCounter,
    skipDueDate,
  }
}
