import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  order: {
    orderItems: [],
    otherDetails: '',
    showStockNotice: false,
    tmpQuantity: {},
  },
}

const bakerOrderCreationSlice = createSlice({
  name: 'bakerOrderCreation',
  initialState,
  reducers: {
    reset: (state, action) => {
      return initialState
    },
    resetOrderItems: (state, action) => {
      state.order.orderItems = []
    },
    updateBakerOrderDetails: (state, action) => {
      state.order.bakerOrderDetails = action.payload
    },
    updateCompletionDate: (state, action) => {
      state.order.completionDate = action.payload
    },
    updateDepositDate: (state, action) => {
      state.order.depositDue = action.payload
    },
    updateBalanceDate: (state, action) => {
      state.order.balanceDue = action.payload
    },
    selectCustomer: (state, action) => {
      const customer = action.payload

      state.order.customerName = customer.name
      if (customer.contactOnly) {
        state.order.customerId = null
        state.order.customerContactId = customer.id
      } else {
        state.order.customerId = customer.id
        state.order.customerContactId = null
      }
    },
    selectCustomerContact: (state, action) => {
      const customer = action.payload

      state.order.customerName = customer.name
      state.order.customerId = null
      state.order.customerContactId = customer.id
    },
    toggleOrderItem: (state, action) => {
      const offering = action.payload
      const offeringId = offering.offeringId || offering.id

      if (state.order.orderItems.findIndex((oi) => oi.offeringId === offeringId) > -1) {
        state.order.orderItems = state.order.orderItems.filter((oi) => oi.offeringId !== offeringId)
      } else {
        state.order.orderItems.push({
          name: offering.name,
          offeringId,
          quantity: +offering.minQuantity || 0,
          unit: offering.dozenOnly ? 'dozen' : 'individual',
          spec: '',
          stock: offering.stock,
        })
      }
    },
    setOrderItems: (state, action) => {
      const offerings = action.payload
      offerings.forEach((offering) => {
        state.order.orderItems.push({
          name: offering?.offering?.name,
          offeringId: offering?.offering?.id ? offering.offering.id : offering.offeringId,
          quantity: offering.quantity,
          unit: offering.unit,
          spec: offering.spec,
          stock: offering.offering?.stock,
        })
      })
    },
    updateQuantity: (state, { payload: { offeringId, quantity } }) => {
      state.order.orderItems.forEach((oi) => {
        if (oi.offeringId !== offeringId) return
        if (!state.order.tmpQuantity[offeringId]) state.order.tmpQuantity[offeringId] = oi.quantity
        oi.quantity = quantity

        if (
          oi.stock !== null &&
          oi.stock !== undefined &&
          quantity > oi.stock &&
          quantity > state.order.tmpQuantity[offeringId]
        ) {
          state.order.showStockNotice = { name: oi.name, stock: oi.stock }
        }
      })
    },
    updateItemSpec: (state, action) => {
      state.order.orderItems.forEach((oi) => {
        if (oi.offeringId !== action.payload.offeringId) return
        oi.spec = action.payload.text
      })
    },
    disableShowStockNotice: (state) => {
      state.order.showStockNotice = false
    },
  },
})

export const {
  selectCustomer,
  selectCustomerContact,
  setOrderItems,
  toggleOrderItem,
  updateQuantity,
  updateItemSpec,
  updateBakerOrderDetails,
  updateCompletionDate,
  updateDepositDate,
  updateBalanceDate,
  reset,
  resetOrderItems,
  disableShowStockNotice,
} = bakerOrderCreationSlice.actions

export const bakerOrderCreationReducer = bakerOrderCreationSlice.reducer
