import React from 'react'
import styled from 'styled-components'
import { FixedWidthContainer } from 'web/styles/responsive'
import { OrderList } from './OrderList'
import { useCurrentUser } from 'shared/hooks/useCurrentUser'
import { UserAvatar } from 'shared/components/UserAvatar/UserAvatar'

export const AccountPage = () => {
  const currentUser = useCurrentUser()

  return (
    <Container>
      <Header>
        <UserAvatar user={currentUser} editable />
        <UserName>{currentUser.name}</UserName>
      </Header>
      <OrderList />
    </Container>
  )
}

const Header = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 50,
})

const Container = styled(FixedWidthContainer)`
  padding: 40px !important;
`

const UserName = styled.div({
  marginLeft: 20,
  fontSize: 24,
  fontWeight: 'semi-bold',
})
