import React, { memo } from 'react'
import styled from 'styled-components'
import { useFragment } from '@apollo/client'

import { OfferingDetailsFragment } from 'shared/graphql/fragments'
import { SpecHints } from 'shared/utils/SpecHints'
import { StyledTextArea } from 'web/components/form/TextAreaField'

export const Spec = memo(({ offeringId, onChange, value }) => {
  const { data: offering } = useFragment({
    fragment: OfferingDetailsFragment,
    fragmentName: 'OfferingDetails',
    from: {
      __typename: 'Offering',
      id: offeringId,
    },
  })

  return (
    <Container>
      <Title>{offering.name}</Title>
      <StyledInput
        onChange={onChange(offering.id)}
        placeholder={SpecHints[offering.name] || SpecHints.Default}
        value={value}
      />
    </Container>
  )
})

const Container = styled.div({
  marginBottom: 20,
})

const Title = styled.div({
  fontWeight: 'bold',
})

const StyledInput = styled(StyledTextArea)({
  flex: 1,
  minHeight: 100,
  maxHeight: 150,
  marginTop: 5,
})
