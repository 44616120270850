import React from 'react'
import styled from 'styled-components'
import { faUser } from '@fortawesome/pro-light-svg-icons'

import * as Cross from 'shared/components/Cross'
import { Colors } from 'shared/styles/Colors'
import { Divider, Row, Strong } from 'shared/components/Invoice/styles'
import { Icon } from 'shared/components/Icon/Icon'
import { useInvoiceContext } from 'shared/contexts/InvoiceContext'

export const CommentSection = ({ show, Container = React.Fragment }) => {
  const { order } = useInvoiceContext()

  if (!show) return null
  if (!order.otherDetails) return null

  return (
    <Container>
      <Row $marginBottom={10}>
        <UserIcon icon={faUser} size={20} color={Colors.blue} />

        <Strong $marginLeft={10}>Customer Comments:</Strong>
      </Row>

      <Row>
        <Cross.Text>{order.otherDetails}</Cross.Text>
      </Row>

      <Divider />
    </Container>
  )
}

const UserIcon = styled(Icon)({
  marginLeft: -2,
})
