import React from 'react'

import * as Cross from 'shared/components/Cross'
import { Colors } from 'shared/styles/Colors'

export const OpenMapButton = ({ pickupAddress }) => {
  const href = `https://maps.apple.com/?q=${pickupAddress}`

  return (
    <a href={href} target="_blank" rel="noreferrer">
      <Cross.Text style={{ color: Colors.brand }}>{pickupAddress}</Cross.Text>
    </a>
  )
}
