/* globals AppleID */

import qs from 'qs'
import { Configuration } from 'shared/services/Configuration'

let windowObjectReference = null
let previousUrl = null

export const initiateOAuth = ({ provider }) => {
  let url

  if (provider === 'google') {
    const query = qs.stringify({
      response_type: 'token',
      client_id: Configuration.googleOAuthClientId,
      redirect_uri: Configuration.googleOAuthRedirectUri,
      scope: 'email profile openid',
      state: 'google',
    })
    url = `${Configuration.googleOAuthEndpoint}?${query}`
  } else if (provider === 'facebook') {
    const query = qs.stringify({
      response_type: 'token',
      client_id: Configuration.facebookOAuthClientId,
      redirect_uri: Configuration.facebookOAuthRedirectUri,
      state: 'facebook',
      scope: 'email',
    })
    url = `${Configuration.facebookOAuthEndpoint}?${query}`
  } else if (provider === 'apple') {
    AppleID.auth.init({
      clientId: Configuration.appleOAuthClientId,
      scope: 'name email',
      redirectURI: Configuration.appleOAuthRedirectUri,
      state: 'apple',
      usePopup: true,
    })
    AppleID.auth.signIn()
  } else {
    throw new Error(`unknown provider ${provider}`)
  }

  const name = 'Authorize'

  if (provider !== 'apple') {
    // window features
    const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100'

    if (windowObjectReference === null || windowObjectReference.closed) {
      /* if the pointer to the window object in memory does not exist
      or if such pointer exists but the window was closed */
      windowObjectReference = window.open(url, name, strWindowFeatures)
    } else if (previousUrl !== url) {
      /* if the resource to load is different,
      then we load it in the already opened secondary window and then
      we bring such window back on top/in front of its parent window. */
      windowObjectReference = window.open(url, name, strWindowFeatures)
      windowObjectReference.focus()
    } else {
      /* else the window reference must exist and the window
      is not closed; therefore, we can bring it back on top of any other
      window with the focus() method. There would be no need to re-create
      the window or to reload the referenced resource. */
      windowObjectReference.focus()
    }
  }

  // assign the previous URL
  previousUrl = url
}
