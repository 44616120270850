import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  questions: [],
}

const customQuestionsSlice = createSlice({
  name: 'customQuestions',
  initialState,
  reducers: {
    reset: (state, action) => {
      return initialState
    },
    addQuestion: (state, action) => {
      state.questions.push(action.payload)
    },
    removeQuestion: (state, action) => {
      const index = state.questions.findIndex((question) => question.id === action.payload)
      if (index > -1) state.questions.splice(index, 1)
    },
    updateQuestion: (state, action) => {
      const index = state.questions.findIndex((question) => question.id === action.payload.id)
      if (index > -1) state.questions[index] = action.payload
    },
    updateQuestions: (state, action) => {
      state.questions = action.payload
    },
  },
})

export const { addQuestion, removeQuestion, updateQuestion, updateQuestions, reset } =
  customQuestionsSlice.actions

export const customQuestionsReducer = customQuestionsSlice.reducer
