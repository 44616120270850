import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { faCopy } from '@fortawesome/pro-regular-svg-icons'

import { addColon } from 'shared/utils/textTransforms'
import { Button } from 'web/components/Button'
import { Colors } from 'shared/styles/Colors'
import { fontPositionAdjustments } from 'shared/styles/PremiumThemes'
import { Icon } from 'shared/components/Icon/Icon'
import { Text } from 'shared/components/Cross'
import { useThemeContext } from 'web/contexts/ThemeContext'

export const BankTransfer = ({ paymentMethod }) => {
  const { secondaryFontRaw } = useThemeContext()

  const [copied, setCopied] = useState(false)
  const username = paymentMethod.username

  useEffect(() => {
    if (copied) setTimeout(() => setCopied(false), 3000)
  }, [copied])

  const handleCopy = () => {
    setCopied(true)
    navigator.clipboard.writeText(username)
  }

  return (
    <Container>
      <NameText font={secondaryFontRaw}>Bank Transfer{username && addColon(username)}</NameText>
      {username && (
        <CopyButton type="button" onClick={handleCopy}>
          <Icon icon={copied ? faCheck : faCopy} size={25} />
        </CopyButton>
      )}
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  gap: 5,
  alignItems: 'center',
})

const CopyButton = styled(Button)({
  width: 40,
  height: 40,
  backgroundColor: 'transparent',
  color: Colors.brand,
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const NameText = styled(Text)(({ font }) => fontPositionAdjustments[font])
