import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from 'web/components/Button'
import { Colors } from 'shared/styles/Colors'
import { FixedWidthContainer } from 'web/styles/responsive'
import { fontPositionAdjustments } from 'shared/styles/PremiumThemes'
import { Icon } from 'shared/components/Icon/Icon'
import { PAGES } from './constants'
import { useOrderBuild } from 'web/hooks/useOrderBuild'
import { useOrderContext } from 'web/contexts/OrderContext'
import { useThemeContext } from 'web/contexts/ThemeContext'
import { useWindowDimensions } from 'web/components/WindowDimensionsProvider'

export const OrderRequestLayout = ({
  children,
  nextDisabled,
  onBack,
  onNext,
  bakerySlug,
  nextButtonText = 'Next',
}) => {
  const { layoutHeight } = useWindowDimensions()
  const navigate = useNavigate()
  const { orderId } = useParams()
  const { primaryColor, secondaryFontRaw } = useThemeContext()

  const { order } = useOrderContext()
  const { currentPage, customQuestions, questionCounter, setQuestionCounter, skipDueDate } =
    useOrderBuild({ order })

  const listOuterRef = useRef()
  const listInnerRef = useRef()

  const [showScrollDownIndicator, setShowScrollDownIndicator] = useState()

  const { enableOrderSpecifications } = order.bakery
  const showCounter = ['theme', 'questionnaire', 'pictures', 'terms'].includes(currentPage)

  const skipInspirationPhotos = useMemo(
    () => order.orderItems.every((oi) => !oi.offering.enableInspirationPhotos),
    [order],
  )

  const skipQuestionnaire = useMemo(
    () => order.orderItems?.length && !customQuestions?.length,
    [order, customQuestions],
  )

  const pageNames = useMemo(() => {
    let pages = JSON.parse(JSON.stringify(PAGES))

    if (!enableOrderSpecifications) pages = pages.filter((page) => page.name !== 'theme')
    if (skipDueDate) pages = pages.filter((page) => page.name !== 'pick-up-date')
    if (skipQuestionnaire) pages = pages.filter((page) => page.name !== 'questionnaire')
    if (skipInspirationPhotos) pages = pages.filter((page) => page.name !== 'pictures')
    if (!order.bakery.termsAndConditions) pages = pages.filter((page) => page.name !== 'terms')

    return pages.map(({ name }) => name)
  }, [
    enableOrderSpecifications,
    skipDueDate,
    skipQuestionnaire,
    skipInspirationPhotos,
    order.bakery.termsAndConditions,
  ])

  const maxCounter = pageNames.length + customQuestions.length - (skipQuestionnaire ? 1 : 2)
  const currentPageIndex = pageNames.findIndex((n) => n === currentPage)
  const nextRoute = `/order-request/${orderId}/${pageNames[currentPageIndex + 1]}`
  const backRoute =
    currentPageIndex === 0
      ? `/b/${bakerySlug}`
      : `/order-request/${orderId}/${pageNames[currentPageIndex - 1]}`

  const backButtonOnClick = (e) => {
    if (showCounter && (skipDueDate ? questionCounter > 3 : questionCounter > 4))
      setQuestionCounter((ps) => {
        if (currentPage === 'pictures' && customQuestions.length) return ps - customQuestions.length

        return ps - 1
      })

    if (onBack) {
      e.preventDefault()
      onBack()
    }
  }

  const nextButtonOnClick = (e) => {
    if (showCounter && questionCounter < maxCounter) setQuestionCounter((ps) => ps + 1)

    if (onNext) {
      e.preventDefault()
      const navigateToNextScreen = () => navigate(nextRoute)
      onNext({ navigateToNextScreen })
    }
  }

  const onScroll = () => {
    if (listOuterRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listOuterRef.current
      if (scrollTop + clientHeight >= 0.95 * scrollHeight) {
        setShowScrollDownIndicator(false)
      } else {
        setShowScrollDownIndicator(true)
      }
    }
  }

  useEffect(() => {
    // Hack around Intstagram browser dimensions bug by forcing a fake resize event
    if (currentPage === 'baked-goods' && navigator.userAgent.match(/instagram/i)) {
      window.dispatchEvent(new CustomEvent('resize'))
    }

    setShowScrollDownIndicator(!allElementsInViewport(listInnerRef.current))
  }, [currentPage])

  const allElementsInViewport = (el) => {
    let offsetTop = el.offsetTop
    let offsetLeft = el.offsetLeft
    let offsetWidth = el.offsetWidth
    let offsetHeight = el.offsetHeight

    while (el.offsetParent) {
      el = el.offsetParent
      offsetTop += el.offsetTop
      offsetLeft += el.offsetLeft
    }

    return (
      offsetTop >= window.pageYOffset &&
      offsetLeft >= window.pageXOffset &&
      offsetTop + offsetHeight <= window.pageYOffset + window.innerHeight &&
      offsetLeft + offsetWidth <= window.pageXOffset + window.innerWidth
    )
  }
  return (
    <Container $height={layoutHeight}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Main onScroll={() => onScroll()} ref={listOuterRef}>
        {showScrollDownIndicator && (
          <ScrollIndicator>
            <IconContainer>
              <Icon icon={faChevronDown} color={Colors.grey75} />
            </IconContainer>
          </ScrollIndicator>
        )}
        <MainInner ref={listInnerRef}>
          {children}
          {showCounter && (
            <CounterText>
              (Question {questionCounter} of {maxCounter})
            </CounterText>
          )}
        </MainInner>
      </Main>

      <BottomBar>
        <BottomBarInner>
          <Button to={backRoute} type="alt" onClick={backButtonOnClick} color={primaryColor}>
            <ButtonTextContainer font={secondaryFontRaw}>Back</ButtonTextContainer>
          </Button>
          <Button
            to={nextRoute}
            disabled={nextDisabled}
            onClick={nextButtonOnClick}
            color={primaryColor}
          >
            <ButtonTextContainer font={secondaryFontRaw}>
              {currentPageIndex === pageNames.length - 1 ? 'Finish' : nextButtonText}
            </ButtonTextContainer>
          </Button>
        </BottomBarInner>
      </BottomBar>
    </Container>
  )
}

const Container = styled.div(({ $height }) => ({
  height: $height,
  display: 'flex',
  flexDirection: 'column',
}))

const Main = styled.div({
  flex: 1,
  paddingTop: 20,
  paddingBottom: 20,
  overflowY: 'auto',
  display: 'flex',
})

const MainInner = styled(FixedWidthContainer)({
  maxWidth: 700,
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  paddingBottom: 60,
})

const BottomBarInner = styled(FixedWidthContainer)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 75,
  padding: '0 30px',
  maxWidth: 600,
})

const BottomBar = styled.div({
  borderTop: `2px solid ${Colors.grey10}`,
  backgroundColor: Colors.white,
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
})

const ScrollIndicator = styled.div({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  width: 30,
  bottom: 90,
  display: 'flex',
  justifyContent: 'center',
})

const IconContainer = styled.div`
  background-color: ${Colors.white};
  height: 30px;
  width: 30px;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.5);
`
const CounterText = styled.p({
  fontSize: 18,
  textAlign: 'right',
})

const ButtonTextContainer = styled.span(({ font }) => fontPositionAdjustments[font] || {})
