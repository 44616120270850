import { useCallback, useMemo } from 'react'

import { useOrderContext } from 'web/contexts/OrderContext'

export const useValidation = () => {
  const { bakery } = useOrderContext()

  const validateQuantity = useCallback((orderItem, offering) => {
    const { name, minQuantity, maxQuantity, dozenOnly } = offering

    if (offering.stock !== null && offering.stock !== undefined) {
      const stock = dozenOnly && offering.stock ? offering.stock / 12 : offering.stock

      if (orderItem.quantity > stock) {
        alert(`${name}: Only ${stock}${dozenOnly ? ' dozen' : ''} left in stock`)
        return false
      }
    }

    const validateMinMax = () => {
      if (orderItem.quantity > maxQuantity || orderItem.quantity < minQuantity) {
        alert(`${name}: Please enter a quantity between ${minQuantity} and ${maxQuantity}`)
        return false
      }
      return true
    }

    const validateMin = () => {
      if (orderItem.quantity < minQuantity) {
        alert(`${name}: Please enter a quantity of at least ${minQuantity}`)
        return false
      }
      return true
    }

    switch (true) {
      case offering.allowMaxQuantity:
        return validateMinMax()

      default:
        return validateMin()
    }
  }, [])

  const validateUnit = useCallback((orderItem, offering) => {
    switch (true) {
      case offering.dozenOnly && offering.allowHalfDozen:
        if (orderItem.quantity % 0.5 === 0) return true

        alert(`${offering.name}: Please enter a quantity in increments of 0.5 (1/2 dozen)`)
        return false

      default:
        if (orderItem.quantity % 1 === 0) return true

        alert(`${offering.name}: Please enter a quantity in increments of 1`)
    }
  }, [])

  const itemsById = useMemo(() => {
    const allItems = [
      ...bakery.selectedBakedGoods,
      ...bakery.selectedPresaleItems,
      ...bakery.selectedMenuItems,
    ]

    const hash = {}
    allItems.forEach((item) => {
      hash[item.id] = item
    })

    return hash
  }, [bakery.selectedBakedGoods, bakery.selectedPresaleItems, bakery.selectedMenuItems])

  return useCallback(
    (orderItems) => {
      return orderItems.every((orderItem) => {
        const offering = itemsById[orderItem.offeringId]

        return validateQuantity(orderItem, offering) && validateUnit(orderItem, offering)
      })
    },
    [itemsById, validateQuantity, validateUnit],
  )
}
