import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  offerings: [],
}

const offeringsSlice = createSlice({
  name: 'offerings',
  initialState,
  reducers: {
    setOfferings: (state, action) => {
      state.offerings = action.payload
    },
  },
})

export const { setOfferings } = offeringsSlice.actions

export const offeringsReducer = offeringsSlice.reducer
