import React, { useMemo } from 'react'
import { faPencil } from '@fortawesome/pro-solid-svg-icons'

import * as Cross from 'shared/components/Cross'
import { Colors } from 'shared/styles/Colors'
import { Configuration } from 'shared/services/Configuration'
import { Fonts } from 'shared/styles/Typography'
import { Icon } from 'shared/components/Icon/Icon'
import { Spinner } from '../Spinner'
import { styler } from 'shared/utils/styler'
import { useAvatarUpload } from './useAvatarUpload'

export const UserAvatar = ({
  user = null,
  bakery = null,
  name = null,
  size = 50,
  editable = false,
  ...props
}) => {
  const { onUpload, renderInput, loading, tmpImage } = useAvatarUpload()
  const canEdit = editable && !loading

  const inner = useMemo(() => {
    let result

    if (user?.avatar || tmpImage) {
      result = (
        <>
          <AvatarImage
            source={{ uri: tmpImage || user.avatar.largeUrl || user.avatar.smallUrl }}
            size={size}
            draggable={false}
          />
          {loading && <Spinner overlay />}
        </>
      )
    } else {
      let nameString = ''

      if (name) {
        nameString = name
      } else if (bakery?.name) {
        nameString = bakery.name
      } else if (user) {
        nameString = user.name
      }

      result = (
        <InitialsContainer color={stringToColor(nameString)} size={size}>
          <Initials size={size}>{letters(nameString)}</Initials>
        </InitialsContainer>
      )
    }

    return result
  }, [bakery, name, size, user, loading, tmpImage])

  const clickProps = {}
  if (canEdit) clickProps.onClick = onUpload

  return (
    <Container
      size={size}
      {...props}
      {...clickProps}
      $editable={editable}
      as={editable ? Cross.LinkedView : Cross.View}
    >
      {canEdit && renderInput?.()}
      {inner}
      {canEdit && (
        <ActionContainer>
          <Icon icon={faPencil} size={13} color={Colors.white} />
        </ActionContainer>
      )}
    </Container>
  )
}

const letters = (name) => {
  return name
    .split(' ')
    .slice(0, 2)
    .map((w) => w[0])
    .join('')
}

// https://medium.com/@pppped/compute-an-arbitrary-color-for-user-avatar-starting-from-his-username-with-javascript-cd0675943b66
const stringToColor = (str, { s = 60, l = 70 } = {}) => {
  var hash = 0
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  var h = hash % 360
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)'
}

const avatarStyles = Configuration.isNative ? {} : { borderStyle: 'solid', userSelect: 'none' }

const Container = styler(Cross.View)(({ size, $editable }) => ({
  position: 'relative',
  width: size,
  height: size,
  cursor: Configuration.isWeb && $editable ? 'pointer' : undefined,
}))

const InitialsContainer = styler(Cross.View)((props) => ({
  backgroundColor: props.color,
  width: props.size,
  height: props.size,
  borderRadius: props.size / 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
}))

const Initials = styler(Cross.Text)(({ size }) => ({
  fontFamily: Configuration.isNative ? Fonts.SourceSansPro.Bold : undefined,
  textTransform: 'uppercase',
  color: Colors.white,
  fontSize: size / 3,
  fontWeight: 'bold',
}))

const AvatarImage = styler(Cross.Image)((props) => ({
  backgroundColor: Colors.grey10,
  width: props.size,
  height: props.size,
  borderRadius: props.size / 2,
  flexShrink: 0,
  borderWidth: 0.5,
  borderColor: Colors.avatarOutline,
  ...avatarStyles,
}))

const ActionContainer = styler(Cross.View)((props) => ({
  position: 'absolute',
  right: -5,
  bottom: -5,
  backgroundColor: Colors.grey100,
  width: 28,
  height: 28,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 100,
}))
