const goods = {
  icings: ['Cakes', 'Cupcakes', 'Cookies'],
  cakes: ['Cakes', 'Cupcakes'],
  cookies: ['Cookies'],
}

const process = (bakery, type) => {
  const bakedGoods = bakery?.selectedBakedGoods || bakery?.bakedGoods

  if (!bakedGoods) return true

  return goods[type].some((g) => bakedGoods.map((bg) => bg.name).includes(g))
}

export const showIcingScreen = (bakery) => process(bakery, 'icings')
export const showCakeFlavorsScreen = (bakery) => process(bakery, 'cakes')
export const showCookieFlavorsScreen = (bakery) => process(bakery, 'cookies')
