/* @returns {Object} - An object of errors that can be used by react-hook-form's setError function
 * { user: [["email", "has already been taken"]], form: [["", "Unknown error updating user information."]] } */

export const mapGraphqlErrors = (errors, { remap = {} } = {}) => {
  const mapping = {}

  errors.forEach((e) => {
    const originalPath = e.path
    const errorPath = remap[originalPath] || originalPath

    const segments = errorPath.split('.')
    const message = e.messages.join(', ')

    if (segments.length === 1) {
      mapping[segments[0]] = ['', message]
    } else if (segments.length === 2) {
      const [parent, child] = segments
      mapping[parent] = mapping[parent] || []
      mapping[parent].push([child, message])
    } else if (segments.length === 3) {
      mapping[segments[0]] = { [segments[1]]: { [segments[2]]: message } }
    }
  })

  return mapping
}
