import React, { memo, useState } from 'react'
import styled from 'styled-components'
import { faStar, faStarHalf } from '@fortawesome/pro-solid-svg-icons'
import { useNavigate, useParams } from 'react-router-dom'

import { Colors } from 'shared/styles/Colors'
import { Icon } from 'shared/components/Icon/Icon'

const { brand, goldenYellow, grey10, grey50, white } = Colors

const StarDetails = memo(({ active, isHalf }) => (
  <StarWrapper>
    {(active || isHalf) && (
      <StarFilled icon={isHalf ? faStarHalf : faStar} color={goldenYellow} size={20} />
    )}
    <StarBlank icon={faStar} color={grey50} size={20} />
  </StarWrapper>
))

const StarReviews = memo(({ active, isHalf, onClick, onMouseEnter, small }) => (
  <StarBlockWrapper
    active={active}
    isHalf={isHalf}
    clickable={!!onClick}
    onMouseEnter={onMouseEnter}
    onClick={onClick}
    small={small}
  >
    <Icon icon={faStar} size={small ? 12 : 16} color={white} />
  </StarBlockWrapper>
))

/**
 * @param rating - rating from 0 to 5
 * @param count - reviews quantity
 * @param type - for now - either `details` or `reviews`
 * @param onStarClick - function with rating value as param - `(rating) => setRating(rating)`
 * @param small - boolean - determine whether to render normal or smaller sized stars
 * @param containerStyle - additional style applied to Container
 */

export const ReviewStars = memo(
  ({ rating, count, type = 'details', onStarClick, small, containerStyle }) => {
    const { slug } = useParams()
    const navigate = useNavigate()

    const [hover, setHover] = useState(null)

    const inDetails = type === 'details'
    /* NOTE: is used to "round up" the stars filling
     * for example: average rating is 4.79 will show full 5 stars
     */

    const starsRating = Math.round(rating / 0.5) * 0.5

    const handleWrapperClick = () => navigate(`/b/${slug}/reviews`)

    const renderStar = (_, idx) => {
      const starIdx = idx + 1
      const active = starIdx <= (hover || starsRating)
      const isHalf = starIdx - 1 < starsRating && starIdx > starsRating

      return inDetails ? (
        <StarDetails key={'star_' + starIdx} active={active} isHalf={isHalf} />
      ) : (
        <StarReviews
          key={'star_' + starIdx}
          active={active}
          isHalf={isHalf}
          onClick={() => onStarClick?.(starIdx)}
          small={small}
        />
      )
    }

    return (
      <Container $style={containerStyle}>
        <ContentWrapper onClick={inDetails ? handleWrapperClick : undefined} inDetails={inDetails}>
          <StarsContainer
            onMouseLeave={inDetails ? undefined : () => setHover(null)}
            hasMargin={!!count}
            inDetails={inDetails}
          >
            {[...Array(5)].map(renderStar)}
          </StarsContainer>

          {!!count && <CountText>({count})</CountText>}
        </ContentWrapper>
      </Container>
    )
  },
)

const Container = styled.div(({ inDetails, $style }) => ({
  display: 'flex',
  alignItems: 'center',
  ...$style,

  '@media (min-width: 768px)': {
    margin: inDetails ? '40px auto 20px;' : 0,
  },
}))
const ContentWrapper = styled.div(({ inDetails }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 10,
  cursor: inDetails ? 'pointer' : 'auto',
}))

const StarsContainer = styled.div(({ hasMargin, inDetails }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: inDetails ? 12 : 5,
  marginLeft: hasMargin ? 15 : 0,
}))

const StarWrapper = styled.div({
  position: 'relative',
  width: 20,
  height: 20,
})

const StarBlockWrapper = styled(StarWrapper)(({ active, clickable, isHalf, small }) => ({
  width: 34,
  height: 34,
  cursor: clickable ? 'pointer' : 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '25%',
  background:
    active || isHalf
      ? `linear-gradient(90deg, ${brand} 50%, ${isHalf ? grey10 : brand} 50%)`
      : grey10,

  ...(small
    ? {
        width: 24,
        height: 24,
      }
    : {}),
}))

const StarBlank = styled(Icon)({ position: 'absolute', top: 0, left: 0 })

const StarFilled = styled(StarBlank)({ zIndex: 2 })

const CountText = styled.p({
  color: brand,
  fontSize: 20,
  margin: 0,
  padding: 0,
  marginBottom: -2,
})
