import { BakedGoods } from './FormPages/BakedGoods'
import { CompletionDate } from './FormPages/CompletionDate'
import { OtherDetails } from './FormPages/OtherDetails'
import { Pictures } from './FormPages/Pictures'
import { Quantity } from './FormPages/Quantity'
import { Questionnaire } from './FormPages/Questionnaire'
import { TermsAndConditions } from './FormPages/TermsAndConditions'
import { Theme } from './FormPages/Theme'

export const PAGES = [
  { name: 'baked-goods', component: BakedGoods },
  { name: 'quantity', component: Quantity },
  { name: 'pick-up-date', component: CompletionDate },
  { name: 'theme', component: Theme },
  { name: 'questionnaire', component: Questionnaire },
  { name: 'pictures', component: Pictures },
  { name: 'terms', component: TermsAndConditions },
  { name: 'details', component: OtherDetails },
]
