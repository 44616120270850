import { buttonVariants } from 'shared/styles/PremiumThemes'

export const sizes = {
  xs: {
    fontSize: '1.2rem',
    paddingHorizontal: '8px',
    paddingVertical: '4px',
    borderRadius: '4px',
  },
  small: {
    fontSize: '1.6rem',
    paddingHorizontal: '30px',
    paddingVertical: '8px',
  },
  medium: {
    fontSize: '1.8rem',
    paddingHorizontal: '30px',
    paddingVertical: '10px',
  },
  large: {
    fontSize: '2.0rem',
    paddingHorizontal: '40px',
    paddingVertical: '14px',
  },
}

export const processButtonVariant = ({ size, variant = 'outline100' }) => {
  const styleVariant = buttonVariants[variant]

  return {
    borderRadius: sizes[size].borderRadius || styleVariant.borderRadius,
    boxShadow: styleVariant.boxShadow,
    borderWidth: styleVariant.borderWidth,
  }
}
