import React from 'react'

import { FetchError } from 'shared/components/FetchError'
import { SharedComponent } from './SharedComponents'
import { Spinner } from 'shared/components/Spinner'
import { useNotificationSettings } from 'shared/hooks/useNotificationSettings'

export const NotificationSettings = React.memo(() => {
  const { loading, error, refetch, settings, toggleSetting } = useNotificationSettings()

  if (loading) return <Spinner page />
  if (error) return <FetchError page onRetry={refetch} />

  return <SharedComponent settings={settings} toggleSetting={toggleSetting} />
})
