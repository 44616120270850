import * as Yup from 'yup'
import { user } from './rules'

export const NameSchema = Yup.object().shape({ name: user.Name })

export const EmailSchema = Yup.object().shape({ email: user.Email })

export const UserSchema = Yup.object().shape({
  email: user.Email,
  name: user.Name,
  password: user.Password,
})
