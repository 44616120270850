import React, { useMemo } from 'react'
import styled from 'styled-components'
import { addDays, addMonths, format, formatISO } from 'date-fns'
import { Calendar } from 'react-calendar'

import { Colors } from 'shared/styles/Colors'
import { getContrastColor } from 'shared/utils/getContrastColor'
import { useResponsiveLayout } from 'web/hooks/useResponsiveLayout'
import { useThemeContext } from 'web/contexts/ThemeContext'

const dateNow = new Date()

export const CalendarComponent = ({
  value = dateNow,
  bakery,
  onChange = undefined,
  modalStyle = {},
}) => {
  const { isMdUp } = useResponsiveLayout()
  const { primaryColor, secondaryFont } = useThemeContext()

  const blockedLeadTimeDaysCount = useMemo(
    () => (bakery.leadTimeUnit === 'weeks' ? bakery.leadTimeValue * 7 : bakery.leadTimeValue),
    [bakery],
  )

  const leadTimeBlockedDays = useMemo(
    () =>
      Array.from({ length: blockedLeadTimeDaysCount + 1 }, (_, i) =>
        formatISO(addDays(dateNow, i), { representation: 'date' }),
      ),
    [blockedLeadTimeDaysCount],
  )

  const blockedDates = useMemo(
    () => bakery.blockedDates.concat(bakery.leadTimeBlockEnabled ? leadTimeBlockedDays : []),
    [bakery, leadTimeBlockedDays],
  )

  const isLeadTimeBlocked = (date) => blockedDates.includes(format(date, 'yyyy-MM-dd'))

  const isFutureBlocked = (date) =>
    bakery.futureBlockMonths && date > addMonths(dateNow, bakery.futureBlockMonths)

  const isDateBlocked = (date) => {
    return isLeadTimeBlocked(date) || isFutureBlocked(date)
  }

  const handleClickDay = (date) => {
    if (isLeadTimeBlocked(date)) {
      alert(`${bakery.name} is not accepting orders for this date.`)
    }

    if (isFutureBlocked(date)) {
      alert(
        `${bakery.name} does not accept order requests this far in advance. Please contact them directly.`,
      )
    }
  }

  const selectedTextColor = useMemo(() => {
    if (primaryColor !== Colors.defaultPrimary) return getContrastColor(primaryColor)
  }, [primaryColor])

  return (
    <CalendarContainer
      modalStyle={modalStyle}
      selectedColor={primaryColor}
      selectedTextColor={selectedTextColor}
      fontFamily={secondaryFont}
    >
      <Calendar
        value={value}
        onChange={(date) => !!onChange && !isDateBlocked(date) && onChange(date)}
        onClickDay={handleClickDay}
        calendarType="gregory"
        minDetail="month"
        next2Label={null}
        prev2Label={null}
        minDate={dateNow}
        showNeighboringMonth={false}
        showDoubleView={isMdUp}
        tileClassName={({ date }) => isDateBlocked(date) && 'blocked'}
      />
    </CalendarContainer>
  )
}

export const CalendarContainer = styled.div(
  ({ modalStyle, selectedColor, fontFamily, selectedTextColor }) => ({
    display: 'flex',
    justifyContent: 'center',

    '.react-calendar': {
      border: 0,
      backgroundColor: 'transparent',
      fontFamily,
      ...modalStyle,
    },
    '.react-calendar__navigation__label': {
      color: Colors.grey100,
    },
    '.react-calendar__navigation button[disabled]': {
      backgroundColor: 'transparent',
    },
    '.react-calendar__month-view__days__day--neighboringMonth': {
      opacity: 0,
    },
    '.react-calendar__tile': {
      color: Colors.grey100,
      borderRadius: 100,
    },
    '.react-calendar__month-view__days__day--weekend': {
      color: Colors.grey100,
    },
    '.blocked:hover, .blocked:focus': {
      backgroundColor: 'transparent',
    },
    '.react-calendar__tile:disabled, .blocked': {
      backgroundColor: 'transparent',
      color: Colors.grey50,
    },
    '.react-calendar__tile--active:hover, .react-calendar__tile--active:focus': {
      backgroundColor: selectedColor,
    },
    '.react-calendar__tile--now': {
      fontWeight: 'bold',
      background: 'none',
    },
    '.react-calendar__tile--now:hover': {
      background: '#e6e6e6',
    },
    '.react-calendar__tile--active.react-calendar__tile--now': {
      backgroundColor: selectedColor,
      color: selectedTextColor,
    },
    '.react-calendar__tile--active': {
      backgroundColor: selectedColor,
      color: selectedTextColor,
    },
  }),
)
