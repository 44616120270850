import React, { useEffect } from 'react'

import { FetchError } from 'shared/components/FetchError'
import { SignInRequiredPage } from 'web/pages/SignInRequired'
import { useCurrentUser } from 'shared/hooks/useCurrentUser'
import { useInvoiceContext } from 'shared/contexts/InvoiceContext'

let currentUserWas = null

/* NOTE: higher order component to show error if order is not owned by user */
export const withOwnership = (WrappedComponent) => (props) => {
  const currentUser = useCurrentUser()
  const { error } = useInvoiceContext()

  if (error && error.message.includes('not allowed to show')) {
    return currentUser ? (
      <FetchError page message="This order is associated with another account." />
    ) : (
      <SignInRequiredPage />
    )
  }

  useEffect(() => {
    // Refresh the order if the user logs in
    // Reload the page because of this Apollo bug: https://github.com/apollographql/react-apollo/issues/3571

    if (error && currentUser && !currentUserWas) window.location.reload()
    currentUserWas = currentUser
  }, [currentUser, error])

  return <WrappedComponent {...props} />
}
