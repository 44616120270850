import React, { useMemo } from 'react'

import { Helper, Row, Strong } from 'shared/components/Invoice/styles'
import { MultiLineText } from 'web/components/form'
import { useInvoiceContext } from 'shared/contexts/InvoiceContext'

export const InvoiceNoteSection = ({ show, Container = React.Fragment }) => {
  const { order } = useInvoiceContext()

  const note = useMemo(() => order?.finalInvoice?.note, [order.finalInvoice])

  if (
    !show ||
    !note ||
    order?.bakerOrderDetails?.includes(note) ||
    note.includes(order?.bakerOrderDetails)
  )
    return null

  return (
    <Container>
      <Row>
        <Strong>Note to Customer</Strong>
      </Row>

      <Row>
        <MultiLineText Container={Helper}>{note}</MultiLineText>
      </Row>
    </Container>
  )
}
