import React, { memo } from 'react'
import styled from 'styled-components'
import { formatDistance, parseISO } from 'date-fns'
import { useFragment } from '@apollo/client'

import { Colors } from 'shared/styles/Colors'
import { FixedWidthContainer, MediaQueries } from 'web/styles/responsive'
import { fontSizeAdjustments } from 'shared/styles/PremiumThemes'
import { Header } from 'web/pages/OrderRequest/styles'
import { ReviewBars } from 'web/components/reviews/ReviewBars'
import { ReviewFragment } from 'shared/graphql/fragments'
import { ReviewStars } from 'web/components/reviews/ReviewStars'
import { useBakeryContext } from 'web/contexts/BakeryContext'
import { useThemeContext } from 'web/contexts/ThemeContext'
import { useWindowDimensions } from 'web/components/WindowDimensionsProvider'

export const ReviewItem = memo(({ reviewId }) => {
  const { data } = useFragment({
    fragment: ReviewFragment,
    fragmentName: 'Review',
    from: {
      __typename: 'Review',
      id: reviewId,
    },
  })

  return (
    <ReviewItemContainer>
      <ReviewItemTextWrap>
        <Text size={30} bold>
          {data.title}
        </Text>
        <Text>{data.name}</Text>
      </ReviewItemTextWrap>

      <ReviewItemStarsWrap>
        <ReviewStars rating={data.rating} type="reviews" small />
        <Text size={14} color={Colors.grey50}>
          {formatDistance(parseISO(data.reviewDate), new Date(), { addSuffix: true })}
        </Text>
      </ReviewItemStarsWrap>
      <div>{data.comments}</div>
    </ReviewItemContainer>
  )
})

export const Reviews = memo(() => {
  const { layoutHeight } = useWindowDimensions()
  const { bakery } = useBakeryContext()

  const { reviews, reviewSummary } = bakery

  return (
    <Container minHeight={layoutHeight}>
      <HeaderTitle>Ratings & Reviews</HeaderTitle>
      <HeaderSubtitle>Overall Rating</HeaderSubtitle>
      <StarsWrapper>
        <ReviewStars rating={reviewSummary.average} type="reviews" />
        {reviews.length} Rating{reviews.length > 1 || reviews.length === 0 ? 's' : ''}
      </StarsWrapper>
      <ReviewBars style={{ marginBottom: 20 }} reviews={reviews} />

      <Separator />

      <div>
        {bakery.reviews.map((review) => (
          <div key={review.id}>
            <ReviewItem reviewId={review.id} />
            {reviews.length > 1 && <Separator />}
          </div>
        ))}
      </div>
    </Container>
  )
})

const Container = styled(FixedWidthContainer)(({ minHeight }) => ({
  paddingBottom: 40,
  backgroundColor: Colors.white,
  minHeight: minHeight - 10,
}))

const HeaderTitle = styled(Header)(() => {
  const { primaryFont } = useThemeContext()

  return {
    fontSize: `${2.5 + (fontSizeAdjustments[primaryFont] || 0)}rem`,
    marginBottom: 10,
    '@media (max-width: 767px)': {
      textAlign: 'left',
    },
  }
})

const HeaderSubtitle = styled.h2(() => {
  const { primaryFont } = useThemeContext()

  return {
    fontSize: `${1.8 + (fontSizeAdjustments[primaryFont] || 0)}rem`,
    marginBottom: 20,
    textAlign: 'left',
  }
})

const StarsWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 20,
  marginTop: 20,
  marginBottom: 20,
})

const Text = styled.span(({ color, size, bold }) => ({
  color,
  fontSize: size,
  ...(bold ? { fontWeight: 'bold' } : {}),
}))

const Separator = styled.div({
  height: 1,
  flexShrink: 0,
  backgroundColor: Colors.grey10,
  marginTop: 20,
  marginBottom: 20,
})

const ReviewItemContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',

  [`@media ${MediaQueries.mdUp}`]: {
    width: '50%',
  },
})

const ReviewItemTextWrap = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginBottom: 10,
})

const ReviewItemStarsWrap = styled(ReviewItemTextWrap)({
  justifyContent: 'flex-start',
  gap: 16,
})
