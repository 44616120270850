import React, { useRef } from 'react'
import styled from 'styled-components'
import { faImage, faLock, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Colors } from 'shared/styles/Colors'
import { ImagePreview } from './ImagePreview'
import { StyledAutoResizeTextArea } from 'web/components/form/TextAreaField'
import { Text } from 'shared/components/Cross'

export const Editor = React.memo(({ messageSenderBag, readOnly }) => {
  const fileInputRef = useRef(null)
  const { onSend, isSending, message, setMessage, images, addImages, removeImage } =
    messageSenderBag
  const sendButtonDisabled = isSending || (!message.length && !images?.length)

  const onKeyPress = (evt) => {
    if (evt.charCode === 13 && !evt.shiftKey) {
      evt.preventDefault()
      onSend()
    }
  }

  const onChange = (evt) => setMessage(evt.target.value)

  const onImageSelect = (e) => {
    e.preventDefault()
    const files = Array.from(e.target.files)
    addImages(files)
    e.target.value = null
  }

  const openImagePicker = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  if (readOnly)
    return (
      <Container>
        <ReadOnlyBlock>
          <FontAwesomeIcon icon={faLock} color={Colors.grey80} size={20} />
          <ReadOnlyText>(Read Only)</ReadOnlyText>
        </ReadOnlyBlock>
      </Container>
    )

  return (
    <Container>
      <ImagePreview images={images} removeImage={removeImage} canRemove={!isSending} />
      <FileInput
        type="file"
        accept="image/*"
        multiple
        ref={fileInputRef}
        id="messenger-file-upload"
        onChange={onImageSelect}
      />
      <InnerContainer>
        <StyledIcon
          icon={faImage}
          color={Colors.blue}
          size="lg"
          disabled={isSending}
          onClick={isSending ? null : openImagePicker}
        />
        <StyledInput
          placeholder="Type a message..."
          onKeyPress={onKeyPress}
          value={message}
          onChange={onChange}
        />
        {isSending ? (
          <StyledIcon icon={faSpinnerThird} color={Colors.blue} size="lg" spin />
        ) : (
          <StyledIcon
            icon={faPaperPlane}
            color={Colors.blue}
            size="lg"
            onClick={onSend}
            disabled={sendButtonDisabled}
          />
        )}
      </InnerContainer>
    </Container>
  )
})

const Container = styled.div`
  border-top: 2px solid ${Colors.grey10};
  padding: 10px 15px;
`

const FileInput = styled.input({
  display: 'none',
})

const InnerContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

const StyledInput = styled(StyledAutoResizeTextArea)`
  margin: 0 15px;
  max-height: 200px;
  border: 0;
  background-color: ${Colors.formFieldBackground};
`

const StyledIcon = styled(FontAwesomeIcon)`
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  cursor: ${(props) => (props.disabled ? 'initial' : 'pointer')};
  margin-top: 7px;
`

const ReadOnlyBlock = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 5,
  marginHorizontal: '15%',
  padding: 10,
  borderColor: Colors.grey25,
  borderWidth: 1,
  borderRadius: 4,
})
const ReadOnlyText = styled(Text)({
  color: Colors.grey80,
  fontSize: 20,
})
