import React from 'react'
import styled from 'styled-components'
import { faBars } from '@fortawesome/pro-light-svg-icons'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import { Colors } from 'shared/styles/Colors'
import { MediaQueries } from 'web/styles/responsive'
import { UserAvatar } from 'shared/components/UserAvatar/UserAvatar'

export const Header = React.memo(({ activeConversation, openConversations }) => {
  const { otherUser } = activeConversation
  const { bakery } = otherUser

  return (
    <Container>
      <Inner>
        <ConversationsToggle icon={faBars} onClick={openConversations} />
        <UserAvatar
          user={otherUser}
          bakery={otherUser.bakery}
          style={{ marginRight: '10px' }}
          size={40}
        />
        {bakery && bakery.name ? (
          <BakeryLink to={`/b/${bakery.slug}`}>{bakery.name}</BakeryLink>
        ) : (
          otherUser.name
        )}

        {otherUser.verified && (
          <FontAwesomeIcon
            icon={faCheckCircle}
            color={Colors.blue}
            size={20}
            style={{ marginLeft: 5 }}
          />
        )}
      </Inner>
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  border-bottom: 2px solid ${Colors.grey10};
  padding: 10px 10px;
`

const Inner = styled.div({
  fontSize: '2rem',
  marginLeft: 10,
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  padding: '0 40px',

  [`@media ${MediaQueries.mdUp}`]: {
    padding: 0,
  },
})

const ConversationsToggle = styled(FontAwesomeIcon)({
  position: 'absolute',
  left: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  cursor: 'pointer',

  [`@media ${MediaQueries.mdUp}`]: {
    display: 'none',
  },
})

const BakeryLink = styled(Link)({
  textDecoration: 'none',
})
