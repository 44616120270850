import React from 'react'
import styled from 'styled-components'
import { Colors } from 'shared/styles/Colors'
import { ContentWithBreaks } from 'web/components/ContentWithBreaks'
import { setLightboxImage } from 'shared/reducers/ui'
import { useCurrentUser } from 'shared/hooks/useCurrentUser'
import { useDispatch } from 'react-redux'

export const Message = React.memo(({ message }) => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()

  const isSelf = message.sender.id === currentUser.id

  return (
    <>
      {message.images?.map((image) => (
        <Container key={image.id}>
          {isSelf && <Spacer />}
          <StyledImage
            src={image.thumbnailUrl}
            isSelf={isSelf}
            onClick={() => dispatch(setLightboxImage(image.fullUrl))}
          />
          {!isSelf && <Spacer />}
        </Container>
      ))}
      {!!message.content?.length && (
        <Container>
          {isSelf && <Spacer />}
          <Content isSelf={isSelf}>
            <ContentWithBreaks content={message.content} />
          </Content>
          {!isSelf && <Spacer />}
        </Container>
      )}
    </>
  )
})

const Container = styled.div`
  display: flex;
  flex-shrink: 0;
`

const Spacer = styled.div`
  flex: 1;
`

const Content = styled.div(({ isSelf }) => ({
  maxWidth: '60%',
  backgroundColor: isSelf ? Colors.blue : Colors.grey10,
  color: isSelf ? Colors.white : Colors.grey100,
  padding: '5px 10px',
  marginTop: '10px',
  borderRadius: 14,
  borderBottomRightRadius: isSelf ? 0 : 14,
  borderBottomLeftRadius: isSelf ? 14 : 0,
}))

const StyledImage = styled.img(({ isSelf }) => ({
  height: 250,
  width: 250,
  backgroundColor: isSelf ? Colors.blue : Colors.grey10,
  marginTop: '10px',
  borderRadius: 14,
  borderBottomRightRadius: isSelf ? 0 : 14,
  borderBottomLeftRadius: isSelf ? 14 : 0,
  border: `1px solid ${Colors.grey10}`,
  cursor: 'pointer',
}))
