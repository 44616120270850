import React from 'react'
import styled from 'styled-components'

import { Alerts } from './Alerts'
import { Header } from './Header/Header'
import { useOrderContext } from 'web/contexts/OrderContext'
import { useThemeContext } from 'web/contexts/ThemeContext'

export const OrderPageLayout = ({ alt, children }) => {
  const { bakery } = useOrderContext()

  return (
    <OrderLayout alt={alt} bakery={bakery}>
      {children}
    </OrderLayout>
  )
}

const OrderLayout = ({ alt, bakery, children }) => {
  const { secondaryFont } = useThemeContext()

  return (
    <Container fontFamily={secondaryFont}>
      <Alerts />
      <Header alt={alt} bakery={bakery} />
      {children}
    </Container>
  )
}

const Container = styled.div(({ fontFamily }) => ({
  fontFamily,
}))
