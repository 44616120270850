import { Colors } from 'shared/styles/Colors'
import { Configuration } from 'shared/services/Configuration'

export const pendingPaymentState = {
  text: 'Pending Payment',
  textColor: Colors.white,
  bgColor: Colors.darkerYellow,
}

export const friendlyState = (state) => {
  const states = {
    requested: { text: 'Requested', textColor: Colors.white, bgColor: Colors.grey75 },
    orderRequestDeclined: { text: 'Declined', textColor: Colors.white, bgColor: Colors.red },
    pending: {
      text: 'Pending',
      textColor: Colors.white,
      bgColor: Colors.darkerYellow,
    },
    pendingDeposit: {
      text: 'Pending Deposit',
      textColor: Colors.white,
      bgColor: Colors.darkerYellow,
    },
    pendingConfirmation: {
      text: 'Pending Customer Confirmation',
      textColor: Colors.white,
      bgColor: Colors.darkerYellow,
    },
    confirmed: { text: 'Confirmed', textColor: Colors.white, bgColor: Colors.green },
    cancelled: { text: 'Cancelled', textColor: Colors.white, bgColor: Colors.red },
    refunded: { text: 'Refunded', textColor: Colors.white, bgColor: Colors.red },
    pendingFinalPayment: {
      text: 'Pending Final Payment',
      textColor: Colors.white,
      bgColor: Colors.darkerYellow,
    },
    paid: { text: 'Paid', textColor: Colors.white, bgColor: Colors.green },
    complete: { text: 'Complete', textColor: Colors.white, bgColor: Colors.green },
    import: { text: 'Imported', textColor: Colors.white, bgColor: Colors.grey75 },
    instantCheckoutRequested: pendingPaymentState,
    instantCheckoutAccepted: pendingPaymentState,
  }

  return states[state] || { text: state, textColor: Colors.white, bgColor: Colors.grey75 }
}

export const StateData = [
  { value: 'all', label: 'All' },
  { value: 'cancelled', label: 'Cancelled' },
  { value: 'complete', label: 'Complete' },
  { value: 'confirmed', label: 'Confirmed' },
  { value: 'orderRequestDeclined', label: 'Declined' },
  { value: 'paid', label: 'Paid' },
  { value: 'pendingDeposit', label: 'Pending Deposit' },
  { value: 'pendingFinalPayment', label: 'Pending Final Payment' },
  { value: 'refunded', label: 'Refunded' },
  { value: 'requested', label: 'Requested' },
]

export const bakerOrderDetailsEditable = (order) => order.state !== 'requested'

export const orderUrl = (order) => `${Configuration.bakesyUrl}/orders/${order?.orderNumber}`
export const orderShortUrl = (order) => `${Configuration.bakesyUrl}/o/${order?.orderNumber}`
export const reviewUrl = (order) => `${Configuration.bakesyUrl}/review/${order.orderNumber}`

export const printOrderUrl = (order) =>
  `${Configuration.bakesyUrl}/print-order/${order.orderNumber}`
