import { gql, useMutation, useQuery } from '@apollo/client'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Fragments } from 'shared/constants/Fragments'
import { selectSettings, setSettings } from 'shared/reducers/account'

const onErrorAlert = (error) => alert(error)

export const useNotificationSettings = () => {
  const dispatch = useDispatch()

  const settings = useSelector(selectSettings)

  const { loading, error, refetch } = useQuery(GET_USER_SETTINGS, {
    onCompleted: (data) => {
      dispatch(setSettings(data.me.settings))
    },
  })
  const [updateUserSettingMutation] = useMutation(UPDATE_USER_SETTING)

  const toggleSetting = useCallback(
    async (settingName, syncCalendar) => {
      const initialSetting = settings[settingName]
      dispatch(
        setSettings({
          ...settings,
          [settingName]: !initialSetting,
        }),
      )

      try {
        const {
          data: {
            updateUserSettings: { orders, user, errors },
          },
        } = await updateUserSettingMutation({
          variables: { input: { [settingName]: !initialSetting } },
        })

        if (errors.length) throw errors[0].messages[0]

        setSettings(user.settings)
        if (user.settings?.calendarSync !== null) syncCalendar?.(orders, user.settings)
      } catch (e) {
        setSettings({
          ...settings,
          [settingName]: initialSetting,
        })

        console.log(e)
        onErrorAlert('Error updating setting.')
      }
    },
    [dispatch, settings, updateUserSettingMutation],
  )

  return {
    settings,
    loading,
    error,
    refetch,
    toggleSetting,
    updateUserSettingMutation,
  }
}

const GET_USER_SETTINGS = gql`
  ${Fragments.UserSettingsFragment}

  query GetUserSettings {
    me {
      settings {
        ...UserSettingsFragment
      }
    }
  }
`

const UPDATE_USER_SETTING = gql`
  ${Fragments.UserSettingsFragment}

  mutation UpdateUserSetting($input: UpdateUserSettingsInput!) {
    updateUserSettings(input: $input) {
      user {
        settings {
          ...UserSettingsFragment
        }
      }
      orders {
        id
        orderNumber
        completionDate
        customerName
        customer {
          name
        }
      }
      errors {
        path
        messages
      }
    }
  }
`
