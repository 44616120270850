import { setAlert } from 'shared/reducers/ui'

export const bakeryPath = (bakery) => `/b/${bakery.slug}`

export const notifyAboutManagement = ({ user, dispatch }) => {
  if (user.bakery) {
    dispatch(
      setAlert({
        title: 'Heads up!',
        body: 'Bakery management tasks must be performed in the Bakesy app at this time.',
      }),
    )
  }
}
