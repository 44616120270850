import React from 'react'

import * as Cross from 'shared/components/Cross'
import { Configuration } from 'shared/services/Configuration'
import { Fonts } from 'shared/styles/Typography'
import { friendlyState } from 'shared/utils/orders'
import { styler } from 'shared/utils/styler'

export const StateLabel = ({ state, lookup = friendlyState, style = {}, textStyle = {} }) => {
  const stateParams = lookup(state)

  return (
    <Label style={{ backgroundColor: stateParams.bgColor, ...style }}>
      <StateText style={{ color: stateParams.textColor, ...textStyle }}>
        {stateParams.text}
      </StateText>
    </Label>
  )
}

const Label = styler(Cross.View)({
  paddingTop: 2,
  paddingBottom: Configuration.isNative ? 5 : 2,
  paddingLeft: 10,
  paddingRight: 10,
  borderRadius: 4,
  display: Configuration.isNative ? 'flex' : 'inline-flex',
})

const StateText = styler(Cross.Text)({
  fontWeight: Configuration.isNative ? undefined : 'bold',
  fontFamily: Configuration.isNative ? Fonts.SourceSansPro.Bold : undefined,
  fontSize: Configuration.isNative ? 12 : '1.2rem',
  paddingTop: 3,
  textTransform: 'uppercase',
  letterSpacing: 1,
})
