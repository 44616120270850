import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  faqs: [],
}

const faqsSlice = createSlice({
  name: 'faqs',
  initialState,
  reducers: {
    reset: () => {
      return initialState
    },
    setFaqs: (state, action) => {
      state.faqs = action.payload
    },
    addFaq: (state, action) => {
      state.faqs.push(action.payload)
    },
    removeFaq: (state, action) => {
      const index = state.faqs.findIndex((faq) => faq.id === action.payload)
      if (index > -1) state.faqs.splice(index, 1)
    },
    saveFaq: (state, action) => {
      const index = state.faqs.findIndex((faq) => faq.id === action.payload.id)
      if (index > -1) state.faqs[index] = action.payload
    },
  },
})

export const { addFaq, setFaqs, removeFaq, saveFaq, reset } = faqsSlice.actions

export const faqsReducer = faqsSlice.reducer

export const selectFaqs = (state) => state?.faqs?.faqs
