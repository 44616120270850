import React from 'react'

import { addColon } from 'shared/utils/textTransforms'

export const CreditCard = ({ paymentMethod }) => (
  <span>
    {paymentMethod.name}
    {paymentMethod.username && addColon(paymentMethod.username)}
  </span>
)
