import React, { useRef } from 'react'
import styled from 'styled-components'

import * as Cross from 'shared/components/Cross'
import { GalleryItem } from './GalleryItem'
import { styler } from 'shared/utils/styler'

export const GalleryList = React.memo(({ data, itemProps, galleryWidth, addImages }) => {
  const fileInputRef = useRef(null)

  const onImageSelect = (e) => {
    document.activeElement.blur()
    e.preventDefault()
    const images = Array.from(e.target.files)
    addImages(images)
    e.target.value = null
  }

  const openImagePicker = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <Container width={galleryWidth}>
      <FileInput
        type="file"
        accept="image/*"
        multiple
        ref={fileInputRef}
        id="messenger-file-upload"
        onChange={onImageSelect}
      />
      {data.map((item, index) => (
        <GalleryItem
          item={item}
          index={index}
          {...itemProps}
          pickImage={openImagePicker}
          galleryWidth={galleryWidth}
          key={item.id}
        />
      ))}
    </Container>
  )
})

const Container = styler(Cross.View)(({ width }) => ({
  display: 'flex',
  width,
  flexWrap: 'wrap',
}))

const FileInput = styled.input({
  display: 'none',
})
