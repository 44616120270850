import { useMutation } from '@apollo/client'

import { mapGraphqlErrors } from 'shared/utils/mapGraphqlErrors'
import { UpdateOrderRequestDocument, UpdateOrderStatusDocument } from 'shared/graphql/mutations'
import { useLoading } from 'shared/hooks/useLoading'
import { useOrderContext } from 'web/contexts/OrderContext'

/* TODO: move to web hooks since it's used only in web */
export const useUpdateOrder = () => {
  const { loading, withLoading } = useLoading()
  const { order } = useOrderContext()

  /**
   * Those mutations will automatically write fragments to the cache
   * and since we use fragments inside the components, we don't need to
   * write any additional code here to update the order request
   */
  const [updateOrderRequest] = useMutation(UpdateOrderRequestDocument)
  const [updateOrderStatus] = useMutation(UpdateOrderStatusDocument)

  const updateOrder = async ({ input }, callback) =>
    withLoading(async () => {
      try {
        const {
          data: {
            updateOrderDetails: { errors },
          },
        } = await updateOrderRequest({
          variables: {
            input: {
              id: order.id,
              ...input,
            },
          },
        })

        if (!errors.length) {
          callback?.()
        } else {
          return mapGraphqlErrors(errors)
        }
      } catch (e) {
        console.error(e)
        alert('Error saving order details.')
      }
    })

  const saveOrder = async ({ input }) =>
    withLoading(async () => {
      try {
        const {
          data: {
            updateOrderStatus: { errors },
          },
        } = await updateOrderStatus({
          variables: {
            input: {
              id: order.id,
              ...input,
            },
          },
        })
        if (errors.length) {
          return mapGraphqlErrors(errors)
        }
      } catch (e) {
        console.error(e)
        alert('Error saving order details.')
      }
    })

  return { loading, updateOrder, saveOrder }
}
