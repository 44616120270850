import React from 'react'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

import { ActionModule } from './ActionModule'
import {
  ApplePay,
  BankTransfer,
  Cash,
  CashApp,
  CreditCard,
  Default,
  PayPal,
  Square,
  Stripe,
  Venmo,
  Zelle,
} from './PaymentProviders'
import { Icon } from 'shared/components/Icon/Icon'
import { PaymentProviders, Strong, TextDivider } from 'shared/components/Invoice/styles'
import { useInvoiceContext } from 'shared/contexts/InvoiceContext'

const SHOW_PAYMENT_SECTION_STATES = [
  'pendingDeposit',
  'pendingFinalPayment',
  'confirmed',
  'instantCheckoutRequested',
]
const SHOW_STRIPE_STATES = ['pendingDeposit', 'pendingFinalPayment', 'instantCheckoutRequested']

export const PaySection = ({ currentUser, show, Container = React.Fragment }) => {
  const { order } = useInvoiceContext()

  if (
    !show ||
    !SHOW_PAYMENT_SECTION_STATES.includes(order.state) ||
    (order.allowBakesyPayments && !order.allowOtherPayments && order.state === 'confirmed')
  )
    return null

  const paymentMethods = order?.bakery?.paymentMethods

  let paymentType, invoice, invoiceId
  switch (true) {
    case order.state === 'pendingDeposit' && order.depositInvoice.totalDueInCents === 0:
      invoice = order.finalInvoicePreview
      invoiceId = order.depositInvoice.id
      paymentType = 'final'
      break

    case order.state === 'pendingDeposit':
      invoice = order.depositInvoice
      invoiceId = invoice.id
      paymentType = 'deposit'
      break

    case ['pendingFinalPayment', 'instantCheckoutRequested'].includes(order.state):
      invoice = order.finalInvoice
      invoiceId = invoice.id
      paymentType = 'final'
      break

    default:
      invoice = order.finalInvoicePreview
      invoiceId = invoice.id
      paymentType = 'final'
      break
  }

  const renderPaymentMethods = (paymentMethod) => {
    switch (paymentMethod.name || paymentMethod.displayType) {
      case 'Credit Card':
        return <CreditCard paymentMethod={paymentMethod} key={paymentMethod.id} />

      case 'Venmo':
        return <Venmo paymentMethod={paymentMethod} key={paymentMethod.id} />

      case 'CashApp':
        return <CashApp paymentMethod={paymentMethod} key={paymentMethod.id} />

      case 'PayPal':
        return <PayPal paymentMethod={paymentMethod} key={paymentMethod.id} />

      case 'Square':
        return <Square paymentMethod={paymentMethod} key={paymentMethod.id} />

      case 'Zelle':
        return <Zelle paymentMethod={paymentMethod} key={paymentMethod.id} />

      case 'Cash':
        return <Cash paymentMethod={paymentMethod} key={paymentMethod.id} />

      case 'Bank Transfer':
        return <BankTransfer paymentMethod={paymentMethod} key={paymentMethod.id} />

      case 'Apple Pay':
        return <ApplePay paymentMethod={paymentMethod} key={paymentMethod.id} />

      default:
        return <Default paymentMethod={paymentMethod} key={paymentMethod.id} />
    }
  }

  const renderHeader = () =>
    invoice.totalDueInCents !== 0 && (
      <>
        <Icon icon={faExclamationCircle} style={{ marginRight: 10 }} />
        Please refer to payment method(s) below to make payment.
      </>
    )

  const forbidPaymentMethods =
    (!order.allowBakesyPayments && !order.allowOtherPayments) ||
    (paymentType === 'final' && invoice.totalDueInCents === 0)

  if (forbidPaymentMethods) return null

  const hasPaymentMethods = !!paymentMethods?.length && order.allowOtherPayments

  const hasStripe =
    SHOW_STRIPE_STATES.includes(order.state) &&
    (order.customer.contactOnly || currentUser?.id === order.customer?.id) &&
    currentUser?.id !== order.bakery.user.id &&
    order.allowBakesyPayments

  return (
    <Container>
      <ActionModule header={renderHeader()}>
        {hasPaymentMethods && (
          <PaymentProviders>
            <Strong>Payment Method(s):</Strong>
            {paymentMethods.map(renderPaymentMethods)}
          </PaymentProviders>
        )}

        {hasStripe && order.state !== 'confirmed' && (
          <>
            {hasPaymentMethods && <TextDivider>OR</TextDivider>}
            <Stripe
              invoice={invoice}
              invoiceId={invoiceId}
              collectTips={order.bakery.collectTips}
            />
          </>
        )}
      </ActionModule>
    </Container>
  )
}
