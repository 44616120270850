import numeral from 'numeral'
import { divide, round } from 'shared/utils/number'
import { store } from 'shared/reducers/store'

export const formatCents = (cents, currency = { symbol: '$' }, displayCents = true) => {
  const symbol = store?.getState()?.account?.bakery?.currency?.symbol
  const rounded = cents ? divide(cents, 100).toFixed(2) : 0
  return (
    (symbol ? symbol : currency?.symbol) +
    numeral(rounded).format(`0,0${displayCents ? '.00' : ''}`)
  )
}

export const moneyInputRegex = /^[0-9]+([.,][0-9][0-9])?$/
export const moneyInputRegexNoCents = /^[0-9]+$/
export const moneyInputRegexOneCent = /^[0-9]+([.,][0-9])?$/
export const moneyInputRegexTooManyCents = /^[0-9]+([.,][0-9]{3,})?$/

export const normalizeMoney = (value) => {
  const normalizedValue = typeof value === 'string' ? value.replace(',', '.') : value
  if (normalizedValue?.match(moneyInputRegexNoCents)) return `${normalizedValue}.00`
  if (normalizedValue?.match(moneyInputRegexOneCent)) return `${normalizedValue}0`
  if (normalizedValue?.match(moneyInputRegexTooManyCents))
    return round(normalizedValue, 2)?.toString()
  else return normalizedValue
}

export const MONEY_PATTERN_VALIDATION_MESSAGE = 'must be in the format of "10.00"'
