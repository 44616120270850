import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from 'web/components/Button'
import { closeAuthModal, openCreateAccount } from 'shared/reducers/ui'
import { SocialLogin } from './SocialLogin'
import { TextField } from 'web/components/form/TextField'
import { TextLink } from 'web/styles/shared'
import { useDispatch } from 'react-redux'
import { useSignIn } from 'shared/hooks/useSignIn'

export const SignIn = () => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const afterSignIn = () => {
    dispatch(closeAuthModal())
  }

  const { submit, error, loading } = useSignIn({ email, password, afterSignIn })

  const createAccount = (e) => {
    e.preventDefault()
    dispatch(openCreateAccount())
  }

  const onSubmit = (e) => {
    e.preventDefault()
    submit()
  }

  return (
    <Container>
      <Header>Welcome back</Header>

      <Form onSubmit={onSubmit}>
        <TextField
          type="email"
          autocomplete="email"
          inputMode="email"
          label="Email"
          Container={FieldContainer}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          type="password"
          label="Password"
          Container={FieldContainer}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={error}
        />
        <Button fullWidth button disabled={loading} size="large">
          Sign in
        </Button>

        <SocialLoginHeader>or, sign in with:</SocialLoginHeader>
        <SocialLogin />

        <DontHaveAnAccount>
          Don't have an account?{' '}
          <TextLink href="#" as="a" onClick={createAccount}>
            Create one.
          </TextLink>
        </DontHaveAnAccount>
      </Form>
    </Container>
  )
}

const Container = styled.div``

const Header = styled.h2`
  margin-top: 0;
  font-size: 2.6rem;
`

const Form = styled.form`
  margin-top: 20px;
  margin-bottom: 20px;
`

const FieldContainer = styled.div`
  margin-bottom: 20px;
`

const SocialLoginHeader = styled.div`
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 1.6rem;
`

const DontHaveAnAccount = styled.div`
  margin-top: 30px;
`
