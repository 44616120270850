import React from 'react'
import styled from 'styled-components'
import { Colors } from 'shared/styles/Colors'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const ImagePreview = React.memo(({ removeImage, images, canRemove }) => {
  return (
    <Container>
      {images.map((image) => {
        const url = window.URL.createObjectURL(image.data)
        return (
          <ImageContainer key={image.id}>
            {canRemove && (
              <Remove onClick={() => removeImage(image.id)}>
                <StyledIcon icon={faTimes} color={Colors.white} size="sm" />
              </Remove>
            )}
            <StyledImage src={url} />
          </ImageContainer>
        )
      })}
    </Container>
  )
})

const Container = styled.div({
  display: 'flex',
  overflowY: 'auto',
})

const ImageContainer = styled.div({
  position: 'relative',
  marginRight: 10,
  overflow: 'hidden',
  marginBottom: 10,
  border: `1px solid ${Colors.grey10}`,
  flexShrink: 0,
})

const StyledImage = styled.img({
  width: 100,
  height: 100,
  objectFit: 'cover',
})

const Remove = styled.div({
  position: 'absolute',
  top: 0,
  right: 0,
  padding: '2px 5px',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  cursor: 'pointer',
})

const StyledIcon = styled(FontAwesomeIcon)({})
