import { useEffect, useState } from 'react'

export const useQuestionCounter = ({
  customQuestionsLength,
  dueDateOffset,
  specificationsOffset,
  currentPage,
}) => {
  const [questionCounter, setQuestionCounter] = useState(0)

  useEffect(() => {
    const initialCounter =
      {
        theme: 3,
        questionnaire: 4,
        pictures: 4 + customQuestionsLength,
        terms: 5 + customQuestionsLength,
      }[currentPage] || 3

    setQuestionCounter(initialCounter + dueDateOffset - specificationsOffset)
  }, [customQuestionsLength, currentPage, dueDateOffset, specificationsOffset])

  return { questionCounter, setQuestionCounter }
}
