import { gql, useMutation } from '@apollo/client'
import { useState } from 'react'

import { useCurrentUser } from 'shared/hooks/useCurrentUser'

export const useMobileNumberEntry = ({ onSuccess }) => {
  const user = useCurrentUser()
  const [mobileNumber, setMobileNumber] = useState(user?.mobileNumber || '')
  const [phoneCode, setPhoneCode] = useState(
    user?.countryCode ? { countryCode: user?.countryCode } : { countryCode: 'US' },
  )
  const [rawMobileNumber, setRawMobileNumber] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [requestVerification] = useMutation(REQUEST_MOBILE_VERIFICATION)
  const [error, setError] = useState(null)

  const isDisabled = rawMobileNumber.length <= 8 || isLoading

  const onSubmit = async () => {
    setIsLoading(true)
    setError(null)

    const numberToSend =
      rawMobileNumber === '0000000000' ? '00000000000' : phoneCode?.code + rawMobileNumber

    try {
      const {
        data: {
          requestMobileVerification: { errors },
        },
      } = await requestVerification({
        variables: { input: { mobileNumber: numberToSend, countryCode: phoneCode?.countryCode } },
      })

      if (errors.length) {
        setError(errors[0])
      } else {
        onSuccess({
          mobileNumber: `${phoneCode.code} ${mobileNumber}`,
          rawMobileNumber: numberToSend,
        })
      }
    } catch (e) {
      console.error(e)
      setError('Unknown error sending verification code.')
    }

    setIsLoading(false)
  }

  return {
    mobileNumber,
    rawMobileNumber,
    setMobileNumber,
    setRawMobileNumber,
    phoneCode,
    setPhoneCode,
    isLoading,
    isDisabled,
    error,
    setError,
    onSubmit,
  }
}

const REQUEST_MOBILE_VERIFICATION = gql`
  mutation RequestMobileVerification($input: RequestMobileVerificationInput!) {
    requestMobileVerification(input: $input) {
      errors
    }
  }
`
