export const SpecHints = {
  Default: 'Item specifications like Theme/Color scheme, Flavor(s), etc.',
  Cakes:
    'Cake flavor, Icing type/flavor, Filling, Theme/Color scheme, Design, Diameter, Topper, # of servings, etc.',
  Cookies: 'Cookie design(s), Flavor(s), Theme/Color scheme, etc.',
  Cupcakes: 'Cake flavor, Icing type/flavor, Filling, Theme/Color scheme, Topper, etc.',
  Macarons: 'Macaron flavor, Theme/Color scheme, etc.',
  'Cake Pops': 'Cake Pop flavor, Theme/Color scheme, Topping, etc.',
  Pies: 'Flavor, Number of servings, etc.',
  Treats: 'Type of treat, Flavor, Theme/Color scheme, Topping, etc.',
}
