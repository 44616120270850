import * as Yup from 'yup'
import { MONEY_PATTERN_VALIDATION_MESSAGE, moneyInputRegex } from 'shared/utils/currency'

export const order = {
  DepositAmount: Yup.string()
    .required('is required')
    .matches(moneyInputRegex, { message: MONEY_PATTERN_VALIDATION_MESSAGE }),

  TotalAmount: Yup.string()
    .required('is required')
    .matches(moneyInputRegex, { message: MONEY_PATTERN_VALIDATION_MESSAGE }),

  BalanceAmount: Yup.string()
    .required('is required')
    .matches(moneyInputRegex, { message: MONEY_PATTERN_VALIDATION_MESSAGE }),

  CompletionDate: Yup.string().required('is required'),
  CustomerName: Yup.string().required('is required'),
}
