import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { loadMessages, markRead, messagesForSelector } from 'shared/reducers/messenger'
import { Message } from './Message'
import { PlatformVisibility } from 'shared/services/PlatformVisibility'
import { Spinner } from 'shared/components/Spinner'
import { useDispatch, useSelector } from 'react-redux'

export const Messages = React.memo(({ activeConversation }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const messagesEnd = useRef()

  const { otherUser } = activeConversation
  const messagesFor = useSelector(messagesForSelector)
  const activeMessages = [...messagesFor(otherUser.id)].reverse()

  useEffect(() => {
    if (messagesEnd.current) messagesEnd.current.scrollIntoView()
  }, [activeMessages])

  useEffect(() => {
    const getMessages = async () => {
      setIsLoading(true)
      await dispatch(loadMessages({ otherUserId: otherUser.id }))
      dispatch(markRead({ otherUserId: otherUser.id }))
      setIsLoading(false)
    }
    getMessages()
  }, [dispatch, otherUser.id])

  useEffect(() => {
    const listener = PlatformVisibility.change((e, state) => {
      if (state === 'visible') {
        dispatch(markRead({ otherUserId: otherUser.id }))
      }
    })
    return () => PlatformVisibility.unbind(listener)
  }, [otherUser, dispatch])

  useEffect(() => {
    if (PlatformVisibility.isSupported() && !PlatformVisibility.hidden()) {
      dispatch(markRead({ otherUserId: otherUser.id }))
    }
  }, [otherUser, activeMessages, dispatch])

  if (!activeConversation) return null

  return (
    <Container>
      <Scroller>
        {isLoading ? (
          <Spinner height="100%" />
        ) : (
          <>
            <Spacer />
            {activeMessages.map((m) => (
              <Message key={m.id} message={m} />
            ))}
            <MessagesEnd ref={messagesEnd} />
          </>
        )}
      </Scroller>
    </Container>
  )
})

const Container = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
`

const Scroller = styled.div`
  padding: 10px 10px 0 10px;
  flex-direction: column;
  display: flex;
  width: 100%;
`

const MessagesEnd = styled.div`
  height: 10px;
  flex-shrink: 0;
`

const Spacer = styled.div`
  flex: 1;
`
