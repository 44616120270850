import { useState } from 'react'

export const useLoading = () => {
  const [loading, setLoading] = useState(false)

  const startLoading = () => setLoading(true)
  const stopLoading = () => setLoading(false)

  const withLoading = async (fn) => {
    startLoading()
    const dataToReturn = await fn()
    stopLoading()
    if (dataToReturn) return dataToReturn
  }

  return { loading, startLoading, stopLoading, withLoading }
}
