import React from 'react'
import styled from 'styled-components'
import { Colors } from 'shared/styles/Colors'
import { ErrorMessage } from 'shared/components/Form'

export const TextField = React.memo(
  React.forwardRef(({ label, Container = null, error, name = '', style, ...rest }, ref) => {
    const ContainerComponent = Container || DefaultContainer

    return (
      <ContainerComponent style={style}>
        {label && <Label>{label}</Label>}
        <StyledInput ref={ref} {...rest} />
        <ErrorMessage name={name} error={error} />
      </ContainerComponent>
    )
  }),
)

const DefaultContainer = styled.div``

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
`

const StyledInput = styled.input`
  padding: 10px 15px;
  background: none;
  box-shadow: none;
  border-radius: 4px;
  -webkit-appearance: none;
  outline: none;
  -webkit-appearance: none;
  border: 1px solid ${Colors.grey25};
  color: ${Colors.grey100};
  width: 100%;
  background-color: ${Colors.white};

  &:focus {
    border: 1px solid ${Colors.blue};
  }

  &::placeholder {
    color: ${Colors.grey50};
  }
`
