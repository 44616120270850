import React from 'react'
import styled from 'styled-components'
import TextareaAutosize from 'react-autosize-textarea'
import { Colors } from 'shared/styles/Colors'
import { ErrorMessage } from 'shared/components/Form'

export const TextAreaField = React.memo(({ label, Container = null, error, ...rest }) => {
  const ContainerComponent = Container || DefaultContainer

  return (
    <ContainerComponent>
      {label && <Label>{label}</Label>}
      <StyledTextArea {...rest} />
      <ErrorMessage error={error} />
    </ContainerComponent>
  )
})

const DefaultContainer = styled.div``

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
`

const TextAreaStyles = {
  padding: '10px 15px',
  background: 'none',
  boxShadow: 'none',
  borderRadius: 4,
  '-webkit-appearance': 'none',
  outline: 'none',
  width: '100%',
  resize: 'none',
  color: Colors.grey100,
  border: `1px solid ${Colors.grey25}`,
  backgroundColor: Colors.white,

  '&:focus': {
    borderColor: Colors.blue,
  },

  '&::placeholder': {
    color: Colors.grey50,
  },
}

export const StyledTextArea = styled.textarea({
  ...TextAreaStyles,
})

export const StyledAutoResizeTextArea = styled(TextareaAutosize)({
  ...TextAreaStyles,
})
