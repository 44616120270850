import React, { memo, useMemo } from 'react'
import styled from 'styled-components'

import { ProgressBar } from '../ProgressBar'

/**
 * @param reviews - reviews array
 * @param style - style override for outer component container
 */

export const ReviewBars = memo(({ reviews, style = {} }) => {
  const data = useMemo(() => {
    const result = {}

    if (!reviews?.length) return result

    const reviewsByQuantity = reviews.reduce((acc, el) => {
      const curEl = acc[el.rating] || 0
      return { ...acc, [el.rating]: curEl + 1 }
    }, {})

    Object.keys(reviewsByQuantity).forEach((key) => {
      const curEl = reviewsByQuantity[key]
      result[key] = { qty: curEl, percent: (100 * curEl) / reviews.length }
    })

    return result
  }, [reviews])

  return (
    <Container $style={style}>
      {[...Array(5).keys()].reverse().map((idx) => {
        const barIdx = idx + 1

        return (
          <BarRow key={Math.random()}>
            <BarText>
              {barIdx} star{barIdx > 1 ? 's' : ''}
            </BarText>
            <ProgressBar completed={data[barIdx]?.percent || 0} />
          </BarRow>
        )
      })}
    </Container>
  )
})

const Container = styled.div(({ $style }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 20,
  ...$style,
}))

const BarRow = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
})

const BarText = styled.span({
  width: 80,
})
