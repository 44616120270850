import React from 'react'
import styled from 'styled-components'
import { Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { Button } from 'web/components/Button'
import { closeAuthModal, openSignIn } from 'shared/reducers/ui'
import { ControlledInput, ErrorMessage } from 'shared/components/Form'
import { createAccountScreenTitle } from 'shared/utils/auth'
import { SocialLogin } from './SocialLogin'
import { TextLink } from 'web/styles/shared'
import { useAccountCreation } from 'shared/hooks/useAccountCreation'

export const CreateAccount = ({ serviceData, setServiceData }) => {
  const dispatch = useDispatch()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useAccountCreation({
    serviceData,
    afterCreate: () => dispatch(closeAuthModal()),
  })

  const signIn = (e) => {
    e.preventDefault()
    setServiceData({})
    dispatch(openSignIn())
  }

  return (
    <Container>
      <Header>{createAccountScreenTitle(serviceData)}</Header>

      <Form onSubmit={handleSubmit}>
        <Controller
          name="name"
          control={control}
          render={(controller) => (
            <ControlledInput
              Container={FieldContainer}
              controller={controller}
              label="Name"
              type="text"
            />
          )}
        />

        <Controller
          name="email"
          control={control}
          render={(controller) => (
            <ControlledInput
              Container={FieldContainer}
              controller={controller}
              error={errors.email?.message}
              label="Email"
              type="email"
            />
          )}
        />

        {!serviceData.token && (
          <Controller
            name="password"
            control={control}
            render={(controller) => (
              <ControlledInput
                Container={FieldContainer}
                controller={controller}
                error={errors.password?.message}
                label="Password"
                type="password"
              />
            )}
          />
        )}

        {errors.form && (
          <ErrorContainer>
            <ErrorMessage error={errors.form.message} />
          </ErrorContainer>
        )}

        <Button fullWidth button size="large">
          Create account
        </Button>
      </Form>

      {!serviceData.token && (
        <>
          <SocialLoginHeader>or, create account with:</SocialLoginHeader>
          <SocialLogin />
        </>
      )}

      <DontHaveAnAccount>
        Already have an account?{' '}
        <TextLink href="#" as="a" onClick={signIn}>
          Sign in.
        </TextLink>
      </DontHaveAnAccount>
    </Container>
  )
}

const ErrorContainer = styled.div`
  margin-bottom: 10px;
`

const Container = styled.div``

const Header = styled.h2`
  margin-top: 0;
  font-size: 2.6rem;
`

const Form = styled.form`
  margin-top: 20px;
  margin-bottom: 20px;
`

const FieldContainer = styled.div`
  margin-bottom: 20px;
`

const SocialLoginHeader = styled.div`
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 1.6rem;
`

const DontHaveAnAccount = styled.div`
  margin-top: 30px;
`
