import React from 'react'
import styled from 'styled-components'

export const ContentWithBreaks = ({ content }) => {
  if (!content) return null

  return (
    <>
      {content.split('\n').map((line, i) => (
        <ContentLine key={i}>{line}</ContentLine>
      ))}
    </>
  )
}

const ContentLine = styled.p`
  margin: 0;
  min-height: 10px;
  word-break: break-word;
`
