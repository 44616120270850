import React, { useRef, useState } from 'react'

import { useImageUpload } from './useImageUpload'

export const useAvatarUpload = () => {
  const { uploadImage, loading, error } = useImageUpload()
  const [tmpImage, setTmpImage] = useState(null)
  const fileInputRef = useRef(null)

  const onImageSelect = (e) => {
    e.preventDefault()
    const images = Array.from(e.target.files)
    setTmpImage(URL.createObjectURL(images[0]))
    uploadImage(images[0])
    e.target.value = null
  }

  const onUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const renderInput = () => (
    <input
      type="file"
      accept="image/*"
      ref={fileInputRef}
      id="avatar-file-upload"
      onChange={onImageSelect}
      style={{ display: 'none' }}
    />
  )

  return { onUpload, renderInput, tmpImage: error ? null : tmpImage, loading }
}
