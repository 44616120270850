import AriaModal from 'react-aria-modal'
import React, { memo } from 'react'
import styled from 'styled-components'
import { Carousel } from 'react-responsive-carousel'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import { Button, Modal } from 'web/components/InfoModal'
import { Colors } from 'shared/styles/Colors'
import { formatCents } from 'shared/utils/currency'
import { Icon } from 'shared/components/Icon/Icon'
import { Text } from 'shared/components/Cross'
import { useOrderContext } from 'web/contexts/OrderContext'

const renderArrow = (direction) => (onClickHandler, hasNext, label) =>
  hasNext && (
    <Arrow
      type="button"
      aria-label={label}
      className={`control-arrow control-${direction}`}
      onClick={onClickHandler}
    />
  )

export const DescriptionModal = memo(({ offering, onClose }) => {
  const { order } = useOrderContext()
  const currency = order?.bakery?.currency || 'USD'

  const hasSeveralImages = offering.images.length > 1

  return (
    <AriaModal
      titleText={`${offering.name.trim()}'s Description`}
      underlayClickExits={false}
      escapeExits={false}
      focusDialog={true}
      verticallyCenter
    >
      <ModalContainer>
        <ContentContainer>
          <CarouselContainer>
            <Carousel
              dynamicHeight
              showThumbs={false}
              showStatus={hasSeveralImages}
              showIndicators={hasSeveralImages}
              preventMovementUntilSwipeScrollTolerance={true}
              swipeScrollTolerance={100}
              renderArrowPrev={renderArrow('prev')}
              renderArrowNext={renderArrow('next')}
            >
              {offering.images.map((image) => (
                <ItemImage img src={image.fullUrl} key={image.id + image.key} alt={offering.name} />
              ))}
            </Carousel>
          </CarouselContainer>

          <Name>{offering.name}</Name>
          <Price>
            {offering.priceCents > 0 &&
              `${offering.priceType === 'variable' ? 'Starting at' : ''} ${formatCents(
                offering.priceCents,
                currency,
              )}`}
          </Price>
          <Description>{offering.description}</Description>
        </ContentContainer>

        <CloseButton onClick={onClose} style={{ backgroundColor: 'transparent' }}>
          <Icon icon={faXmark} color={Colors.black} size="lg" />
        </CloseButton>
      </ModalContainer>
    </AriaModal>
  )
})

const ModalContainer = styled(Modal)({
  position: 'relative',
  borderRadius: 15,
  height: 'auto',
})

const CloseButton = styled(Button)({
  position: 'absolute',
  top: 10,
  right: 5,
})

const ContentContainer = styled.div({
  padding: 24,
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'scroll',
  height: '65vh',

  '@media (max-width: 380px)': {
    height: '75vh',
  },
})

const CarouselContainer = styled.div({
  width: '100%',
  height: 280,
  alignSelf: 'center',
  marginBottom: 5,
})

const Name = styled(Text)({
  fontSize: 22,
})

const Price = styled(Text)({
  fontSize: 20,
  fontWeight: 700,
  color: Colors.pink,
  marginBottom: 5,
})

const Description = styled(Text)({
  fontStyle: 'italic',
  fontSize: 14,
  whiteSpace: 'pre-wrap',
})

const ItemImage = styled.img({
  objectFit: 'cover',
  height: 280,
})

const Arrow = styled.button({
  opacity: 1,
  transform: 'scale(1.5)',
})
