import googleLogo from 'shared/images/google-logo.png'
import React from 'react'
import styled from 'styled-components'
import { Colors } from 'shared/styles/Colors'
import { faApple, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { initiateOAuth } from 'web/utils/initiateOAuth'
import { MediaQueries } from 'web/styles/responsive'

export const SocialLogin = () => {
  return (
    <Container>
      <FacebookButton onClick={() => initiateOAuth({ provider: 'facebook' })}>
        <FontAwesomeIcon icon={faFacebookF} color={Colors.white} size="1x" />
        <span>facebook</span>
      </FacebookButton>
      <AppleButton onClick={() => initiateOAuth({ provider: 'apple' })}>
        <FontAwesomeIcon icon={faApple} color={Colors.white} size="1x" />
        <span>Apple</span>
      </AppleButton>
      <GoogleButton onClick={() => initiateOAuth({ provider: 'google' })}>
        <GoogleLogoImage src={googleLogo} />
        <span>Google</span>
      </GoogleButton>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`

const SocialButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  height: 55px;
  width: 55px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 20px;

  span {
    display: none;
  }

  @media ${MediaQueries.mdUp} {
    width: initial;
    flex: 1;
    margin-bottom: 0;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
    svg {
      margin-right: 10px;
    }
    span {
      display: initial;
    }
  }
`

const FacebookButton = styled(SocialButton)`
  background-color: rgb(65, 89, 148);
  color: ${Colors.white};
`

const AppleButton = styled(SocialButton)`
  background-color: ${Colors.black};
  color: ${Colors.white};
`

const GoogleButton = styled(SocialButton)`
  border: 1px solid ${Colors.grey25};
`

const GoogleLogoImage = styled.img`
  height: 30px;
  width: 30px;
`
