import React from 'react'
import styled from 'styled-components'

import { Colors } from 'shared/styles/Colors'

const { brand, grey10 } = Colors

export const ProgressBar = ({ backgroundColor, activeColor, completed, style }) => (
  <Container backgroundColor={backgroundColor} style={style}>
    <Progress backgroundColor={activeColor} completed={completed} />
  </Container>
)

const Container = styled.div(
  ({ backgroundColor }) => `
  height: 10px;
  background-color: ${backgroundColor || grey10};
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 2px;
  width: 100%;

  @media (min-width: 768px) {
    width: 50%;
  }

  @media (min-width: 992px) {
    width: 25%;
  }
`,
)

const Progress = styled.div(({ backgroundColor, completed }) => ({
  height: '100%',
  width: `${completed}%`,
  backgroundColor: backgroundColor || brand,
  borderRadius: 'inherit',
}))
