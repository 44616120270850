import { combineReducers } from 'redux'

import { accountReducer } from './account'
import { bakerOrderCreationReducer } from './bakerOrderCreation'
import { categoriesReducer } from './categories'
import { customQuestionsReducer } from './customQuestions'
import { customSectionsReducer } from './customSections'
import { faqsReducer } from './faqs'
import { messengerReducer } from './messenger'
import { offeringsReducer } from './offerings'
import { priceAdjustmentsReducer } from './priceAdjustments'
import { uiReducer } from './ui'

const allReducers = combineReducers({
  account: accountReducer,
  categories: categoriesReducer,
  ui: uiReducer,
  bakerOrderCreation: bakerOrderCreationReducer,
  messenger: messengerReducer,
  customQuestions: customQuestionsReducer,
  customSections: customSectionsReducer,
  offerings: offeringsReducer,
  priceAdjustments: priceAdjustmentsReducer,
  faqs: faqsReducer,
})

export const rootReducer = (state, action) => {
  if (action.type === 'RESET') {
    state = undefined
  }
  return allReducers(state, action)
}
