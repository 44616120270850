import React from 'react'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons'

import * as Cross from 'shared/components/Cross'
import { CenteredContent } from './CenteredContent'
import { Colors } from 'shared/styles/Colors'
import { Icon } from './Icon/Icon'
import { styler } from 'shared/utils/styler'

export const FetchError = ({ message = 'Connection error', onRetry = null, ...props }) => {
  if (message.startsWith("can't find record") || message.startsWith("Couldn't find")) {
    message = 'Nothing found at this URL'
    onRetry = null
  }

  return (
    <CenteredContent {...props}>
      <Container>
        <Icon icon={faExclamationTriangle} color={Colors.red} size={50} />
        <Message>{message}</Message>
        {onRetry && (
          <Cross.Button
            size="xs"
            type="alt"
            onPress={async () => await onRetry()}
            onClick={(e) => {
              e.preventDefault()
              onRetry()
            }}
          >
            Retry
          </Cross.Button>
        )}
      </Container>
    </CenteredContent>
  )
}

const Container = styler(Cross.View)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const Message = styler(Cross.Text)({
  marginTop: 20,
  marginBottom: 20,
})
