import React from 'react'
import styled from 'styled-components'
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons'
import { Link } from 'react-router-dom'

import { Colors } from 'shared/styles/Colors'
import { Icon } from 'shared/components/Icon/Icon'
import { processButtonVariant, sizes } from './buttonStyles'
import { useThemeContext } from 'web/contexts/ThemeContext'

export const Button = ({
  fullWidth = false,
  disabled = false,
  type = 'standard',
  color = Colors.blue,
  borderColor = null,
  size = 'medium',
  button = false,
  children,
  loading = false,
  onClick = null,
  ...props
}) => {
  const buttonVariant = useThemeContext()?.buttonVariant

  let as
  if (button) {
    as = 'button'
  } else if (disabled) {
    as = 'span'
  } else {
    as = (p) => (
      <Link className={p.className} {...props} onClick={onClick}>
        {children}
      </Link>
    )
  }

  return (
    <StyledLink
      type={type}
      disabled={disabled}
      fullWidth={fullWidth}
      color={color}
      borderColor={borderColor}
      size={size}
      as={as}
      $style={button || disabled ? props.style : {}}
      onClick={disabled ? null : onClick}
      variant={buttonVariant}
    >
      {children}
      {loading && <Icon spin icon={faSpinnerThird} style={{ marginLeft: 10 }} />}
    </StyledLink>
  )
}

const StyledLink = styled(Link)((props) => ({
  display: props.fullWidth ? 'block' : 'initial',
  width: props.fullWidth ? '100%' : 'initial',
  borderColor: props.borderColor || props.color,
  backgroundColor: props.type === 'alt' ? Colors.transparent : props.color,
  borderWidth: 2,
  textAlign: 'center',
  borderStyle: 'solid',
  opacity: props.disabled ? 0.5 : 1,
  paddingLeft: props.fullWidth ? 20 : sizes[props.size].paddingHorizontal,
  paddingRight: props.fullWidth ? 20 : sizes[props.size].paddingHorizontal,
  paddingTop: sizes[props.size].paddingVertical,
  paddingBottom: sizes[props.size].paddingVertical,
  color: props.type === 'alt' ? props.color : Colors.white,
  fontSize: sizes[props.size].fontSize,
  textDecoration: 'none',
  cursor: props.disabled ? 'initial' : 'pointer',
  ...props.$style,
  ...processButtonVariant({ size: props.size, variant: props.variant }),
}))
