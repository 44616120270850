import { useSelector } from 'react-redux'

export const useConversationPreview = ({ conversation }) => {
  const draft = useSelector((state) => state.messenger.drafts[conversation.otherUser.id]) || {}

  if (draft.message || draft.images?.length) {
    return draft.message ? `Draft: ${draft.message}` : '(images)'
  }

  let preview

  if (conversation.newConversation) {
    preview = 'New conversation'
  } else {
    preview = conversation.content.length ? conversation.content : '(images)'
    if (conversation.isSender) preview = `Me: ${preview}`
  }

  return preview
}
