import React from 'react'
import styled from 'styled-components'
import { Button } from 'web/components/Button'
import { Colors } from 'shared/styles/Colors'
import { Configuration } from 'shared/services/Configuration'
import { logout } from 'shared/reducers/account'
import { MobileNumberField } from 'web/components/form'
import { TextLink } from 'web/styles/shared'
import { useDispatch } from 'react-redux'
import { useMobileNumberEntry } from 'shared/hooks/useMobileNumberEntry'

export const EnterNumber = React.memo(({ setVerifying }) => {
  const dispatch = useDispatch()
  const onSuccess = ({ mobileNumber, rawMobileNumber }) => {
    setVerifying?.(mobileNumber)
  }
  const {
    onSubmit,
    isLoading,
    error,
    setError,
    setMobileNumber: setLocalMobileNumber,
    rawMobileNumber,
    setRawMobileNumber: setLocalRawMobileNumber,
    phoneCode,
    setPhoneCode,
  } = useMobileNumberEntry({ onSuccess })

  const onChange = (event) => {
    setError(null)
    setLocalMobileNumber(event.target.value)
    const text = event.target.rawValue
    if (text) {
      setLocalRawMobileNumber(`${text}`)
    } else {
      setLocalRawMobileNumber('')
    }
  }

  return (
    <Container>
      <Header>Mobile Verification</Header>

      <MobileNumberDescription style={{ marginTop: 0, marginBottom: 20 }}>
        Please enter your mobile number to receive notifications regarding your orders.
      </MobileNumberDescription>

      <MobileNumberField
        Container={InputContainer}
        error={error}
        onChange={onChange}
        style={{ fontSize: '3rem' }}
        phoneCode={phoneCode}
        setPhoneCode={setPhoneCode}
      />
      <Button
        fullWidth
        onClick={onSubmit}
        disabled={rawMobileNumber.length <= 11 || isLoading}
        button
      >
        Send Code
      </Button>
      <MobileNumberDescription style={{ fontSize: '1.6rem' }}>
        You may opt out of these notifications at any time.&nbsp;
        <TextLink href={Configuration.privacyUrl} target="_blank" as="a">
          Privacy Policy
        </TextLink>
      </MobileNumberDescription>

      <div style={{ marginTop: 20 }}>
        <Cancel onClick={() => dispatch(logout())} href="#">
          Cancel and log out
        </Cancel>
      </div>
    </Container>
  )
})
export default EnterNumber

const Container = styled.div``
const InputContainer = styled.div({
  marginBottom: 20,
})

const Header = styled.div({
  fontWeight: 'bold',
  fontSize: '2.8rem',
  marginBottom: 20,
})

const MobileNumberDescription = styled.div({
  color: Colors.grey75,
  fontSize: 18,
  marginTop: 30,
})

const Cancel = styled.a({
  color: Colors.grey75,
  textDecoration: 'underline',
  fontSize: '1.4rem',
})
