import React, { useMemo } from 'react'
import styled from 'styled-components'

import { Colors } from 'shared/styles/Colors'
import { formatCents } from 'shared/utils/currency'
import { MediaQueries } from 'web/styles/responsive'
import { parseNumberOrNull, times } from 'shared/utils/number'
import { Text } from 'shared/components/Cross'
import { TextField } from 'web/components/form/TextField'
import { useInvoiceContext } from 'shared/contexts/InvoiceContext'

const preventMinus = (e) => {
  if (!/\d|\./.test(e.key)) {
    e.preventDefault()
  }
}

const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData
  const pastedData = parseNumberOrNull(clipboardData.getData('text'))

  if (pastedData < 0) {
    e.preventDefault()
  }
}

export const Tips = ({ setTipVariant, setCustomTip, tipVariant, customTip, total }) => {
  const { order } = useInvoiceContext()

  const currencySymbol = useMemo(() => order?.bakery.currency?.symbol, [order?.bakery.currency])

  const calcPercents = (percents) => formatCents(times(total, percents), order?.bakery?.currency)

  return (
    <Container>
      <PercentsContainer>
        <TipPercentVariant active={tipVariant === 1} onClick={() => setTipVariant(1)}>
          <TipPercentTitle>15%</TipPercentTitle>
          <TipPercentSubtitle>{calcPercents(0.15)}</TipPercentSubtitle>
        </TipPercentVariant>
        <TipPercentVariant active={tipVariant === 2} onClick={() => setTipVariant(2)}>
          <TipPercentTitle>20%</TipPercentTitle>
          <TipPercentSubtitle>{calcPercents(0.2)}</TipPercentSubtitle>
        </TipPercentVariant>
        <TipPercentVariant active={tipVariant === 3} onClick={() => setTipVariant(3)}>
          <TipPercentTitle>25%</TipPercentTitle>
          <TipPercentSubtitle>{calcPercents(0.25)}</TipPercentSubtitle>
        </TipPercentVariant>
      </PercentsContainer>
      {tipVariant === 4 ? (
        <CustomTipContainer>
          <SymbolContainer id="currencySymbolContainer">
            <SymbolText>{currencySymbol}</SymbolText>
          </SymbolContainer>
          <CustomTipInput
            type="number"
            inputMode="numeric"
            pattern={/^\d+(\.\d{1,2})?$/}
            value={customTip}
            onChange={(e) => setCustomTip(e.target.value)}
            style={{ flex: 1 }}
            onKeyPress={preventMinus}
            onPaste={preventPasteNegative}
            min={0}
          />
        </CustomTipContainer>
      ) : (
        <TipOption onClick={() => setTipVariant(4)}>Custom Tip Amount</TipOption>
      )}
      <TipOption active={tipVariant === 0} onClick={() => setTipVariant(0)}>
        No Tip
      </TipOption>
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
  marginBottom: 30,
})

const PercentsContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 15,
})

const TipPercentVariant = styled.div(({ active }) => ({
  ...buttonCommonStyle,
  width: 'calc((100% / 3))',
  border: `3px solid ${active ? Colors.brand : Colors.grey25}`,
  height: 80,

  [`@media ${MediaQueries.mdUp}`]: {
    height: 100,
  },
}))

const TipPercentTitle = styled(Text)({
  color: Colors.brand,
  fontSize: '2.4rem',
  lineHeight: '2.4rem',

  [`@media ${MediaQueries.mdUp}`]: {
    fontSize: '3.5rem',
    lineHeight: '3.7rem',
  },
})

const TipPercentSubtitle = styled(Text)({
  color: Colors.grey50,
})

const TipOption = styled.div(({ active }) => ({
  ...buttonCommonStyle,
  color: Colors.brand,
  height: 50,
  border: `3px solid ${active ? Colors.brand : Colors.grey25}`,
}))

const CustomTipContainer = styled.div({
  display: 'flex',

  '&:focus-within #currencySymbolContainer': {
    backgroundColor: Colors.blue,
  },
})

const CustomTipInput = styled(TextField)({
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
})

const buttonCommonStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  cursor: 'pointer',
  borderRadius: 8,
  backgroundColor: Colors.grey3,
}

const SymbolContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: Colors.grey25,
  width: 30,
  borderTopLeftRadius: 4,
  borderBottomLeftRadius: 4,
  marginRight: -1,
})

const SymbolText = styled(Text)({
  color: Colors.grey75,
  marginRight: 1,
})
