import React from 'react'
import styled from 'styled-components'

export const LinkedView = (props) => {
  return <StyledView {...props} />
}

const StyledView = styled.div({
  cursor: 'pointer',
})
