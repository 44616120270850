export const StorageKeys = {
  refreshTokenStorageKey: 'bakesy:refreshToken',
  appIntroCompleteKey: 'bakesy:appIntroCompleteKey',
  beforeYouStartKey: 'bakesy:beforeYouStart:2',
  jwtStorageKey: 'bakesy:jwtStorageKey',
  notificationsSkipped: 'bakesy:notificationsSkipped',
  notificationsSystemPrompted: 'bakesy:notificationsSystemPrompted',
  lastVersionPromptedForReview: 'bakesy:lastVersionPromptedForReview',
  calendarEvents: 'bakesy:calendarEvents',
}
