import React from 'react'
import styled from 'styled-components'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import { useInvoiceContext } from 'shared/contexts/InvoiceContext'

export const ContractViewPage = () => {
  const { order } = useInvoiceContext()
  const attachment = order?.attachment

  return (
    <Container>
      <GoBackContainer to={`/orders/${order?.orderNumber}`}>
        <FontAwesomeIcon icon={faArrowLeft} />
        Back to Invoice
      </GoBackContainer>
      {attachment?.fullUrl && <Image src={attachment.fullUrl} />}
    </Container>
  )
}

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100vw;

  & .thumbnail-wrapper * {
    max-width: 100vw;
  }
`
const GoBackContainer = styled(Link)({
  padding: 20,
  paddingLeft: 0,
  display: 'flex',
  alignSelf: 'flex-start',
  alignItems: 'center',
  gap: 16,
  cursor: 'pointer',
})

const Image = styled.img({
  maxWidth: 'calc(100vw - 40px)',
})
