/* eslint-disable import/no-named-as-default-member */
import np from 'number-precision'
import parser from 'number-parsing'

export const parseNumberOrNull = (value) => {
  return parser(value) || null
}

export const parseNumberOrZero = (value) => {
  return parser(value) || 0
}

export const divide = (num1, num2) => np.divide(parseNumberOrZero(num1), parseNumberOrZero(num2))
export const minus = (num1, num2) => np.minus(parseNumberOrZero(num1), parseNumberOrZero(num2))
export const plus = (num1, num2) => np.plus(parseNumberOrZero(num1), parseNumberOrZero(num2))
export const round = (num, digits) => np.round(parseNumberOrZero(num), digits)
export const times = (num1, num2) => np.times(parseNumberOrZero(num1), parseNumberOrZero(num2))

/**
 *
 * @param stock - stock quantity
 * @param dozenOnly - whether offering quantifies in dozen
 * @param showDecimal - whether we should show decimal
 * @returns processed number based on the input params
 */

export const covertStockToDozens = (stock, dozenOnly, showDecimal = false) => {
  if (stock === null || stock === undefined) return null

  if (dozenOnly) {
    // Following expression would return float with one decimal after comma, e.g. 87 / 12 = 7.25 => 7.2
    if (showDecimal) return Math.floor((stock / 12) * 10) / 10
    else return Math.floor(stock / 12)
  }
  // this is for ItemDataInputScreen, basically to revert decimal stock value
  if (showDecimal) return Math.round(stock * 12)

  return stock
}
