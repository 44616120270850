import React from 'react'
import { TextField } from 'web/components/form/TextField'

export const ControlledInput = React.memo(
  ({ controller, Component = TextField, showError = true, ...rest }) => {
    const { field, fieldState } = controller

    const errorMessage = showError ? fieldState.error?.message : undefined

    return (
      <Component
        name={field.name}
        error={errorMessage}
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value}
        {...rest}
      />
    )
  },
)
