import * as Yup from 'yup'
import { MONEY_PATTERN_VALIDATION_MESSAGE, moneyInputRegex } from 'shared/utils/currency'
import { parseNumberOrNull } from 'shared/utils/number'

const testIncrements = ([dozenOnly, allowHalfDozen], schema) => {
  switch (true) {
    case dozenOnly && allowHalfDozen:
      return schema.test(
        'is-half-dozen',
        'must be in increments of 0.5',
        (value) => value % 0.5 === 0,
      )

    default:
      return schema.integer('must be a whole number')
  }
}

const checkInstantCheckoutPrice = (condition, schema) => {
  if (parseNumberOrNull(condition[0] * condition[1]) < 4)
    return schema.test(
      'check-price',
      'must be greater or equal to $4.00 when Instant Checkout enabled',
      (value) => !value,
    )
}

export const offering = {
  Name: Yup.string().required('is required'),
  Price: Yup.string().when('priceType', {
    is: (value) => value !== 'noPrice',
    then: (schema) =>
      schema
        .required('is required')
        .test('positive', 'must be above $0.00', (value) => parseNumberOrNull(value) > 0)
        .matches(moneyInputRegex, { message: MONEY_PATTERN_VALIDATION_MESSAGE }),
  }),

  MinQuantity: Yup.number()
    .required('is required')
    .typeError('must be a number')
    .positive('must be above 0')
    .transform((_, originalValue) => parseNumberOrNull(originalValue))
    .when(['dozenOnly', 'allowHalfDozen'], testIncrements),

  MaxQuantity: Yup.number()
    .nullable()
    .transform((_, originalValue) => parseNumberOrNull(originalValue))
    .when('allowMaxQuantity', {
      is: true,
      then: (schema) =>
        schema
          .required('is required')
          .typeError('must be a number')
          .positive('must be above 0')
          .moreThan(Yup.ref('minQuantity'), 'must be greater than min quantity')
          .when(['dozenOnly', 'allowHalfDozen'], testIncrements),
    }),

  Stock: Yup.number()
    .typeError('must be a number')
    .nullable()
    .when('hasInventory', {
      is: true,
      then: (schema) => schema.required('is required').typeError('must be a number'),
    }),

  EnableInstantCheckout: Yup.boolean()
    .nullable()
    .when(['price', 'minQuantity'], checkInstantCheckoutPrice),
}
