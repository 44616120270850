/**
 * Function returns black or white color to be contrasty with given color
 *
 * @param color - color you want to get black or white contrasty value, e.g. background color for text
 * @returns color in hex format - `#FFFFFF | #000000`
 * adapted from: https://stackoverflow.com/a/35970186
 */

// Luminance coeficients for sRGB colorspace
// taken from http://www.brucelindbloom.com/index.html?WorkingSpaceInfo.html

const redLuminanceCoeficient = 0.212656
const greenLuminanceCoeficient = 0.715158
const blueLuminanceCoeficient = 0.072186
const overallColorIntensityThreshold = 186 // More info: https://stackoverflow.com/questions/a/3943023

export const getContrastColor = (hex) => {
  if (hex.indexOf('#') === 0) hex = hex.slice(1)
  // convert 3-digit, 4-digit and 8-digit hex to 6-digits.
  if (hex.length === 3 || hex.length === 4)
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  if (hex.length === 8) hex = hex.slice(0, -2)
  if (hex.length !== 6) return '#000'

  const r = parseInt(hex.slice(0, 2), 16)
  const g = parseInt(hex.slice(2, 4), 16)
  const b = parseInt(hex.slice(4, 6), 16)

  return r * redLuminanceCoeficient + g * greenLuminanceCoeficient + b * blueLuminanceCoeficient >
    overallColorIntensityThreshold
    ? '#000'
    : '#fff'
}
